//#region > Imports
import { reportException } from "../../helpers/errorReporting";
//#endregion

//#region > Imports
//> Time
import moment from "moment";
//#endregion

//#region > Action types
export const TOKEN_AUTH_S = "AUTH_SUCCESS";
export const TOKEN_AUTH_F = "AUTH_FAIL";
export const DATA_AUTH_S = "DATA_SUCCESS";
export const DATA_AUTH_F = "DATA_FAIL";
export const LOGOUT_S = "LOGOUT_SUCCESS";
//#endregion

//#region > Creators
export const createDamageReport = (policy_id, message, images) => {
  return (dispatch, getState) => {
    const data = new FormData();

    data.append("policy_id", policy_id);
    data.append("message", message);

    if (images && images.length > 0) {
      // Send multiple files
      images.forEach((img) => {
        data.append("images", img);
      });
    }

    return fetch(process.env.REACT_APP_BASEURL + "damagereport/submit/", {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: data,
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not create damage report.",
              "POST damagereport/submit/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not create damage report.",
              "POST damagereport/submit/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not create damage report.",
          "POST damagereport/submit/"
        );

        return { status: false, data: error };
      });
  };
};

export const sendMessageToDamageReport = (id, msg, images) => {
  return (dispatch, getState) => {
    const data = new FormData();

    data.append("message", msg);

    if (images && images.length > 0) {
      // Send multiple files
      images.forEach((img) => {
        data.append("images", img);
      });
    }

    return fetch(
      process.env.REACT_APP_BASEURL + "damagereport/submit/" + id + "/",
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: data,
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not send massage to damage report.",
              "POST damagereport/submit/<id>/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not send massage to damage report.",
              "POST damagereport/submit/<id>/"
            );

            return { status: false, data: data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not send massage to damage report.",
          "POST damagereport/submit/<id>/"
        );

        return { status: false, data: error };
      });
  };
};

export const getDamageReportData = (id) => {
  return (dispatch, getState) => {
    return fetch(
      process.env.REACT_APP_BASEURL + "damagereport/show/" + id + "/",
      {
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not get damage report data.",
              "GET damagereport/show/<id>/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not get damage report data.",
              "GET damagereport/show/<id>/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not get damage report data.",
          "GET damagereport/show/<id>/"
        );

        return { status: false, data: error };
      });
  };
};

export const getUserIdentification = (id) => {
  return (dispatch, getState) => {
    return fetch(process.env.REACT_APP_BASEURL + "id/user/" + id + "/", {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not get user identification.",
              "GET id/user/<id>/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not get user identification.",
              "GET id/user/<id>/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not get user identification.",
          "GET id/user/<id>/"
        );

        return { status: false, data: error };
      });
  };
};

export const getUser = (id) => {
  return (dispatch, getState) => {
    return fetch(process.env.REACT_APP_BASEURL + "users/" + id + "/", {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(data, "Could not get user.", "GET users/<id>/");

            return { status: false, data: data };
          default:
            reportException(data, "Could not get user.", "GET users/<id>/");

            return false;
        }
      })
      .catch((error) => {
        reportException(error, "Could not get user.", "GET users/<id>/");

        return { status: false, data: error };
      });
  };
};

export const uploadTemplate = (id, fileObj) => {
  return (dispatch, getState) => {
    const data = new FormData();

    for (const [key, value] of Object.entries(fileObj)) {
      if (value !== undefined && value !== null) {
        data.append(key, value);
      }
    }

    return fetch(
      process.env.REACT_APP_BASEURL + "insurance/submit/" + id + "/template/",
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: data,
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not upload template.",
              "POST insurance/submit/<id>/template/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not upload template.",
              "POST insurance/submit/<id>/template/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not upload template.",
          "POST insurance/submit/<id>/template/"
        );

        return { status: false, data: error };
      });
  };
};

export const uploadDocuments = (id, files) => {
  return (dispatch, getState) => {
    const data = new FormData();

    if (files && files.length > 0) {
      // Send multiple files
      files.forEach((file, i) => {
        data.append("document_" + (i + 1), file);
      });
    }

    return fetch(
      process.env.REACT_APP_BASEURL + "insurance/submit/" + id + "/agreement/",
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: data,
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not upload documents.",
              "POST insurance/submit/<id>/agreement/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not upload documents.",
              "POST insurance/submit/<id>/agreement/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not upload documents.",
          "POST insurance/submit/<id>/agreement/"
        );

        return { status: false, data: error };
      });
  };
};

export const uploadDoc = (id, file, policy_id) => {
  return (dispatch, getState) => {
    const data = new FormData();

    data.append("document", file);
    data.append("policy_id", policy_id);

    return fetch(
      process.env.REACT_APP_BASEURL + "insurance/" + id + "/change/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: data,
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not upload document.",
              "PUT insurance/<id>/change/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not upload document.",
              "PUT insurance/<id>/change/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not upload document.",
          "PUT insurance/<id>/change/"
        );

        return { status: false, data: error };
      });
  };
};

export const declineInsurance = (id) => {
  return (dispatch, getState) => {
    return fetch(
      process.env.REACT_APP_BASEURL + "insurance/" + id + "/decline/",
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not decline insurance.",
              "POST insurance/<id>/decline/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not decline insurance.",
              "POST insurance/<id>/decline/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not decline insurance.",
          "POST insurance/<id>/decline/"
        );

        return { status: false, data: error };
      });
  };
};

export const getInsurances = (id) => {
  return (dispatch, getState) => {
    return fetch(process.env.REACT_APP_BASEURL + "insurance/show/", {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not get insurances.",
              "GET insurance/show/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not get insurances.",
              "GET insurance/show/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not get insurances.",
          "GET insurance/show/"
        );

        return { status: false, data: error };
      });
  };
};

export const verifyId = (document_id, valid, state) => {
  return (dispatch, getState) => {
    const res = {};

    res["verified"] = valid ? true : false;
    res["denied"] = !valid ? true : false;

    if (state.first_name) {
      res["first_name"] = state.first_name;
    }

    if (state.last_name) {
      res["last_name"] = state.last_name;
    }

    if (state.email) {
      res["email"] = state.email;
    }

    if (state.phone) {
      res["phone"] = state.phone;
    }

    if (state.address1) {
      res["address1"] = state.address1;
    }

    if (state.zip) {
      res["zip"] = state.zip;
    }

    if (state.birthdate) {
      res["birthdate"] = moment(state.birthdate, "DD.MM.YYYY").format(
        "YYYY-MM-DD"
      );
    }

    if (state.sex) {
      res["sex"] = state.sex[0];
    }

    return fetch(
      process.env.REACT_APP_BASEURL + "id/verify/" + document_id + "/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(res),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not verify identification.",
              "PUT id/verify/<id>/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not verify identification.",
              "PUT id/verify/<id>/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not verify identification.",
          "PUT id/verify/<id>/"
        );

        return { status: false, data: error };
      });
  };
};

export const changeUserData = (
  first_name,
  last_name,
  email,
  phone,
  password
) => {
  return (dispatch, getState) => {
    const currentData = getState().auth.data;
    const data = new FormData();

    first_name &&
      first_name !== currentData.first_name &&
      data.append("first_name", first_name);
    last_name &&
      last_name !== currentData.last_name &&
      data.append("last_name", last_name);
    email && email !== currentData.email && data.append("email", email);
    phone && phone !== currentData.phone && data.append("phone", phone);
    data.append("current_password", password);

    return fetch(
      process.env.REACT_APP_BASEURL + "users/" + currentData.id + "/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.fromEntries(data)),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          default:
            reportException(
              data,
              "Could not change user data.",
              "PUT users/<id>/"
            );

            return { status: false, data: data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not change user data.",
          "PUT users/<id>/"
        );

        return { status: false, data: error };
      });
  };
};

export const changeUserDataByAdmin = (
  userId,
  first_name,
  last_name,
  email,
  phone
) => {
  return (dispatch, getState) => {
    const data = new FormData();

    data.append("first_name", first_name);
    data.append("last_name", last_name);
    data.append("email", email);
    data.append("phone", phone);

    return fetch(process.env.REACT_APP_BASEURL + "users/" + userId + "/", {
      method: "PUT",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Object.fromEntries(data)),
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          default:
            reportException(
              data,
              "Could not change user data by admin.",
              "PUT users/<id>/"
            );

            return { status: false, data: data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not change user data by admin.",
          "PUT users/<id>/"
        );

        return { status: false, data: error };
      });
  };
};

export const requestResetPassword = (email) => {
  return (dispatch, getState) => {
    return fetch(process.env.REACT_APP_BASEURL + "users/password/request/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          default:
            reportException(
              data,
              "Could not request reset password.",
              "POST users/password/request/"
            );

            return { status: false, data: data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not request reset password.",
          "POST users/password/request/"
        );

        return { status: false, data: error };
      });
  };
};

export const resetPassword = (password, token) => {
  return (dispatch, getState) => {
    return fetch(
      process.env.REACT_APP_BASEURL + "users/password/reset/" + token + "/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data };
          default:
            reportException(
              data,
              "Could not reset password.",
              "POST users/password/request/<token>/"
            );

            return { status: false, data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not reset password.",
          "POST users/password/request/<token>/"
        );

        return { status: false, data: error };
      });
  };
};

export const assignAdvisor = (advisor_id, customer_id) => {
  return (dispatch, getState) => {
    return fetch(process.env.REACT_APP_BASEURL + "users/" + customer_id + "/", {
      method: "PUT",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        advisor: advisor_id,
      }),
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not assign advisor.",
              "PUT users/<id>/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not assign advisor.",
              "PUT users/<id>/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(error, "Could not assign advisor.", "PUT users/<id>/");

        return { status: false, data: error };
      });
  };
};

export const uploadDocument = (front, back) => {
  return (dispatch, getState) => {
    const data = new FormData();

    data.append("document", front);

    if (back) {
      data.append("document_back", back);
    }

    return fetch(process.env.REACT_APP_BASEURL + "id/document/", {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: data,
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not upload id document.",
              "POST id/document/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not upload id document.",
              "POST id/document/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not upload id document.",
          "POST id/document/"
        );

        return { status: false, data: error };
      });
  };
};

export const changeReportStatus = (id, value) => {
  return (dispatch, getState) => {
    let endpoint =
      value === "c" ? "close/" : value === "w" ? "close/" : "close/";

    return fetch(
      process.env.REACT_APP_BASEURL + "damagereport/" + endpoint + id + "/",
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not change damagereport status.",
              "POST damagereport/<endpoint>/<id>/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not change damagereport status.",
              "POST damagereport/<endpoint>/<id>/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not change damagereport status.",
          "POST damagereport/<endpoint>/<id>/"
        );

        return { status: false, data: error };
      });
  };
};

export const verifyPhone = (phone, channel) => {
  return (dispatch, getState) => {
    return fetch(process.env.REACT_APP_BASEURL + "users/phone/add/", {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ channel, phone_number: phone.trim() }),
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not verify phone.",
              "POST users/phone/add/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not verify phone.",
              "POST users/phone/add/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not verify phone.",
          "POST users/phone/add/"
        );

        return { status: false, data: error };
      });
  };
};

export const sendCode = (code) => {
  return (dispatch, getState) => {
    return fetch(process.env.REACT_APP_BASEURL + "users/phone/verify/", {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not send verify phone code.",
              "POST users/phone/verify/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not send verify phone code.",
              "POST users/phone/verify/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not send verify phone code.",
          "POST users/phone/verify/"
        );

        return { status: false, data: error };
      });
  };
};

export const updateInsurancePersonalized = (id, color, title) => {
  return (dispatch, getState) => {
    return fetch(
      process.env.REACT_APP_BASEURL + "insurance/" + id + "/design/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body:
          title && color
            ? JSON.stringify({ color, title: title.trim() })
            : color
            ? JSON.stringify({ color })
            : title
            ? JSON.stringify({ title: title.trim() })
            : null,
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not update insurance design.",
              "PUT insurance/<id>/design/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not update insurance design.",
              "PUT insurance/<id>/design/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not update insurance design.",
          "PUT insurance/<id>/design/"
        );

        return { status: false, data: error };
      });
  };
};
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
