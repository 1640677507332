//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// Router dom
import { Redirect } from "react-router-dom";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBCard,
  MDBCardImage,
  MDBBtn,
  MDBProgress,
} from "mdbreact";
//> Redux
// Basic Redux provider
import { connect } from "react-redux";
//> Animations
import { animations } from "react-animation";

//> Components
import { Questionnaire } from "../../organisms";
import { Navbar } from "../../molecules";
//> Helpers
import { getLS, removeLS, writeToLS } from "../../../helpers/localStorage.js";
//> CSS
import "./HomePage.scss";
//#endregion

//#region > Dummy
const DUMMY = {
  insurances: [
    {
      title: "Haushaltsversicherung",
      slug: "household",
      icon: "building",
      lead: "Finde heraus, wie deine Wohnung top und umfangreich geschützt werden kann.",
      picture: require("../../../assets/content/insurances/haushaltsversicherung/lead.jpg"),
    },
    /*{
      title: "Reiseversicherung",
      slug: "reiseversicherung",
      icon: "plane",
      external: "https://start.europaeische.at/private?AGN=10008340",
      lead: "Damit du dich auf deiner nächsten Reise voll ins Vergnügen stürzen kannst.",
      picture: require("../../../assets/content/insurances/travel/lead.jpg"),
    },*/
  ],
};
//#endregion

//#region > Config
const stylePopIn = {
  animation: animations.popIn,
};
const styleFadeInUp = {
  animation: animations.fadeInUp,
};
const styleFadeIn = {
  animation: animations.fadeIn,
};
//#endregion

//#region > Components
/**
 * @class Template Homepage Component
 * @description Provides a first template to see when you run this app
 */
class HomePage extends React.Component {
  state = {
    //name: getLS("name") ? getLS("name") : "",
    insurance: getLS("insurance") ? getLS("insurance") : null,
  };

  componentDidMount = () => {
    // Get location href
    const url = new URL(window.location.href);

    const parameter = url.searchParams.get("action");

    this.setState({ insurance: parameter });
  };

  getProgress = () => {
    const insuranceJSON = getLS("insuranceBackup");
    const progressJSON = getLS("progressBackup");

    if (insuranceJSON && progressJSON) {
      const insurance = insuranceJSON;
      const progress = JSON.parse(progressJSON);
      const currentDepth = progress.currentDepth;

      switch (insurance) {
        case "household":
          return {
            value: (100 / 7) * (currentDepth + 1),
            name: "Haushaltsversicherung",
          };
        default:
          return null;
      }
    } else {
      return false;
    }
  };

  render() {
    const { data } = this.props;

    if (data && !data.utype) {
      return <Redirect to="/me" />;
    }

    const progress = this.getProgress();

    return (
      <>
        <Navbar
          inQuestionnaire={
            this.state.insurance === null || this.state.name === ""
              ? false
              : true
          }
          exitInsurance={() =>
            this.setState({ insurance: null }, () => {
              const insuranceBackup = getLS("insurance");
              const progressBackup = getLS("progress");

              writeToLS("insuranceBackup", insuranceBackup);
              writeToLS("progressBackup", progressBackup);

              this.setState({ showOpenConfig: true });

              removeLS("insurance");
              removeLS("progress");
            })
          }
        />
        <div id="home" className={this.state.insurance ?? "showbg"}>
          <MDBContainer className="py-5 my-5">
            {this.state.insurance === null ? (
              <>
                <div className="text-center pb-5" style={styleFadeIn}>
                  <h3 className="font-weight-bold mt-5 h3 d-sm-none d-block">
                    Dein individueller, unabhängiger Versicherungsrechner
                  </h3>
                  <h2 className="font-weight-bold mt-5 h2 d-sm-block d-none">
                    Dein individueller, unabhängiger Versicherungsrechner
                  </h2>
                  <p className="lead text-muted">
                    In wenigen Minuten zu deiner optimalen Versicherungslösung
                  </p>
                </div>
                <MDBRow className="justify-content-center landing-page">
                  {this.state.showOpenConfig !== false &&
                    (progress || this.state.showOpenConfig) && (
                      <>
                        <MDBCol lg="5" className="mb-4 text-center">
                          <MDBCard
                            className="open-progress animated"
                            style={stylePopIn}
                          >
                            <MDBProgress value={progress.value} />
                            <MDBCardBody>
                              <p className="lead mb-1">
                                Du hast noch eine offene Konfiguration.
                              </p>
                              <p className="text-muted">
                                Du wolltest doch wissen, wie viel deine{" "}
                                {progress.name} bei uns kostet.
                              </p>
                              <MDBBtn
                                color="blue"
                                onClick={() => {
                                  // Get backup
                                  const insurance = getLS("insuranceBackup");
                                  const progress = getLS("progressBackup");

                                  writeToLS("insurance", insurance);
                                  writeToLS("progress", progress);

                                  this.setState(
                                    {
                                      insurance,
                                    },
                                    () => {
                                      removeLS("insuranceBackup");
                                      removeLS("progressBackup");
                                      window.scrollTo(0, 0);
                                    }
                                  );
                                }}
                              >
                                Fortsetzen
                              </MDBBtn>
                              <p
                                className="clickable text-muted small mt-2 mb-0"
                                onClick={() => {
                                  removeLS("insuranceBackup");
                                  removeLS("progressBackup");
                                  removeLS("insurance");
                                  removeLS("progress");

                                  this.setState({
                                    reset: true,
                                    showOpenConfig: false,
                                  });
                                }}
                              >
                                Verwerfen
                              </p>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                        <MDBCol lg="8" className="text-center">
                          <div className="w-100 mb-5">
                            <div className="splitter mt-3 mb-2">
                              <span className="or">
                                <span className="or-text">
                                  oder neu anfangen
                                </span>
                              </span>
                            </div>
                          </div>
                        </MDBCol>
                      </>
                    )}
                  <MDBCol lg="8">
                    <div style={styleFadeInUp} className="text-center">
                      <MDBRow className="offers flex-center">
                        {DUMMY.insurances.map((insurance, i) => {
                          if (insurance.external) {
                            return (
                              <MDBCol lg="6" key={"insurance-col-" + i}>
                                <a
                                  href={insurance.external}
                                  className="text-dark"
                                >
                                  <MDBCard>
                                    <MDBCardImage
                                      className="img-fluid"
                                      src={insurance.picture?.default}
                                    />
                                    <MDBCardBody className="text-center">
                                      <p className="lead font-weight-bold mb-2 text-dark">
                                        {insurance.title}
                                      </p>
                                      <hr />
                                      <p className="text-muted mb-0">
                                        {insurance.lead}
                                      </p>
                                    </MDBCardBody>
                                  </MDBCard>
                                </a>
                              </MDBCol>
                            );
                          } else {
                            return (
                              <MDBCol
                                lg="6"
                                key={"insurance-col-" + i}
                                className="internal mb-4 mb-sm-0"
                              >
                                <button
                                  type="submit"
                                  onClick={() =>
                                    this.setState(
                                      { insurance: insurance.slug },
                                      () => window.scrollTo(0, 0)
                                    )
                                  }
                                >
                                  <MDBCard>
                                    <MDBCardImage
                                      className="img-fluid"
                                      src={insurance.picture?.default}
                                    />
                                    <MDBCardBody className="text-center">
                                      <p className="lead font-weight-bold mb-2 text-dark">
                                        {insurance.title}
                                      </p>
                                      <hr />
                                      <p className="text-muted mb-0">
                                        {insurance.lead}
                                      </p>
                                    </MDBCardBody>
                                  </MDBCard>
                                </button>
                              </MDBCol>
                            );
                          }
                        })}
                      </MDBRow>
                    </div>
                  </MDBCol>
                </MDBRow>
              </>
            ) : (
              <div className="h-100 d-block">
                <Questionnaire
                  name={this.state.name}
                  insurance={this.state.insurance}
                  exitInsurance={() =>
                    this.setState({ insurance: null }, () =>
                      removeLS("insurance")
                    )
                  }
                />
              </div>
            )}
            {/*<DamageReport />*/}
          </MDBContainer>
        </div>
      </>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
  data: state.auth.data,
});

const mapDispatchToProps = {};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
