//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBContainer } from "mdbreact";

//> Components
import { Navbar } from "../../molecules";
//#endregion

//#region > Components
class MessagePage extends React.Component {
  render() {
    return (
      <>
        <Navbar inQuestionnaire={false} loader={false} />
        <MDBContainer id="message" className="py-3 my-5 text-center white">
          {this.props.location.pathname === "/about" && (
            <div className="text-left">
              <h2>Impressum</h2>
            </div>
          )}
          {this.props.location.pathname === "/privacy" && (
            <>
              <div className="text-left">
                <h2>Datenschutzerklärung</h2>
              </div>
            </>
          )}
          {this.props.location.pathname === "/gtc" && (
            <>
              <div className="text-left">
                <h2>Allgemeine Geschäftsbedingungen</h2>
              </div>
            </>
          )}
        </MDBContainer>
      </>
    );
  }
}
//#endregion

//#region > Exports
export default MessagePage;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
