//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> File upload
import Dropzone from "react-dropzone";
//> Lottie
import Lottie from "lottie-react-web";
//> Color
import { CirclePicker } from "react-color";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBListGroupItem,
  MDBBadge,
  MDBListGroup,
  MDBModalHeader,
  MDBView,
  MDBMask,
  MDBBtn,
  MDBTooltip,
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import {
  uploadDocuments,
  updateInsurancePersonalized,
} from "../../../../store/actions/userActions";
import { getInsuranceSubmissions } from "../../../../store/actions/signatureActions";
//> CSS
import "../index.scss";
import SignatureModal from "../SignatureModal";
//> Lottie
import houseAnimationFile from "../../../../assets/lottie/home.json";
//#endregion

//#region > Config
const houseAnimation = {
  loop: true,
  autoplay: true,
  animationData: houseAnimationFile,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
//#endregion

//#region > Components
class InsuranceOverview extends React.Component {
  state = { selectedFiles: [] };

  componentDidMount = () => {
    this.setState({
      insuranceData: this.props.insurance,
      insuranceTitle:
        this.props.insurance.title ?? this.props.insurance.insurance,
      insuranceTitleFallback:
        this.props.insurance.title ?? this.props.insurance.insurance,
    });
  };

  toggleModal = () => {
    if (this.state.modal) {
      this.setState({
        modal: !this.state.modal,
        success: false,
        email: "",
      });
    } else {
      // Set email if passed on
      const { email } = this.props;

      this.setState({
        modal: !this.state.modal,
        email: email ? email : "",
      });
    }
  };

  next = () => {
    const { insuranceData } = this.state;

    const documents = insuranceData?.submission_documents;

    const unfiltered = documents.filter((doc) => !doc.signed);
  };

  refreshInsuranceSubmissions = async () => {
    const insurances = await this.props.getInsuranceSubmissions();

    const selectedInsurance = insurances.data?.filter(
      (ins) => ins.id === this.props.insurance.id
    )[0];

    this.setState({
      insuranceData: selectedInsurance,
    });
  };

  findNonSignedDocs = (submission_documents) => {
    const res = submission_documents
      ? submission_documents.filter((doc) => !doc.document && !doc.signature)
      : [];

    return res.length;
  };

  renderItem = (doc) => {
    if (doc) {
      return (
        <MDBListGroupItem
          onClick={() =>
            doc.document === null
              ? this.setState({
                  signDoc: doc,
                })
              : undefined
          }
        >
          <div className="d-flex justify-content-between">
            <div>
              <p className="mb-1">{doc.title}</p>
              <p className="text-muted small">{doc.description}</p>

              {doc.pos_x !== null &&
                doc.pos_y !== null &&
                doc.page_index !== null &&
                doc.document === null && (
                  <MDBBadge color="green" className="z-depth-0">
                    Unterschrift erforderlich
                  </MDBBadge>
                )}
            </div>
          </div>
        </MDBListGroupItem>
      );
    }
  };

  // Change color
  handleChangeComplete = (color, insuranceId) => {
    this.setState({ background: color.hex }, () =>
      this.props.updateInsurancePersonalized(insuranceId, color.hex)
    );
  };

  render() {
    const { insuranceData } = this.state;

    let insurance = insuranceData ?? this.props.insurance;

    const signaturesLeft = this.findNonSignedDocs(
      insurance.submission_documents
    );
    const needsSignature = signaturesLeft > 0 ? true : false;

    return (
      <div>
        <MDBListGroupItem
          key={insurance.policy_id}
          className={insurance.status.status === "c" ? "disabled" : undefined}
          onClick={this.toggleModal}
        >
          {insurance.status.status < 3 && (
            <div className="status-container">
              <div className="status d-flex">
                {insurance.status.status > -1 && insurance.status.status < 2 && (
                  <>
                    <span
                      className={
                        insurance.status.status === 0
                          ? "status-item small text-center active"
                          : insurance.status.status > 0
                          ? "status-item small text-center finished"
                          : "status-item small text-center"
                      }
                    >
                      <span>1. Daten erfasst</span>
                    </span>
                    <span className="status-arrow">
                      <MDBIcon icon="angle-double-right" size="lg" />
                    </span>
                  </>
                )}
                {insurance.status.status > -1 && insurance.status.status < 3 && (
                  <>
                    <span
                      className={
                        insurance.status.status === 1
                          ? "status-item small text-center active"
                          : insurance.status.status > 1
                          ? "status-item small text-center finished"
                          : "status-item small text-center"
                      }
                    >
                      <span>2. Angebot erhalten</span>
                    </span>
                    {insurance.status.status > 0 && (
                      <span className="status-arrow">
                        <MDBIcon icon="angle-double-right" size="lg" />
                      </span>
                    )}
                  </>
                )}
                {insurance.status.status > 0 && insurance.status.status < 4 && (
                  <>
                    <span
                      className={
                        insurance.status.status === 2
                          ? "status-item small text-center active"
                          : insurance.status.status > 2
                          ? "status-item small text-center finished"
                          : "status-item small text-center"
                      }
                    >
                      <span>3. Antrag unterzeichnet</span>
                    </span>
                    {insurance.status.status > 1 && (
                      <span className="status-arrow">
                        <MDBIcon icon="angle-double-right" size="lg" />
                      </span>
                    )}
                  </>
                )}
                {insurance.status.status > 1 && insurance.status.status < 5 && (
                  <>
                    <span
                      className={
                        insurance.status.status === 3
                          ? "status-item small text-center active"
                          : insurance.status.status > 3
                          ? "status-item small text-center finished"
                          : "status-item small text-center"
                      }
                    >
                      <span>4. Polizze erhalten</span>
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="d-flex">
            <div
              className="color-badge"
              style={{
                backgroundColor:
                  this.state.background ?? insurance.color ?? "#006eb7",
              }}
            ></div>
            <div className="d-flex justify-content-between align-items-center insurance-container">
              <div className="animation-container">
                <MDBView>
                  <Lottie options={houseAnimation} speed={2} ariaRole="img" />
                  <MDBMask className="clickable" />
                </MDBView>
              </div>
              <div className="p-3 insurance-info">
                <img
                  src={process.env.REACT_APP_MEDIAURL + insurance?.logo_link}
                  alt="Versicherung"
                  className="insurance-partner d-block d-sm-none mb-2"
                />
                {(insurance.title || this.state.showTempTitle) && (
                  <p className="mb-0 text-muted">{insurance.insurance}</p>
                )}
                <p className="mb-0 font-weight-bold lead text-left">
                  {insurance.title || this.state.showTempTitle
                    ? this.state.showTempTitle ?? insurance.title
                    : insurance.insurance}
                </p>
                <div className="text-muted mb-0">
                  {insurance.status.active ? (
                    <>
                      <p className="text-muted small mb-1">
                        Polizze: {insurance.policy_id}
                      </p>
                      <p className="mb-0 text-success small">
                        <MDBIcon icon="check-circle" /> Aktiv
                      </p>
                    </>
                  ) : (
                    <div>
                      {insurance.status.status === 0 && (
                        <div>
                          <span className="small d-block">
                            Du hast die Versicherungsanfrage eingebracht.
                          </span>
                          <MDBBadge color="orange" pill className="z-depth-0">
                            In Bearbeitung
                          </MDBBadge>
                        </div>
                      )}
                      {insurance.status.status === 1 && (
                        <span className="small d-block">
                          Du hast das <strong>Angebot</strong> von Deinem
                          Berater erhalten.
                        </span>
                      )}
                      {insurance.status.status === 2 && (
                        <span className="small d-block">
                          Dein Berater hat die Dokumente erhalten und erstellt
                          die Polizze.
                        </span>
                      )}
                      {insurance.status.status === 3 && (
                        <span className="small d-block">
                          Du hast die <strong>Polizze</strong> von Deinem
                          Berater erhalten.
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {insurance.status.status === 1 && (
                  <MDBBadge
                    color="green"
                    className="z-depth-0 d-block mt-2 py-2"
                  >
                    <MDBIcon icon="signature" className="mr-2" />
                    UNTERSCHRIFT ERFORDERLICH
                  </MDBBadge>
                )}
              </div>
              <div className="text-right">
                <img
                  src={process.env.REACT_APP_MEDIAURL + insurance?.logo_link}
                  alt="Versicherung"
                  className="insurance-partner d-sm-block d-none"
                />
              </div>
            </div>
          </div>
        </MDBListGroupItem>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          size="md"
          animation="left"
          id="overview"
          className="flex-center"
        >
          <MDBModalHeader
            toggle={this.toggleModal}
            style={{
              backgroundColor:
                this.state.background ?? insurance.color ?? "#006eb7",
            }}
          >
            <div className="d-flex align-items-center">
              <p className="mb-0 lead font-weight-bold text-white">
                {this.state.insuranceTitle ?? insurance.policy_id}
              </p>
            </div>
          </MDBModalHeader>
          <MDBModalBody className="text-left">
            {insurance.status.status === 0 && (
              <>
                <MDBAlert className="text-center mb-0">
                  <p className="lead font-weight-bold">
                    Wir haben deine Anfrage erhalten.
                  </p>
                  <p className="mb-0 text-muted">
                    Du erhälst eine Benachrichtung sobald das Angebot für dich
                    bereit steht.
                  </p>
                </MDBAlert>
                <MDBBtn
                  color="grey"
                  className="mx-0 w-100 mt-3"
                  onClick={this.toggleModal}
                >
                  Zurück
                </MDBBtn>
              </>
            )}
            {insurance.status.status === 2 && (
              <div>
                <MDBAlert className="text-center mb-2">
                  <p className="lead font-weight-bold">
                    Wir haben deine Unterschriften erhalten.
                  </p>
                  <p className="mb-0 text-muted">
                    Dein Berater hat die Dokumente erhalten und erstellt die
                    Polizze. Du wirst benachricht, wenn dein Berater die Polizze
                    erstellt hat.
                  </p>
                </MDBAlert>
                <MDBListGroup>
                  {insurance?.submission_documents
                    ?.filter((doc) => doc.document && doc.signature)
                    .map((doc) => {
                      if (doc.document) {
                        return (
                          <a
                            href={process.env.REACT_APP_MEDIAURL + doc.document}
                            target="_blank"
                            className="text-dark"
                          >
                            {this.renderItem(doc)}
                          </a>
                        );
                      } else {
                        return this.renderItem(doc);
                      }
                    })}
                </MDBListGroup>
                <MDBBtn
                  color="grey"
                  className="mx-0 w-100 mt-3"
                  onClick={this.toggleModal}
                >
                  Zurück
                </MDBBtn>
              </div>
            )}
            {(insurance.status.status === 1 || insurance.status.status === 3) &&
              !insurance.policy_id && (
                <div>
                  {insurance.status.status === 1 &&
                    !insurance.status.active &&
                    needsSignature && (
                      <MDBAlert className="text-center mb-3">
                        <p className="lead font-weight-bold">
                          Unterschriften erforderlich
                        </p>
                        <p className="mb-0 text-muted">
                          Einige Dokumente benötigen deine Aufmerksamkeit.
                        </p>
                      </MDBAlert>
                    )}
                  {insurance.status.status === 1 &&
                    !insurance.status.active &&
                    !needsSignature && (
                      <MDBAlert className="text-center mb-3">
                        <p className="lead font-weight-bold">
                          Wir haben deine Unterschriften erhalten.
                        </p>
                        <p className="mb-0 text-muted">
                          Du erhälst eine Benachrichtung sobald die
                          Versicherungsdokumente verfügbar sind.
                        </p>
                      </MDBAlert>
                    )}
                  {(insurance?.submission_documents?.filter(
                    (doc) => !doc.document
                  ).length > 0 ||
                    !insurance?.payment_data?.city) && (
                    <div className="sign-container">
                      <p className="font-weight-bold mb-2">Zu unterzeichnen</p>
                      <MDBListGroup>
                        {insurance?.submission_documents
                          ?.filter((doc) => !doc.document)
                          .map((doc) => {
                            if (doc.template) {
                              return this.renderItem(doc);
                            }
                          })}
                        {!insurance.payment_data?.city && (
                          <MDBListGroupItem
                            onClick={() =>
                              this.setState({
                                signDoc: "payment",
                              })
                            }
                          >
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="mb-1">
                                  <MDBIcon icon="dollar-sign" /> SEPA
                                  Lastschriftmandat
                                </p>
                                <p className="text-muted small">
                                  Durch das SEPA Lastschriftmandat können wir
                                  bequem und automatisch die Versicherungsprämie
                                  von dem angegebenen Konto abbuchen.
                                </p>
                                <MDBBadge color="green" className="z-depth-0">
                                  Unterschrift erforderlich
                                </MDBBadge>
                              </div>
                            </div>
                          </MDBListGroupItem>
                        )}
                      </MDBListGroup>
                    </div>
                  )}
                  <div className="sign-container mt-3">
                    <p className="font-weight-bold mb-2">Unterzeichnet</p>
                    {insurance?.submission_documents?.filter(
                      (doc) => doc.document
                    ).length === 0 && (
                      <span className="text-muted">
                        Noch keine unterzeichneten Dokumente.
                      </span>
                    )}
                    <MDBListGroup>
                      {insurance?.submission_documents
                        ?.filter((doc) => doc.document)
                        .map((doc) => {
                          if (doc.document) {
                            return (
                              <a
                                href={
                                  process.env.REACT_APP_MEDIAURL +
                                  doc.document +
                                  "?token=" +
                                  localStorage.getItem("token")
                                }
                                target="_blank"
                                className="text-dark"
                              >
                                {this.renderItem(doc)}
                              </a>
                            );
                          } else {
                            return this.renderItem(doc);
                          }
                        })}
                    </MDBListGroup>
                  </div>
                </div>
              )}
            {insurance.status.active && (
              <div className="mt-1">
                <p className="lead mb-0">{insurance.policy_id}</p>
                <p className="text-muted small">Polizzennummer</p>
                <p className="lead font-weight-bold mt-3 mb-1">Dateien</p>
                <MDBListGroup className="mb-3">
                  <a
                    href={
                      process.env.REACT_APP_MEDIAURL +
                      insurance.document +
                      "?token=" +
                      localStorage.getItem("token")
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MDBListGroupItem className="text-dark">
                      <p>
                        <MDBIcon far icon="file-pdf" className="mr-2" />
                        Deine Versicherungspolizze (PDF)
                      </p>
                      <p className="small text-muted">Deine finale Polizze</p>
                    </MDBListGroupItem>
                  </a>
                  {insurance?.submission_documents
                    ?.filter((doc) => doc.document && doc.signature)
                    .map((doc) => {
                      if (doc.document) {
                        return (
                          <a
                            href={
                              process.env.REACT_APP_MEDIAURL +
                              doc.document +
                              "?token=" +
                              localStorage.getItem("token")
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBListGroupItem className="text-dark">
                              <p>
                                <MDBIcon far icon="file-pdf" className="mr-2" />
                                {doc.title} (PDF)
                              </p>
                              <p className="small text-muted">
                                {doc.description}
                              </p>
                            </MDBListGroupItem>
                          </a>
                        );
                      }
                    })}
                </MDBListGroup>
                <p className="lead font-weight-bold mt-3 mb-1">
                  Zahlungsinformationen
                </p>
                <MDBRow className="mb-4">
                  <MDBCol lg="6" className="mt-2">
                    <p className="text-muted small">Kontobesitzer</p>
                    <p>
                      {insurance.payment_data.first_name}{" "}
                      {insurance.payment_data.last_name}
                    </p>
                  </MDBCol>
                  <MDBCol lg="6" className="mt-2">
                    <p className="text-muted small">IBAN</p>
                    <p>
                      AT** **** **** **** {insurance.payment_data.iban_ending}
                    </p>
                  </MDBCol>
                  <MDBCol lg="12" className="mt-2">
                    <p className="text-muted small">Adresse</p>
                    <p>
                      {insurance.payment_data.street}{" "}
                      {insurance.payment_data.street_number},{" "}
                      {insurance.payment_data.zipcode},{" "}
                      {insurance.payment_data.city}
                    </p>
                  </MDBCol>
                </MDBRow>
                <p className="lead font-weight-bold mt-3 mb-2">
                  Personalisieren
                </p>
                <p className="text-muted small mb-1">
                  Du hast mehrere Versicherungen? Hier kannst du deiner
                  Versicherung einen eigenen Namen geben.
                </p>
                <div className="d-flex mb-3">
                  <MDBInput
                    value={this.state.insuranceTitle}
                    getValue={(val) => this.setState({ insuranceTitle: val })}
                    className="lead font-weight-bold"
                    containerClass="my-0 flex-1"
                  />
                  {this.state.insuranceTitle !==
                    this.state.insuranceTitleFallback &&
                    this.state.insuranceTitle.trim() !== "" &&
                    this.state.insuranceTitle !== this.state.showTempTitle && (
                      <MDBBtn
                        color="grey"
                        onClick={() => {
                          this.setState({
                            showTempTitle: this.state.insuranceTitle,
                          });

                          this.props.updateInsurancePersonalized(
                            insurance.id,
                            null,
                            this.state.insuranceTitle
                          );
                        }}
                      >
                        <MDBIcon icon="check" className="m-0 p-0" />
                      </MDBBtn>
                    )}
                </div>
                <p className="text-muted small mb-1">Versicherung Farbe</p>
                <CirclePicker
                  width="100%"
                  colors={[
                    "#f44336",
                    "#e91e63",
                    "#9c27b0",
                    "#673ab7",
                    "#3f51b5",
                    "#2196f3",
                    "#03a9f4",
                    "#00bcd4",
                    "#009688",
                    "#4caf50",
                    "#8bc34a",
                  ]}
                  color={this.state.background}
                  onChangeComplete={(color) =>
                    this.handleChangeComplete(color, insurance.id)
                  }
                  className="mb-3"
                />
                <p className="text-muted small">
                  Benötigst Du weitere Informationen, kontaktiere bitte Deinen
                  Berater / deine Beraterin.
                </p>
                <div className="my-4 text-center">
                  <MDBBtn color="grey" onClick={this.toggleModal}>
                    Zurück
                  </MDBBtn>
                </div>
                <div className="d-sm-none d-block mb-5 py-5"></div>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
        {this.state.signDoc && (
          <SignatureModal
            getSignature={this.getSignature}
            signDoc={this.state.signDoc}
            insuranceId={insurance.id}
            next={this.next}
            nextAvailable={signaturesLeft.length > 1}
            toggleModal={() =>
              this.setState(
                {
                  signDoc: undefined,
                },
                () => this.refreshInsuranceSubmissions()
              )
            }
          />
        )}
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  uploadDocuments,
  getInsuranceSubmissions,
  updateInsurancePersonalized,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(InsuranceOverview);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
