//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> File upload
import Dropzone from "react-dropzone";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBView,
  MDBMask,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import { uploadDocument } from "../../../../store/actions/userActions";
//> CSS
import "../index.scss";
//#endregion

//#region > Components
class IdentificationModal extends React.Component {
  state = {
    status: "",
    selectedFiles: [],
    modal: false,
  };

  componentDidMount = () => {
    const { doc, forceShow } = this.props;
    const reminder = localStorage.getItem("verify-reminder");

    if (forceShow && !this.state.modal) {
      this.setState({
        modal: true,
      });
    } else if (!doc && !reminder && !this.state.modal) {
      this.setState({
        modal: true,
      });
    }
  };

  componentDidUpdate = () => {
    const { doc, forceShow } = this.props;
    const reminder = localStorage.getItem("verify-reminder");

    if (forceShow && !this.state.modal) {
      this.setState({
        modal: true,
      });
    } else if (!doc && !reminder && !this.state.modal) {
      this.setState({
        modal: true,
      });
    }
  };

  toggleModal = () => {
    if (this.state.modal) {
      this.setState(
        {
          modal: !this.state.modal,
        },
        () => {
          localStorage.setItem("verify-reminder", true);
          this.props.showReminder();
        }
      );
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };

  getDrop = (files) => {
    const res = files.map((file) => {
      return {
        data: file,
        preview: URL.createObjectURL(file),
      };
    });

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...res],
      status: "",
    });
  };

  removeDrop = (name) => {
    const res = this.state.selectedFiles.filter(
      (file) => file.data.name !== name
    );

    this.setState({
      selectedFiles: res,
    });
  };

  sendFiles = () => {
    const files = this.state.selectedFiles;

    if (files.length > 0) {
      const res = files.map((file) => file.data);

      this.setState(
        {
          loading: true,
        },
        async () => {
          const final = await this.props.uploadDocument(res[0], res[1]);

          if (final.status) {
            localStorage.removeItem("verify-reminder");

            window.location.reload();
          } else {
            this.setState({
              loading: false,
            });
          }
        }
      );
    }
  };

  render() {
    return (
      <div id="identification">
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          size="lg"
          className="d-flex flex-center"
          animation="left"
        >
          <MDBModalBody className="text-center">
            <div className="mb-4">
              <p className="lead font-weight-bold mb-2">
                Verifizierung ausstehend
              </p>
              <p>
                Zur Bestätigung der Echtheit Ihrer Angaben benötigen wir eines
                der folgenden Dokumente:
              </p>
              <p className="mb-3">
                <strong>Personalausweis, Führerschein</strong> oder{" "}
                <strong>Reisepass.</strong>
              </p>
              <p className="text-warning">
                <MDBIcon icon="exclamation-triangle" /> Bitte beachten:
                Vorderseite und Rückseite hinzufügen.
              </p>
            </div>
            <Dropzone
              onDrop={(acceptedFiles) => this.getDrop(acceptedFiles)}
              //onDropAccepted={() => this.setState({ status: "accept" })}
              onDropRejected={() => this.setState({ status: "reject" })}
              onDragOver={() => this.setState({ status: "active" })}
              onDragLeave={() => this.setState({ status: "" })}
              accept="image/jpeg, image/png"
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className={"wrapper " + this.state.status}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p>
                      Ziehe Dateien hierher oder klicke auf das Feld, um Dateien
                      auszuwählen
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            <aside className="thumbs-container mb-3">
              {this.state.selectedFiles.length > 0 &&
                this.state.selectedFiles.map((file) => {
                  return (
                    <div className="thumb" key={file.data.name}>
                      <div className="thumb-inner">
                        <MDBView
                          onClick={() => this.removeDrop(file.data.name)}
                        >
                          <img src={file.preview} alt="File preview" />
                          <MDBMask
                            className="d-flex flex-center"
                            overlay="black-slight"
                          >
                            <MDBIcon
                              far
                              icon="trash-alt"
                              className="text-white"
                            />
                          </MDBMask>
                        </MDBView>
                      </div>
                    </div>
                  );
                })}
            </aside>
            <div>
              <MDBBtn
                color="blue"
                disabled={
                  !this.state.selectedFiles.length > 0 || this.state.loading
                }
                onClick={this.sendFiles}
                className="d-block w-100"
              >
                {this.state.loading ? (
                  <>
                    <MDBIcon icon="spinner" className="fa-spin" /> Lade hoch...
                  </>
                ) : (
                  <>
                    <MDBIcon icon="angle-right" /> Weiter
                  </>
                )}
              </MDBBtn>
              {!this.state.loading && (
                <MDBBtn
                  color="grey"
                  onClick={this.toggleModal}
                  className="d-block w-100 mt-3"
                >
                  <MDBIcon icon="times" />
                  Später
                </MDBBtn>
              )}
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  uploadDocument,
};
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentificationModal);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
