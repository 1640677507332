//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";

//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBFooter, MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { Link } from "react-router-dom";

//> Style
import "./index.scss";
//#endregion

//#region > Components
/**
 * @class Footer Component
 * @description Global footer
 */
class Footer extends React.Component {
  render() {
    return (
      <MDBFooter className="font-small pt-4 mt-auto text-dark">
        <MDBContainer className="text-center text-md-left">
          <MDBRow className="justify-content-center text-center">
            <MDBCol lg="2">
              <a href="https://www.spardaplus.at/impressum" target="_blank">
                Impressum
              </a>
            </MDBCol>
            <MDBCol lg="2">
              <a href="https://www.spardaplus.at/datenschutz/" target="_blank">
                Datenschutz
              </a>
            </MDBCol>
            <MDBCol lg="2">
              <a href="https://www.spardaplus.at/agb/" target="_blank">
                AGB
              </a>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright text-center py-3 text-dark">
          <MDBContainer fluid>
            &copy; {new Date().getFullYear()} Copyright:
            <a
              href="https://www.spardaplus.at"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Sparda Plus Versicherungsservice
            </a>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}
//#endregion

//#region > Exports
export default Footer;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
