//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBBtn,
  MDBIcon,
  MDBMask,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBProgress,
  MDBSpinner,
  MDBView,
  toast,
  ToastContainer,
} from "mdbreact";
//> Lottie
import Lottie from "lottie-react-web";
//> Redux
// Connect
import { connect } from "react-redux";
//> QR Code generation
import QRCode from "qrcode";

//> Actions
import { requestResetPassword } from "../../../../store/actions/userActions";
//> CSS
import "../index.scss";
//> Components
import PDFSignatureView from "../../../molecules/modules/PDFSignatureView";
//> Lottie
import signAnimationFile from "../../../../assets/lottie/sign.json";
import finishedAnimationFile from "../../../../assets/lottie/finished.json";
//#endregion

//#region > Config
const signAnimation = {
  loop: true,
  autoplay: true,
  animationData: signAnimationFile,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const finishedAnimation = {
  loop: false,
  autoplay: true,
  animationData: finishedAnimationFile,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
//#endregion

//#region > Components
class QRModal extends React.Component {
  state = { qrCode: null };

  componentDidMount = () => {
    const { token, url, action } = this.props;

    this.generateCode(token, url, action);

    // Retrieve GET so we can let the user know that he can proceed on mobile and notify him, when he is done
  };

  componentDidUpdate = (prevProps) => {
    // On every token change, redo the QR code
    if (prevProps.token !== this.props.token) {
      const { token, url, action } = this.props;

      this.generateCode(token, url, action);
    }
  };

  copyLink = () => {
    navigator.clipboard.writeText(this.state.link);

    toast.info("Link in die Zwischenablage kopiert.", {
      closeButton: false,
    });
  };

  generateCode = async (token, url, action) => {
    try {
      const link = `${
        url ? url : process.env.REACT_APP_FRONT_URL
      }/id/${action}/${token}`;

      const qrCode = await QRCode.toDataURL(link, {
        errorCorrectionLevel: "H",
        type: "image/jpeg",
        quality: 0.3,
      });

      this.setState({
        qrCode,
        link,
      });
    } catch (err) {
      this.setState({
        qrCode: false,
      });
    }
  };

  render() {
    const { nextAvailable, toggleModal, isOnMobile, isFinished } = this.props;

    return (
      <div id="qrModal">
        <MDBModal
          isOpen={true}
          toggle={toggleModal}
          size="md"
          animation="left"
          className="flex-center"
        >
          <MDBModalHeader toggle={toggleModal}>
            <div className="inline-block">
              <img
                src={require("../../../../assets/sid_light.png")?.default}
                alt="Sparda Plus ID"
                className="img-fluid logo mx-auto d-block"
              />
            </div>
            <div className="inline-block">
              <p className="lead font-weight-bold">Sparda Plus Identity</p>
              <p className="small">Sign documents with your mobile device.</p>
            </div>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBProgress
              color="info"
              value={
                isOnMobile && !isFinished ? "66" : isFinished ? "100" : "33"
              }
              max="100"
              material
              height="20px"
              striped
              animated={!isFinished && this.state.showQrCode}
              className="mb-1"
            />
            <p className="small text-center mb-3">
              {isOnMobile && !isFinished
                ? "Schritt 2 / 3"
                : isFinished
                ? "Schritt 3 / 3"
                : "Schritt 1 / 3"}
            </p>
            {isOnMobile ? (
              <>
                {isFinished ? (
                  <div className="blue-bg text-center">
                    <div className="animation-container">
                      <Lottie
                        options={finishedAnimation}
                        speed={1}
                        ariaRole="img"
                      />
                    </div>
                    <p className="h3 font-weight-bold mb-2">
                      Dokument unterzeichnet
                    </p>
                    <p className="mb-3">
                      Das Dokument wurde erfolgreich unterzeichnet.
                    </p>
                    <MDBBtn
                      color="white"
                      outline
                      onClick={this.props.closeSession}
                    >
                      Schließen
                    </MDBBtn>
                    {nextAvailable && (
                      <MDBBtn color="white" onClick={this.props.next}>
                        Nächstes
                      </MDBBtn>
                    )}
                  </div>
                ) : (
                  <div className="blue-bg text-center">
                    <p className="h3 font-weight-bold mb-2">
                      Am Handy unterschrieben
                    </p>
                    <p className="mb-3">
                      Nun unterschreibe bitte im Unterschriftsfeld auf deinem
                      Handy.
                    </p>
                    <div className="white rounded">
                      <div className="animation-container">
                        <Lottie
                          options={signAnimation}
                          speed={1}
                          ariaRole="img"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {this.state.qrCode && this.state.showQrCode ? (
                  <>
                    <div className="blue-bg">
                      <p className="h3 font-weight-bold text-center mb-2">
                        Dein sicherer Link
                      </p>
                      <p className="font-weight-bold mb-4 text-center">
                        QR-Code mit deinem Handy scannen
                      </p>
                      <div className="mt-4 mb-2 text-center">
                        <MDBView className="w-100">
                          <img
                            src={this.state.qrCode}
                            alt="QR Code"
                            className="img-fluid qr m-auto"
                          />
                          <MDBMask />
                        </MDBView>
                      </div>
                    </div>
                    <div className="w-100 mb-2 text-center">
                      <div className="splitter mt-3 mb-2">
                        <span className="or">
                          <span className="or-text">oder</span>
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <MDBBtn color="white" onClick={this.copyLink}>
                        <MDBIcon icon="link" className="mr-2" />
                        Link kopieren
                      </MDBBtn>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="blue-bg">
                      <p className="h3 font-weight-bold text-center mb-2">
                        Auf deinem Handy fortfahren
                      </p>
                      <p className="font-weight-bold mb-4 text-center">
                        So gehst du vor:
                      </p>
                      <div className="sign-steps">
                        <span>1</span>
                        <p>Sende einen sicheren Link an dein Handy.</p>
                      </div>
                      <div className="sign-steps">
                        <span>2</span>
                        <p>
                          Öffne den Link und unterschreibe das Dokument bequem
                          am Handy.
                        </p>
                      </div>
                      <div className="sign-steps">
                        <span>3</span>
                        <p>
                          Komm wieder hierher zurück, um die Übermittlung
                          abzuschließen.
                        </p>
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <MDBBtn
                        color="white"
                        size="lg"
                        className="font-weight-bold"
                        onClick={() => this.setState({ showQrCode: true })}
                      >
                        Hol dir den sicheren Link
                      </MDBBtn>
                    </div>
                  </>
                )}
              </>
            )}
          </MDBModalBody>
        </MDBModal>
        <ToastContainer
          hideProgressBar={false}
          newestOnTop={true}
          autoClose={2500}
        />
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  requestResetPassword,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(QRModal);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
