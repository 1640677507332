//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBModal, MDBModalBody, MDBModalHeader, MDBBtn } from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";
//> QR Code generation
import QRCode from "qrcode";
//> Lottie
import Lottie from "lottie-react-web";

//> Actions
import { requestResetPassword } from "../../../../store/actions/userActions";
//> CSS
import "../index.scss";
//> Lottie
import finishedAnimationFile from "../../../../assets/lottie/finished.json";
//> Components
import { SignaturePad } from "../../../atoms";
//#endregion

//#region > Config
const finishedAnimation = {
  loop: false,
  autoplay: true,
  animationData: finishedAnimationFile,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
//#endregion

//#region > Components
class SignModal extends React.Component {
  state = { qrCode: null };

  getFile = (signatureFile) => {
    this.props.getSignature(signatureFile);
  };

  render() {
    const { isFinished, nextAvailable } = this.props;

    return (
      <div id="signModal">
        <MDBModal
          isOpen={true}
          toggle={this.props.toggleModal}
          size="md"
          animation="left"
          className="flex-center"
        >
          <MDBModalHeader toggle={this.props.toggleModal}>
            <div className="inline-block">
              <img
                src={require("../../../../assets/sid_light.png")?.default}
                alt="Sparda Plus ID"
                className="img-fluid logo mx-auto d-block"
              />
            </div>
            <div className="inline-block">
              <p className="lead font-weight-bold">Sparda Plus Identity</p>
              <p className="small">Sign documents with your PC mouse.</p>
            </div>
          </MDBModalHeader>
          <MDBModalBody>
            {isFinished ? (
              <div className="blue-bg text-center">
                <div className="animation-container">
                  <Lottie
                    options={finishedAnimation}
                    speed={1}
                    ariaRole="img"
                  />
                </div>
                <p className="h3 font-weight-bold mb-2">
                  Dokument unterzeichnet
                </p>
                <p className="mb-3">
                  Das Dokument wurde erfolgreich unterzeichnet.
                </p>
                <MDBBtn color="white" outline onClick={this.props.closeSession}>
                  Schließen
                </MDBBtn>
                {nextAvailable && (
                  <MDBBtn color="white" onClick={this.props.next}>
                    Nächstes
                  </MDBBtn>
                )}
              </div>
            ) : (
              <>
                <div className="blue-bg">
                  <p>
                    Unterschreibe mit deiner PC Maus in dem Unterschriftfeld.
                    <br />
                    <span className="text-muted small">
                      (Solltest Du nicht zufrieden mit deiner Unterschrift sein,
                      kannst Du diese jederzeit zurücksetzen und es neu
                      versuchen.)
                    </span>
                  </p>
                </div>
                <SignaturePad getFile={this.getFile} title="Unterschriftfeld" />
              </>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  requestResetPassword,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(SignModal);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
