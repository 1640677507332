//#region > Imports
import { reportException } from "../../helpers/errorReporting";
//#endregion

//#region > Imports
//> Time
import moment from "moment";
//#endregion

//#region > Signature
// Request token for signature
export const requestSignature = (id, force) => {
  return (dispatch, getState) => {
    return fetch(
      `${process.env.REACT_APP_BASEURL}insurance/sign/document/${id}/request/`,
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          force: force ?? false,
        }),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not request signature.",
              "POST insurance/sign/document/<id>/request/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not request signature.",
              "POST insurance/sign/document/<id>/request/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not request signature.",
          "POST insurance/sign/document/<id>/request/"
        );

        return { status: false, data: error };
      });
  };
};

// Find out what the current state of the signature is
export const getSignatureProgress = (signToken) => {
  return (dispatch, getState) => {
    return fetch(
      `${process.env.REACT_APP_BASEURL}insurance/sign/document/progress/`,
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: signToken,
        }),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not get signature progress.",
              "POST insurance/sign/document/progress/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not get signature progress.",
              "POST insurance/sign/document/progress/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not get signature progress.",
          "POST insurance/sign/document/progress/"
        );

        return { status: false, data: error };
      });
  };
};

export const signDocument = (signatureImage, signToken, id) => {
  return (dispatch, getState) => {
    return fetch(
      `${process.env.REACT_APP_BASEURL}insurance/sign/document/signature/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: signToken,
          signature: signatureImage,
        }),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not sign document.",
              "POST insurance/sign/document/signature/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not sign document.",
              "POST insurance/sign/document/signature/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not sign document.",
          "POST insurance/sign/document/signature/"
        );

        return { status: false, data: error };
      });
  };
};

export const getInsuranceSubmissions = () => {
  return (dispatch, getState) => {
    return fetch(`${process.env.REACT_APP_BASEURL}insurance/show/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not get insurance submission.",
              "GET insurance/show/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not get insurance submission.",
              "GET insurance/show/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not get insurance submission.",
          "GET insurance/show/"
        );

        return { status: false, data: error };
      });
  };
};

export const callToken = (signToken) => {
  return (dispatch, getState) => {
    return fetch(
      `${process.env.REACT_APP_BASEURL}insurance/sign/document/call/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: signToken,
        }),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not call token.",
              "POST insurance/sign/document/call/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not call token.",
              "POST insurance/sign/document/call/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not call token.",
          "POST insurance/sign/document/call/"
        );

        return { status: false, data: error };
      });
  };
};

export const requestSignDocument = (insurance_id) => {
  return (dispatch, getState) => {
    return fetch(
      `${process.env.REACT_APP_BASEURL}insurance/sign/document/${insurance_id}/request/`,
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ payment_document: true }),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not request sign document.",
              "POST insurance/sign/document/<id>/request/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not request sign document.",
              "POST insurance/sign/document/<id>/request/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not request sign document.",
          "POST insurance/sign/document/<id>/request/"
        );

        return { status: false, data: error };
      });
  };
};

export const addPaymentData = (paymentData, insurance_id) => {
  return (dispatch, getState) => {
    return fetch(
      `${process.env.REACT_APP_BASEURL}insurance/sign/document/${insurance_id}/payment/`,
      {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentData),
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not add payment data.",
              "POST insurance/sign/document/<id>/payment/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not add payment data.",
              "POST insurance/sign/document/<id>/payment/"
            );

            return false;
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not add payment data.",
          "POST insurance/sign/document/<id>/payment/"
        );

        return { status: false, data: error };
      });
  };
};
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
