//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> File upload
import Dropzone from "react-dropzone";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBSelect,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBView,
  MDBMask,
  MDBModalHeader,
} from "mdbreact";

//> CSS
import "../index.scss";

//> Redux
// Connect
import { connect } from "react-redux";
//> Actions
import { createDamageReport } from "../../../../store/actions/userActions";
//#endregion

//#region > Components
class SubModal extends React.Component {
  state = {
    selectedFiles: [],
    selectedOption: undefined,
    modal: false,
    note: "",
    options: [
      {
        text: "Haushaltsversicherung",
        value: "haushaltsversicherung",
      },
    ],
  };

  componentDidMount = () => {
    const insurances = this.props.data?.insurances;

    if (insurances) {
      const activeInsurances = insurances.filter(
        (insurance) => insurance.status.active === true
      );

      this.setState({
        options: activeInsurances.map((ins) => {
          return {
            text: ins.insurance + " - " + ins.policy_id,
            value: ins.policy_id,
          };
        }),
      });
    }
  };

  toggleModal = () => {
    if (!this.state.modal) {
      this.setState({
        modal: !this.state.modal,
        selectedOption: undefined,
        note: "",
        selectedFiles: [],
        response: false,
      });
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };

  getDrop = (files) => {
    const res = files.map((file) => {
      return {
        data: file,
        preview: URL.createObjectURL(file),
      };
    });

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...res],
      status: "",
    });
  };

  removeDrop = (name) => {
    const res = this.state.selectedFiles.filter(
      (file) => file.data.name !== name
    );

    this.setState({
      selectedFiles: res,
    });
  };

  sendFiles = async () => {
    const { selectedFiles, note, selectedOption } = this.state;

    if (note && selectedOption && selectedOption.length > 0) {
      let files = undefined;

      if (selectedFiles.length > 0) {
        files = selectedFiles.map((file) => file.data);
      }

      const res = await this.props.createDamageReport(
        selectedOption[0],
        note,
        files
      );

      this.setState({
        response: res,
      });
    }
  };

  render() {
    return (
      <div id="damagereport" className="d-inline-block">
        {this.props.data &&
          this.props.data.insurances &&
          this.props.data.insurances.length > 0 && (
            <MDBBtn
              onClick={this.toggleModal}
              color="orange"
              size="md"
              className="m-1"
            >
              <MDBIcon icon="fire" />
              Neue Schadensmeldung
            </MDBBtn>
          )}
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          size="md"
          animation="left"
          className="flex-center"
        >
          <MDBModalHeader toggle={this.toggleModal}>
            <p className="lead font-weight-bold">Schadensmeldung verfassen</p>
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {this.state.response ? (
              <>
                {this.state.response.data?.open_report ? (
                  <MDBAlert color="warning" className="mb-0">
                    Zu dieser Versicherung liegt bereits eine offene
                    Schadensmeldung vor.
                  </MDBAlert>
                ) : (
                  <>
                    {this.state.response.status ? (
                      <MDBAlert color="success" className="mb-0">
                        Die Schadensmeldung wurde erfolgreich erstellt.
                      </MDBAlert>
                    ) : (
                      <MDBAlert color="danger" className="mb-0">
                        Die Schadensmeldung konnte nicht erstellt werden. Bitte
                        versuche es später erneut oder kontaktiere deinen
                        Berater.
                      </MDBAlert>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <form>
                  <MDBRow className="justify-content-center text-left">
                    <MDBCol lg="12">
                      <MDBSelect
                        options={this.state.options}
                        selected="Polizze auswählen"
                        label="Versicherung wählen"
                        getValue={(val) =>
                          this.setState({ selectedOption: val })
                        }
                      />
                      <p className="text-muted mb-1">Beschreibe den Schaden</p>
                      <MDBInput
                        type="textarea"
                        rows="5"
                        outline
                        containerClass="mt-0"
                        value={this.state.note}
                        getValue={(val) => this.setState({ note: val })}
                      />
                      <p className="text-muted mb-1">
                        Bilder hochladen (optional)
                      </p>
                      <Dropzone
                        onDrop={(acceptedFiles) => this.getDrop(acceptedFiles)}
                        //onDropAccepted={() => this.setState({ status: "accept" })}
                        onDropRejected={() =>
                          this.setState({ status: "reject" })
                        }
                        onDragOver={() => this.setState({ status: "active" })}
                        onDragLeave={() => this.setState({ status: "" })}
                        accept="image/jpeg, image/png"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className={"wrapper " + this.state.status}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <p>
                                Ziehe Dateien hierher oder klicke auf das Feld,
                                um Dateien auszuwählen
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <aside className="thumbs-container mb-3">
                        {this.state.selectedFiles.length > 0 &&
                          this.state.selectedFiles.map((file) => {
                            return (
                              <div className="thumb" key={file.data.name}>
                                <div className="thumb-inner">
                                  <MDBView
                                    onClick={() =>
                                      this.removeDrop(file.data.name)
                                    }
                                  >
                                    <img
                                      src={file.preview}
                                      alt="File preview"
                                    />
                                    <MDBMask
                                      className="d-flex flex-center"
                                      overlay="black-slight"
                                    >
                                      <MDBIcon
                                        far
                                        icon="trash-alt"
                                        className="text-white"
                                      />
                                    </MDBMask>
                                  </MDBView>
                                </div>
                              </div>
                            );
                          })}
                      </aside>
                    </MDBCol>
                  </MDBRow>
                </form>
                <div className="d-flex justify-content-between">
                  <MDBBtn color="danger" onClick={this.toggleModal}>
                    <MDBIcon icon="times" />
                    Abbrechen
                  </MDBBtn>
                  <MDBBtn
                    color="blue"
                    disabled={
                      !this.state.selectedOption ||
                      (this.state.selectedOption &&
                        !this.state.selectedOption.length > 0) ||
                      !this.state.note
                    }
                    onClick={this.sendFiles}
                  >
                    <MDBIcon far icon="paper-plane" />
                    Senden
                  </MDBBtn>
                </div>
              </>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  createDamageReport,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(SubModal);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
