//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { BrowserRouter as Router } from "react-router-dom";
//> Redux
// Connect
import { connect } from "react-redux";
// Actions
import { getUserData } from "./store/actions/authActions";
//> Error reporting
import * as Sentry from "@sentry/browser";
//> Components
/**
 * Footer: Global Footer
 */
import { Footer } from "./components/molecules";
// Starts the page on top when reloaded or redirected
import { ScrollToTop } from "./components/atoms";

//> Routes
import Routes from "./Routes";
//#endregion

//#region > Components
class App extends React.Component {
  componentDidMount = () => {
    // Set current application version to Sentry
    Sentry.setTag("version", process.env.REACT_APP_VERSION);
    // Set current API version
    Sentry.setTag(
      "API Version",
      process.env.REACT_APP_BASEURL.split("/")[
        process.env.REACT_APP_BASEURL.split("/").length - 2
      ]
    );

    // Get token from local storage
    const token = localStorage.getItem("token");

    // Check if a possible user is logged in
    if (token) {
      this.props.getUserData();
    }
  };

  render() {
    return (
      <Router>
        <ScrollToTop>
          <main className="pt-5">
            <Routes />
          </main>
          <Footer />
        </ScrollToTop>
      </Router>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
  error: state.auth.error,
});

const mapDispatchToProps = {
  getUserData,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(App);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
