//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { Route, Switch } from "react-router-dom";

//> Components
import {
  HomePage,
  LoginPage,
  ProfilePage,
  MessagePage,
  ActionPage,
  SignaturePage,
  QRPage,
} from "./components/pages";
//#endregion

//#region > Config
//> MessagePage content
const messagePage = ["about", "privacy", "gtc"];
//#endregion

//#region > Components
/**
 * @class Routes Components
 * @description Contains all routes
 */
class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/me" component={ProfilePage} />
        <Route exact path="/verify/:token" component={ActionPage} />
        <Route exact path="/id/sign/:token" component={SignaturePage} />
        <Route exact path="/qr" component={QRPage} />
        <Route exact path="/reset/:token/:email?" component={ActionPage} />
        {messagePage.map((page, i) => {
          return (
            <Route key={i} exact path={"/" + page} component={MessagePage} />
          );
        })}
        <Route
          render={function () {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}
//#endregion

//#region > Exports
export default Routes;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
