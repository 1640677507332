//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBInput,
  MDBModalHeader,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import { requestResetPassword } from "../../../../store/actions/userActions";
//> CSS
import "../index.scss";
//#endregion

//#region > Components
class ResetPassword extends React.Component {
  state = {};

  toggleModal = () => {
    if (this.state.modal) {
      this.setState({
        modal: !this.state.modal,
        success: false,
        email: "",
      });
    } else {
      // Set email if passed on
      const { email } = this.props;

      this.setState({
        modal: !this.state.modal,
        email: email ? email : "",
      });
    }
  };

  resetPassword = async () => {
    const { email } = this.state;

    // Check if an email was entered
    if (email?.trim()) {
      const res = await this.props.requestResetPassword(email);

      if (res.status) {
        this.setState({
          success: true,
        });
      } else {
        this.setState({
          success: false,
        });
      }
    }
  };

  render() {
    return (
      <div id="userdata">
        <div
          className="text-left small clickable text-muted"
          onClick={this.toggleModal}
        >
          Passwort vergessen?
        </div>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          size="md"
          animation="left"
          className="flex-center"
        >
          <MDBModalHeader toggle={this.toggleModal}>
            <p className="mb-0 small">Passwort zurücksetzen</p>
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <div className="mb-4">
              <p className="text-muted small text-left">
                Bitte gebe deine E-Mail Adresse ein. Du erhälst dann einen Link,
                wo du dein Passwort zurücksetzen kannst.
              </p>
            </div>
            <MDBInput
              type="email"
              label="E-Mail"
              value={this.state.email}
              getValue={(val) => this.setState({ email: val })}
              containerClass="my-2"
              disabled={this.state.success}
              outline
            />
            {this.state.success ? (
              <MDBAlert color="success" className="mt-2 mb-0">
                <p className="mb-0">
                  Eine E-Mail zum Zurücksetzen des Passworts wurde Dir an{" "}
                  {this.state.email} gesendet.
                </p>
                <MDBBtn color="success" onClick={this.toggleModal}>
                  <MDBIcon icon="check" />
                  Schließen
                </MDBBtn>
              </MDBAlert>
            ) : (
              <div className="d-flex justify-content-between">
                <MDBBtn color="grey" onClick={this.toggleModal}>
                  <MDBIcon icon="times" />
                  Abbrechen
                </MDBBtn>
                <MDBBtn
                  color="blue"
                  onClick={this.resetPassword}
                  disabled={!this.state.email}
                >
                  <MDBIcon icon="angle-right" />
                  Weiter
                </MDBBtn>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  requestResetPassword,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
