//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { Redirect } from "react-router-dom";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBContainer } from "mdbreact";

//> Components
import { Navbar, LoginUser } from "../../molecules";
//#endregion

//#region > Config
// The route of your profile page (include /)
const profileRoute = "/me";
//#endregion

//#region > Components
class LoginPage extends React.Component {
  state = {
    email: "",
    password: "",
    loading: false,
  };

  componentDidMount = () => {};

  render() {
    const { logged, location } = this.props;

    // Get GET parameters from URL
    let params = location.search.substr(1)
      ? location.search.substr(1).split("=")
      : null;

    // Check if there are any GET parameters in the URL
    if (params) {
      // Check if the GET parameter on position 0 is a refer
      if (params[0] === "refer") {
        // Check for custom refers (You would use this when a user tries to access /me, but is not logged in)
        switch (params[1]) {
          case "me":
            if (logged || localStorage.getItem("token"))
              return <Redirect to="/me" />;
            break;
          default:
            if (logged || localStorage.getItem("token"))
              return <Redirect to={profileRoute} />;
        }
      }
    } else {
      // User is not logged in and has no refer GET parameter
      if (logged || localStorage.getItem("token"))
        return <Redirect to={profileRoute} />;
    }

    return (
      <>
        <Navbar inQuestionnaire={false} loader={this.state.loading} />
        <div id="login">
          <MDBContainer className="text-left py-5">
            <LoginUser />
          </MDBContainer>
        </div>
      </>
    );
  }
}
//#endregion

//#region > Exports
export default LoginPage;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
