//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> Animations
import { animations } from "react-animation";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBSwitch,
  MDBProgress,
  MDBIcon,
  MDBSpinner,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBListGroup,
  MDBListGroupItem,
  MDBAlert,
} from "mdbreact";
//> Redux
// Basic Redux provider
import { connect } from "react-redux";

//> Redux Actions
import {
  calculateParameters,
  sendQuestionnaire,
} from "../../../../store/actions/questionnaireActions";
import { getUserData } from "../../../../store/actions/authActions";
//> Components
// Modules
import {
  NumberModule,
  SingleChoiceCardModule,
} from "../../../molecules/modules";
// Molecules
import { RegisterUser, LoginUser } from "../../../molecules";
//> Images
import { ReactComponent as Wilderness } from "../../../../assets/content/insurances/haushaltsversicherung/wilderness.svg";
import { ReactComponent as City } from "../../../../assets/content/insurances/haushaltsversicherung/city.svg";
import { ReactComponent as Active } from "../../../../assets/content/insurances/haushaltsversicherung/active.svg";
import { ReactComponent as Empty } from "../../../../assets/content/insurances/haushaltsversicherung/empty.svg";
import { ReactComponent as Security } from "../../../../assets/content/insurances/haushaltsversicherung/security.svg";
import { ReactComponent as UnSecure } from "../../../../assets/content/insurances/haushaltsversicherung/unsecure.svg";
import { ReactComponent as PublicNo } from "../../../../assets/content/insurances/haushaltsversicherung/publicno.svg";
import { ReactComponent as PublicYes } from "../../../../assets/content/insurances/haushaltsversicherung/publicyes.svg";
import { ReactComponent as HasAccount } from "../../../../assets/content/insurances/haushaltsversicherung/account.svg";
import { ReactComponent as NoAccount } from "../../../../assets/content/insurances/haushaltsversicherung/notsure.svg";
import { ReactComponent as House } from "../../../../assets/content/insurances/haushaltsversicherung/house.svg";
import { ReactComponent as Fishing } from "../../../../assets/content/insurances/haushaltsversicherung/fishing.svg";
import { ReactComponent as Family } from "../../../../assets/content/insurances/haushaltsversicherung/family.svg";
import { ReactComponent as Single } from "../../../../assets/content/insurances/haushaltsversicherung/single.svg";
import { ReactComponent as FamilyTime } from "../../../../assets/content/insurances/haushaltsversicherung/familytime.svg";
import { ReactComponent as Beach } from "../../../../assets/content/insurances/haushaltsversicherung/beach.svg";
import { ReactComponent as Home } from "../../../../assets/content/insurances/haushaltsversicherung/home.svg";
import { ReactComponent as noMoney } from "../../../../assets/content/insurances/haushaltsversicherung/noMoney.svg";
import { ReactComponent as securityOn } from "../../../../assets/content/insurances/haushaltsversicherung/securityOn.svg";
import { ReactComponent as Jewelry } from "../../../../assets/content/insurances/haushaltsversicherung/jewelry.svg";
// Sates of Austria
import Burgenland from "../../../../assets/content/states/burgenland.png";
import Carinthia from "../../../../assets/content/states/carinthia.png";
import LowerAustria from "../../../../assets/content/states/lower_austria.png";
import UpperAustria from "../../../../assets/content/states/upper_austria.png";
import Salzburg from "../../../../assets/content/states/salzburg.png";
import Styria from "../../../../assets/content/states/styria.png";
import Tirol from "../../../../assets/content/states/tirol.png";
import Vorarlberg from "../../../../assets/content/states/vorarlberg.png";
import Vienna from "../../../../assets/content/states/vienna.png";
// React Logo
import { ReactComponent as Logo } from "../../../../assets/logo_white.svg";
import { ReactComponent as LogoDark } from "../../../../assets/logo.svg";

//> Style
import "./index.scss";
//#endregion

//#region > Config
const CONFIG = {
  length: 7,
};

const OPTIONAL_INSURANCE = [
  {
    label: "Unversperrter Schmuck, Edelsteine, Edelmetalle, Sammlungen",
    info: "Von EUR 15.000 - EUR 25.000 versichert.",
  },
];

const styleFadeInUp = {
  animation: animations.fadeInUp,
};
const stylePopIn = {
  animation: animations.popIn,
};
//#endregion

//#region > Components
class InsuranceQuestionnaire extends React.Component {
  state = { currentDepth: 0, paymentYearly: false, successData: undefined };

  componentDidMount = () => {
    if (localStorage.getItem("progress")) {
      const data = localStorage.getItem("progress");
      const parsed = JSON.parse(data);

      this.setState({
        ...parsed,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.logged !== this.props.logged) {
      this.props.getUserData();
    }

    if (prevProps.data !== this.props.data) {
      this.setState({
        selectedMemberInsurance: this.state.selectedInsurance,
      });
    }
  };

  setValue = (name, value) => {
    window.scrollTo(0, 0);

    this.setState(
      { [name]: value, currentDepth: this.state.currentDepth + 1 },
      () => this.saveProgress()
    );
  };

  authAction = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  saveProgress = () => {
    localStorage.setItem(
      "progress",
      JSON.stringify({
        ...this.state,
      })
    );
  };

  goBack = () => {
    this.setState({ currentDepth: this.state.currentDepth - 1 }, () =>
      this.saveProgress()
    );
  };

  next = () => {
    this.setState({ currentDepth: this.state.currentDepth + 1 }, () =>
      this.saveProgress()
    );
  };

  calculate = () => {
    this.setState(
      {
        loading: true,
        successData: [],
      },
      async () => {
        const res = await this.props.calculateParameters(
          this.state,
          "household"
        );

        if (res.status) {
          this.setState({
            successData: res.data[1],
            successDataParameters: res.data[0],
            loading: false,
          });
        } else {
          this.setState({
            successData: false,
            successDataParameters: null,
            loading: false,
          });
        }
      }
    );
  };

  selectInsurance = (insurance) => {
    const { data } = this.props;

    // Check if the user is logged in
    if (data) {
      this.setState(
        {
          selectedMemberInsurance: insurance,
        },
        () => window.scrollTo(0, 0)
      );
    } else {
      this.setState(
        {
          selectedInsurance: insurance,
        },
        () => window.scrollTo(0, 0)
      );
    }
  };

  submitForm = async () => {
    //const { first_name, last_name, email, password, zip, address } = this.props.data;
    this.setState({
      loading: true,
    });

    const insurance =
      this.state.selectedMemberInsurance ?? this.state.selectedInsurance;

    const res = await this.props.sendQuestionnaire(
      "household",
      this.state.successDataParameters,
      insurance.id,
      null,
      {
        payment_interval: this.state.paymentYearly ? "monthly" : "annual",
        price: this.state.paymentYearly
          ? insurance.price ?? insurance.price / 12 >= insurance.month_min_price
            ? insurance.price / 12
            : insurance.month_min_price
          : insurance.price,
      }
    );

    if (res.status) {
      // Success
      window.location.replace("/me");
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  render() {
    const progress = (100 / CONFIG.length) * (this.state.currentDepth + 1);

    // Check if questionnaire is done
    if (this.state.currentDepth >= CONFIG.length && !this.state.successData) {
      // Calculate price
      this.calculate();
    }

    return (
      <div className="household-insurance">
        {this.state.successData ? (
          <div className="text-center">
            {this.state.selectedInsurance && !this.props.data ? (
              <div>
                {this.state.account === 0 && (
                  <RegisterUser
                    cancelRegister={() => this.setState({ account: undefined })}
                    insuranceAgency={this.state.selectedInsurance}
                    insuranceSlug="household"
                    insuranceDetails={this.state.successDataParameters}
                    paymentInterval={
                      this.state.paymentYearly ? "monthly" : "annual"
                    }
                    price={
                      this.state.paymentYearly
                        ? this.state.selectedInsurance?.price / 12 >=
                          this.state.selectedInsurance?.month_min_price
                          ? this.state.selectedInsurance?.price / 12
                          : this.state.selectedInsurance?.month_min_price
                        : this.state.selectedInsurance?.price
                    }
                  />
                )}
                {this.state.account === 1 && (
                  <MDBModal
                    isOpen={this.state.account === 1}
                    toggle={() => this.setState({ account: undefined })}
                    size="lg"
                    id="loginModal"
                    animation="left"
                    className="flex-center"
                  >
                    <MDBModalHeader
                      toggle={() => this.setState({ account: undefined })}
                    >
                      <p className="lead">Login</p>
                    </MDBModalHeader>
                    <MDBModalBody className="text-left">
                      <MDBRow>
                        <MDBCol
                          lg="6"
                          className="background-col-mobile d-sm-none d-block"
                        >
                          <LogoDark className="logo" />
                        </MDBCol>
                        <MDBCol lg="6" className="p-3">
                          <p className="font-weight-bold text-center mt-3 mb-0">
                            Melde dich bei deinem Sparda Profil an.
                          </p>
                          <LoginUser
                            lg={[12, 12]}
                            insuranceAgency={this.state.selectedInsurance}
                            insuranceSlug="household"
                            insuranceDetails={this.state.successDataParameters}
                            paymentInterval={
                              this.state.paymentYearly ? "monthly" : "annual"
                            }
                            price={
                              this.state.paymentYearly
                                ? this.state.selectedInsurance?.price / 12 >=
                                  this.state.selectedInsurance?.month_min_price
                                  ? this.state.selectedInsurance?.price / 12
                                  : this.state.selectedInsurance
                                      ?.month_min_price
                                : this.state.selectedInsurance?.price
                            }
                            fluid
                            btnCenter
                          />
                          <hr />
                          <div className="text-center my-3">
                            <p>oder registriere dich</p>
                            <MDBBtn
                              color="grey"
                              onClick={() => this.setState({ account: 0 })}
                            >
                              Konto anlegen
                            </MDBBtn>
                          </div>
                        </MDBCol>
                        <MDBCol
                          lg="6"
                          className="background-col d-sm-block d-none"
                        >
                          <Logo className="logo" />
                        </MDBCol>
                      </MDBRow>
                    </MDBModalBody>
                  </MDBModal>
                )}
                <SingleChoiceCardModule
                  items={[
                    {
                      value: 0,
                      label: "Noch nicht",
                      icon: NoAccount,
                      help: "In weniger als zwei Minuten zum Kundenkonto.",
                    },
                    {
                      value: 1,
                      label: "Ja, anmelden",
                      icon: HasAccount,
                      help: "Dann klicke hier um dich anzumelden.",
                    },
                  ]}
                  head="Fortfahren"
                  sub="Hast Du bereits ein Kundenkonto bei uns?"
                  name="account"
                  key="account"
                  value={this.state.account}
                  setValue={this.authAction}
                  instant
                  disabled={this.state.account !== undefined}
                />
              </div>
            ) : (
              <>
                {this.state.selectedMemberInsurance ? (
                  <>
                    {true ? (
                      <div style={styleFadeInUp}>
                        <p className="lead font-weight-bold mb-2">
                          Zusammenfassung
                        </p>
                        <MDBRow className="d-flex justify-content-center">
                          <MDBCol lg="6">
                            <p className="text-muted">
                              Unsere MitarbeiterInnen erstellen Dir ein
                              individuelles Angebot basierend auf deiner
                              Auswahl. Dies kann einige Werktage in Anspruch
                              nehmen. Du kannst den aktuellen Status deiner
                              Anfrage in Deinem Dashboard verfolgen.
                            </p>
                            <p className="blue-text font-weight-bold">
                              Wir benachrichtigen Dich, sobald das Angebot zur
                              Einsicht bereit steht.
                            </p>
                          </MDBCol>
                        </MDBRow>
                        {this.state.loading ? (
                          <MDBRow className="flex-center">
                            <MDBCol lg="6">
                              <MDBProgress material preloader />
                            </MDBCol>
                          </MDBRow>
                        ) : (
                          <>
                            <div>
                              <MDBBtn color="blue" onClick={this.submitForm}>
                                Versicherung anfragen
                              </MDBBtn>
                            </div>
                            <div>
                              <MDBBtn
                                color="white"
                                onClick={() =>
                                  this.setState({
                                    selectedMemberInsurance: undefined,
                                    selectedOptions: undefined,
                                  })
                                }
                              >
                                Zurück
                              </MDBBtn>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div style={styleFadeInUp}>
                        <p className="lead font-weight-bold mb-2">
                          Zusatzangebote
                        </p>
                        <MDBRow className="d-flex justify-content-center">
                          <MDBCol lg="6">
                            <p className="text-muted">
                              Beispieltext Lorem Ipsum Dolor Sit Amet.
                            </p>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="d-flex justify-content-center">
                          <MDBCol lg="8" className="mb-3">
                            <MDBListGroup className="optionalParts">
                              <MDBListGroupItem className="mb-4 d-flex align-items-stretch justify-content-between selected-insurance">
                                <div className="text-left p-3">
                                  <p className="font-weight-bold mb-1">
                                    Ausgewählte Versicherung
                                  </p>
                                  <p className="text-muted">
                                    {this.state.selectedMemberInsurance.name}
                                  </p>
                                </div>
                                <div className="price d-flex align-items-center">
                                  <div>
                                    <p className="lead font-weight-bold mb-0">
                                      EUR 420,69
                                    </p>
                                    <p className="mb-0">
                                      {!this.state.paymentYearly
                                        ? "pro Jahr"
                                        : "pro Monat"}
                                    </p>
                                  </div>
                                </div>
                              </MDBListGroupItem>
                              {OPTIONAL_INSURANCE.map((item) => (
                                <MDBListGroupItem
                                  className="d-flex align-items-stretch justify-content-between"
                                  key={item.name}
                                >
                                  <div className="optional-info text-left">
                                    <p className="font-weight-bold mb-0">
                                      {item.label}
                                    </p>
                                    <p className="text-muted small mb-0">
                                      {item.info}
                                    </p>
                                  </div>
                                  <div className="switch-container">
                                    <MDBSwitch
                                      checked={this.state[item.name]}
                                      getValue={(val) =>
                                        this.setState({ [item.name]: val })
                                      }
                                      labelLeft=""
                                      labelRight=""
                                    />
                                  </div>
                                </MDBListGroupItem>
                              ))}
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="6">
                            <MDBBtn
                              color="white"
                              onClick={() =>
                                this.setState({
                                  selectedMemberInsurance: undefined,
                                })
                              }
                            >
                              Zurück
                            </MDBBtn>
                          </MDBCol>
                          <MDBCol md="6">
                            <MDBBtn
                              color="blue"
                              onClick={() =>
                                this.setState({ selectedOptions: true })
                              }
                            >
                              Weiter
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    {this.state.loading ? (
                      <MDBSpinner />
                    ) : (
                      <div style={styleFadeInUp}>
                        <h3 className="mb-3">
                          Wir haben die besten zwei Angebote für Dich
                          heausgesucht!
                        </h3>
                        <p className="text-muted">
                          Diese Prämien sind unverbindliche Preisempfehlungen.
                          Du erhältst den Echt-Preis nach Bearbeitung deiner
                          unverbindlichen Anfrage.
                        </p>
                        <MDBRow className="d-flex justify-content-center success">
                          {this.state.successData.map((insurance, i) => {
                            const price =
                              insurance.price < insurance.year_min_price
                                ? insurance.year_min_price
                                : insurance.price;

                            return (
                              <MDBCol lg="5" key={"insurance-" + i}>
                                <MDBCard className="h-100">
                                  <MDBCardBody>
                                    <div>
                                      {insurance.id ===
                                        "household_vav_exklusiv" && (
                                        <img
                                          src="https://www.ivva.at/wp-content/uploads/2011/02/logo-vav-small-300x149.jpg"
                                          alt="Agency Logo 1"
                                        />
                                      )}
                                      {insurance.id ===
                                        "household_wien_extra" && (
                                        <img
                                          src="https://www.wienerstaedtische.at/fileadmin/user_upload/Bilderpool/Unternehmen/Presse/Mediathek/Logo/Logo_Wiener_Staedtische_4c.jpg"
                                          alt="Agency Logo 2"
                                        />
                                      )}
                                      <p className="lead">{insurance.name}</p>
                                    </div>
                                    <div className="results">
                                      <div>
                                        <p className="lead font-weight-bold mb-0">
                                          {Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "EUR",
                                          }).format(insurance.vs)}
                                        </p>
                                        <p className="text-muted">
                                          Versicherungssumme
                                        </p>
                                        <MDBSwitch
                                          checked={this.state.paymentYearly}
                                          onChange={() =>
                                            this.setState({
                                              paymentYearly:
                                                !this.state.paymentYearly,
                                            })
                                          }
                                          labelLeft="Jährlich"
                                          labelRight="Monatlich"
                                        />
                                        <div style={stylePopIn}>
                                          {!this.state.paymentYearly ? (
                                            <div>
                                              <p className="lead blue-text font-weight-bold mb-0">
                                                {Intl.NumberFormat("de-DE", {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }).format(price)}
                                              </p>
                                              <p className="text-muted mb-0">
                                                Jahresprämie
                                              </p>
                                            </div>
                                          ) : (
                                            <div>
                                              <p className="lead blue-text font-weight-bold mb-0">
                                                {Intl.NumberFormat("de-DE", {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }).format(
                                                  price / 12 >=
                                                    insurance.month_min_price
                                                    ? price / 12
                                                    : insurance.month_min_price
                                                )}
                                              </p>
                                              <p className="text-muted mb-0">
                                                Monatsprämie
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                        <p className="text-muted small">
                                          (Unverbindliche Preisempfehlung)
                                        </p>
                                        <MDBBtn
                                          color="blue"
                                          onClick={() =>
                                            this.selectInsurance(insurance)
                                          }
                                        >
                                          Auswählen
                                        </MDBBtn>
                                      </div>
                                    </div>
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            );
                          })}
                        </MDBRow>
                        <div className="w-100 mb-4">
                          <div className="splitter mt-3 mb-2">
                            <span className="or">
                              <span className="or-text">oder</span>
                            </span>
                          </div>
                        </div>
                        <MDBBtn
                          color="blue"
                          size="md"
                          onClick={() =>
                            this.setState({
                              currentDepth: this.state.currentDepth - 1,
                              successData: null,
                            })
                          }
                        >
                          <MDBIcon icon="angle-left" /> Schritt Zurück
                        </MDBBtn>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            <div className="mb-3">
              <MDBProgress value={progress} />
              {this.state.currentDepth !== 0 && (
                <div className="my-3">
                  <p
                    className="mb-0 text-muted clickable d-inline-block"
                    onClick={() => this.goBack()}
                  >
                    <MDBIcon icon="angle-left" /> Zurück
                  </p>
                </div>
              )}
            </div>
            <div className="questionnaire">
              {this.state.successData === false &&
                this.state.currentDepth === 15 && (
                  <MDBAlert color="info" className="text-center">
                    <p className="lead">
                      Die Berechnung ist leider fehlgeschlagen.
                    </p>
                    <p>
                      Dein Zwischenstand wurde gespeichert, bitte versuche es
                      später erneut.
                    </p>
                    <MDBBtn color="blue" onClick={() => this.goBack()}>
                      Zurück
                    </MDBBtn>
                  </MDBAlert>
                )}
              {(() => {
                switch (this.state.currentDepth) {
                  case 0:
                    return (
                      <SingleChoiceCardModule
                        items={[
                          {
                            value: 0,
                            label: "Nicht ständig bewohnt",
                            help: "< 9 Monate im Jahr",
                            icon: Empty,
                          },
                          {
                            value: 1,
                            label: "Ständig bewohnt",
                            help: "> 9 Monate im Jahr",
                            icon: Active,
                          },
                        ]}
                        head="Bewohnt"
                        sub="Ist die Wohnung in einem ständig bewohnten Gebäude?"
                        help={{
                          title: "Wie ist hier die Regelung?",
                          content:
                            "Im Gebäude muss mindestens eine Person 9 Monate im Jahr anwesend sein.",
                        }}
                        name="staendigbewohnt"
                        key="staendigbewohnt"
                        value={this.state.staendigbewohnt}
                        setValue={this.setValue}
                      />
                    );
                  case 1:
                    return (
                      <SingleChoiceCardModule
                        items={[
                          {
                            value: 1,
                            label: "In Wohngemeinschaft",
                            help: "In der Wohnung lebt eine Wohngemeinschaft.",
                            icon: Family,
                          },
                          {
                            value: 2,
                            label: "Mit Partner oder Familie",
                            help: "In der Wohnung lebe ich mit Familie oder meinem Partner.",
                            icon: FamilyTime,
                          },
                          {
                            value: 0,
                            label: "Single-/Einpersonenhaushalt",
                            help: "Nur ich lebe in der Wohnung.",
                            icon: Single,
                          },
                        ]}
                        head="Wohnform"
                        sub="Wer lebt in der Wohnung?"
                        name="wohntyp"
                        key="wohntyp"
                        value={this.state.wohntyp}
                        setValue={this.setValue}
                      />
                    );
                  case 2:
                    return (
                      <NumberModule
                        min={1}
                        max={100000}
                        head="Wohnungsgröße"
                        sub="Wie groß ist die zu versichernde Wohnung in m²?"
                        help={{
                          title: "Wo finde ich das?",
                          content:
                            "Wohnnutzfläche laut Mietvertrag. Inkl. Loggia, exkl. Balkon.",
                        }}
                        name="groesse"
                        key="groesse"
                        hint="z.B. 55"
                        value={this.state.groesse}
                        setValue={this.setValue}
                      />
                    );
                  case 3:
                    return (
                      <SingleChoiceCardModule
                        items={[
                          {
                            value: 1,
                            label: "5 Jahre Laufzeit",
                            help: "Auf 5 Jahre begrenzt.",
                          },
                          {
                            value: 2,
                            label: "3 Jahre Laufzeit",
                            help: "Auf 3 Jahre begrenzt.",
                          },
                          {
                            value: 0,
                            label: "Nein",
                            help: "Eine 10-jährige Laufzeit ist in Ordnung.",
                          },
                        ]}
                        head="Verkürzte Laufzeit"
                        help={{
                          title: "Was bedeutet die verkürzte Laufzeit?",
                          content:
                            "Hierbei handelt es sich um die Mindestlaufzeit bis eine unbegründete Kündigung möglich ist. Bei besonderen Ereignissen (z.B. Umzug) ist auch ein früherer Vertragsausstieg möglich.",
                        }}
                        sub="Ist eine verkürzte, 5- oder 3-jährige Laufzeit gewünscht?"
                        name="shortterm"
                        key="shortterm"
                        value={this.state.shortterm}
                        setValue={this.setValue}
                      />
                    );
                  case 4:
                    return (
                      <SingleChoiceCardModule
                        items={[
                          {
                            value: 3,
                            label: "EUR 400 - 500",
                            help: "Ja, ein Selbstbehalt von 400 bis 500 Euro ist gewünscht.",
                          },
                          {
                            value: 2,
                            label: "EUR 200 - 300",
                            help: "Ja, ein Selbstbehalt von 200 bis 300 Euro ist gewünscht.",
                          },
                          {
                            value: 1,
                            label: "EUR 100 - 150",
                            help: "Ja, ein Selbstbehalt von 100 bis 150 Euro ist gewünscht.",
                          },
                          {
                            value: 0,
                            label: "Kein Selbstbehalt",
                            help: "Es ist kein Selbstbehalt gewünscht.",
                          },
                        ]}
                        lg={3}
                        head="Selbstbehaltnachlass"
                        sub="Ist bei Schadenseintritt ein Selbstbehalt gewünscht?"
                        name="selbstbehaltsnachlass"
                        key="selbstbehaltsnachlass"
                        value={this.state.selbstbehaltsnachlass}
                        setValue={this.setValue}
                      />
                    );
                  case 5:
                    return (
                      <NumberModule
                        min={18}
                        max={100}
                        head="Alter"
                        sub="Wie alt bist Du?"
                        name="alter"
                        key="alter"
                        value={this.state.alter}
                        setValue={this.setValue}
                      />
                    );
                  case 6:
                    return (
                      <SingleChoiceCardModule
                        items={[
                          {
                            value: "kaernten",
                            label: "Kärnten",
                            icon: Carinthia,
                            isPicture: true,
                          },
                          {
                            value: "wien",
                            label: "Wien",
                            icon: Vienna,
                            isPicture: true,
                          },
                          {
                            value: "niederoesterreich",
                            label: "Niederösterreich",
                            icon: LowerAustria,
                            isPicture: true,
                          },
                          {
                            value: "burgenland",
                            label: "Burgenland",
                            icon: Burgenland,
                            isPicture: true,
                          },
                          {
                            value: "oberoesterreich",
                            label: "Oberösterreich",
                            icon: UpperAustria,
                            isPicture: true,
                          },
                          {
                            value: "salzburg",
                            label: "Salzburg",
                            icon: Salzburg,
                            isPicture: true,
                          },
                          {
                            value: "steiermark",
                            label: "Steiermark",
                            icon: Styria,
                            isPicture: true,
                          },
                          {
                            value: "tirol",
                            label: "Tirol",
                            icon: Tirol,
                            isPicture: true,
                          },
                          {
                            value: "vorarlberg",
                            label: "Vorarlberg",
                            icon: Vorarlberg,
                            isPicture: true,
                          },
                        ]}
                        head="Bundesland"
                        className="states"
                        sub="In welchem Bundesland befindet sich die Wohnung?"
                        name="regionalrabatt"
                        lg={4}
                        key="regionalrabatt"
                        value={this.state.regionalrabatt}
                        setValue={this.setValue}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </>
        )}
        {this.state.currentDepth < 7 && (
          <div className="text-center mt-5">
            <MDBBtn
              color="grey"
              size="md"
              onClick={() => {
                const script = document.createElement("script");

                script.src = process.env.REACT_APP_TIDIO_URL;
                script.async = true;

                document.body.appendChild(script);

                // Open chat if ready
                if (window.tidioChatApi) {
                  window.tidioChatApi.on("ready", () =>
                    window.tidioChatApi.open()
                  );
                } else {
                  document.addEventListener("tidioChat-ready", () =>
                    window.tidioChatApi.open()
                  );
                }
              }}
            >
              <MDBIcon icon="question-circle" />
              Ich benötige Hilfe beim ausfüllen
            </MDBBtn>
          </div>
        )}
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
  data: state.auth.data,
});

const mapDispatchToProps = {
  calculateParameters,
  sendQuestionnaire,
  getUserData,
};
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceQuestionnaire);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
