//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// Signature
import SignatureCanvas from "react-signature-canvas";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBBtn, MDBIcon, MDBInput } from "mdbreact";
//#endregion

//#region > Components
class QRModal extends React.Component {
  state = { signature: null };

  // Receive image and trim it to the edges of the line
  getSignature = () => {
    const signatureFile = this.sigCanvas
      .getTrimmedCanvas()
      .toDataURL("image/png");

    this.props.getFile(signatureFile);
  };

  // Pass signature image to parent component
  passSignature = (signatureFile) => {
    this.props.getFile(signatureFile);
  };

  render() {
    return (
      <div className="signature-pad-container">
        {this.props.title && (
          <p className="mb-1 font-weight-bold text-dark">{this.props.title}</p>
        )}
        <SignatureCanvas
          penColor="black"
          ref={(ref) => {
            this.sigCanvas = ref;
          }}
        />
        <MDBBtn
          color="grey"
          onClick={() => this.sigCanvas.clear()}
          className="mx-0 mt-0"
        >
          Unterschrift zurücksetzen
        </MDBBtn>
        <MDBInput
          label={
            <span className="small">
              Ich bestätige hiermit meine Unterschrift.
            </span>
          }
          filled
          checked={this.state.agreement}
          onClick={(e) => this.setState({ agreement: e.target.checked })}
          type="checkbox"
          id="checkbox1"
          containerClass="my-4 pl-1 text-dark"
        />
        <div className="d-none d-sm-flex justify-content-center">
          <MDBBtn
            color="green"
            onClick={this.getSignature}
            className="m-1"
            disabled={!this.state.agreement}
          >
            <MDBIcon icon="check-circle" />
            Unterzeichnen
          </MDBBtn>
        </div>
        <div className="d-sm-none d-block">
          <MDBBtn
            color="green"
            className="my-4"
            size="lg"
            onClick={this.getSignature}
            disabled={!this.state.agreement}
          >
            <MDBIcon icon="check-circle" />
            Unterzeichnen
          </MDBBtn>
        </div>
      </div>
    );
  }
}
//#endregion

//#region > Exports
export default QRModal;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
