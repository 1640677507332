//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { withRouter, Link } from "react-router-dom";
//> Redux
// Connect
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBContainer,
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBEdgeHeader,
  MDBFreeBird,
} from "mdbreact";

//> Components
import { SignaturePad } from "../../atoms";
// Actions
import {
  signDocument,
  callToken,
} from "../../../store/actions/signatureActions";
//> CSS
import "./index.scss";

//> Images
// React Logo
import { ReactComponent as Logo } from "../../../assets/logo_white.svg";
//#endregion

//#region > Components
class VerifyMail extends React.Component {
  state = {};

  componentDidMount = () => {
    // Send POST request to server to let the user know he can now proceed mobile
    const { token } = this.props.match.params;

    this.setState(
      {
        loading: true,
      },
      () => this.callToken(token)
    );
  };

  // Retrieve signature file
  getFile = async (signature) => {
    const { token } = this.props.match.params;

    console.log("signature", signature);

    if (signature) {
      const res = await this.props.signDocument(signature, token);

      if (res.status) {
        this.setState({
          signatureImage: signature,
          isFinished: res,
        });
      } else {
        this.setState({
          signatureImage: signature,
          isFinished: res,
        });
      }
    }
  };

  // Activate progress for desktop use
  callToken = async (signToken) => {
    if (signToken) {
      const res = await this.props.callToken(signToken);

      this.setState({
        loading: false,
        isFinished: res.status === false ? res : undefined,
      });

      if (!res.status) {
        this.setState({
          isFinished: { status: false },
        });
      }
    }
  };

  render() {
    const { isFinished } = this.state;

    console.log("finished", isFinished);

    return (
      <div id="signaturePage">
        <MDBEdgeHeader color="blue" className="sectionPage">
          <Logo className="img-fluid logo mx-auto d-block" />
        </MDBEdgeHeader>
        <MDBContainer className="text-center py-5">
          {this.state.loading ? (
            <MDBSpinner />
          ) : (
            <>
              {isFinished ? (
                <MDBFreeBird className="white py-3">
                  <>
                    {isFinished.status ? (
                      <>
                        <h3 className="font-weight-bold">Dokument signiert</h3>
                        <p className="text-muted">
                          Das Dokument wurde erfolgreich signiert.
                        </p>
                        <MDBAlert color="info">
                          <p className="lead font-weight-bold mb-0">
                            Fahre nun auf der Website fort.
                          </p>
                        </MDBAlert>
                        <Link to="/qr">
                          <MDBBtn color="blue" size="lg" className="mt-5 mb-2">
                            <MDBIcon icon="qrcode" /> Nächsten Code scannen
                          </MDBBtn>
                        </Link>
                        <MDBBtn
                          color="grey"
                          size="lg"
                          onClick={() => window.close()}
                        >
                          Schließen
                        </MDBBtn>
                      </>
                    ) : (
                      <>
                        <h3 className="font-weight-bold">Zeit abgelaufen</h3>
                        <p className="text-muted">
                          Das Zeitfenster, in dem du das Dokument unterzeichnen
                          kannst ist leider abgelaufen.
                        </p>
                        <MDBAlert color="danger">
                          <p className="lead font-weight-bold mb-0">
                            Bitte starte den Prozess erneut.
                          </p>
                        </MDBAlert>
                        <Link to="/qr">
                          <MDBBtn color="blue" size="lg" className="mt-5 mb-2">
                            <MDBIcon icon="qrcode" /> Neuen Code scannen
                          </MDBBtn>
                        </Link>
                        <MDBBtn
                          color="grey"
                          size="lg"
                          onClick={() => window.close()}
                        >
                          Schließen
                        </MDBBtn>
                      </>
                    )}
                  </>
                </MDBFreeBird>
              ) : (
                <MDBFreeBird className="white py-3">
                  <h3 className="font-weight-bold">Unterschriftfeld</h3>
                  <p className="text-muted">
                    Bitte signiere das Dokument mit deinem Finger.
                  </p>
                  <SignaturePad getFile={this.getFile} />
                </MDBFreeBird>
              )}
            </>
          )}
          <p className="small text-muted">
            Sparda Plus ID is a service provided by Sparda Plus
            Versicherungsservice to enable its customers to sign documents
            online.
          </p>
        </MDBContainer>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
});

const mapDispatchToProps = {
  signDocument,
  callToken,
};
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyMail));
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
