//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBIcon } from "mdbreact";

//> Components
import { Household } from "../insurances";
//> Helpers
import { writeToLS } from "../../../helpers/localStorage.js";
//> Style
import "./index.scss";
//#endregion

//#region > Components
/**
 * @class Questionnaire Component
 * @description Questionnaire wrapper for any insurance
 */
class Navbar extends React.Component {
  state = {
    collapseID: "",
  };

  componentDidMount = () => {
    const { name, insurance } = this.props;

    writeToLS("name", name);
    writeToLS("insurance", insurance);
  };

  render() {
    const { insurance } = this.props;

    switch (insurance) {
      case "household":
        return <Household />;
      default:
        return (
          <div className="d-flex flex-center text-center">
            <div>
              <p className="lead text-muted mb-2 font-weight-bold">
                Option in Demodaten nicht verfügbar.
              </p>
              <p
                className="blue-text clickable"
                onClick={this.props.exitInsurance}
              >
                <MDBIcon icon="angle-left" className="mr-1" />
                Zurück
              </p>
            </div>
          </div>
        );
    }
  }
}
//#endregion

//#region > Exports
export default Navbar;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
