//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBCardTitle,
  MDBSelect,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBModalHeader,
  MDBSpinner,
} from "mdbreact";
//> Time
import moment from "moment";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import {
  getUserIdentification,
  getUser,
  verifyId,
} from "../../../../store/actions/userActions";
//> CSS
import "../index.scss";
//#endregion

//#region > Components
class IdCardCheck extends React.Component {
  state = {
    msg: "",
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "m",
        text: "Männlich",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "w",
        text: "Weiblich",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "o",
        text: "Divers",
      },
    ],
  };

  componentDidMount = async () => {
    const { user } = this.props;
    const id = user.id;

    const idDetails = await this.props.getUserIdentification(id);
    const userDetails = await this.props.getUser(id);

    if (idDetails && userDetails) {
      const userData = userDetails.data;

      const data = {
        first_name: userData.first_name ? userData.first_name : "",
        last_name: userData.last_name ? userData.last_name : "",
        zipcode: userData.zipcode ? userData.zipcode : "",
        address1: userData.address1 ? userData.address1 : "",
        email: userData.email ? userData.email : "",
        phone: userData.phone ? userData.phone : "",
        birthdate: userData.birthdate
          ? moment(userData.birthdate, "YYYY-MM-DD").format("DD.MM.YYYY")
          : "",
        sex: userData.sex ? userData.sex : "",
      };

      // Pre set sex
      if (userData.sex) {
        const currentOptions = this.state.options;

        const otherOptions = currentOptions.filter(
          (opt) => opt.value !== userData.sex
        );

        let selectedOption = currentOptions.filter(
          (opt) => opt.value === userData.sex
        )[0];

        selectedOption.checked = true;

        const newOptions = [...otherOptions, selectedOption];

        this.setState({
          options: newOptions,
        });
      }

      this.setState({
        idDetails: idDetails.data[0],
        userDetails: userDetails.data,
        ...data,
      });
    } else {
      this.setState({
        idDetails: false,
        userDetails: false,
      });
    }
  };

  verifyDocument = async (valid) => {
    this.setState({
      loading: true,
    });

    const res = await this.props.verifyId(
      this.state.idDetails.id,
      valid,
      this.state
    );

    this.setState(
      {
        success: res.status,
        loading: false,
      },
      () => res.status && this.props.close()
    );
  };

  render() {
    const { idDetails } = this.state;

    return (
      <div id="idcardcheck">
        <MDBModal
          isOpen={true}
          toggle={this.props.close}
          size="lg"
          className="d-flex flex-center"
          animation="left"
        >
          <MDBModalHeader toggle={this.props.close}>
            <p className="lead font-weight-bold mb-0">Ausweis überprüfen</p>
          </MDBModalHeader>
          <MDBModalBody className="text-left">
            {idDetails === undefined || this.state.loading ? (
              <div className="text-center py-5">
                <MDBSpinner />
              </div>
            ) : (
              <div>
                <MDBRow>
                  <MDBCol lg="6">
                    <img
                      src={
                        process.env.REACT_APP_MEDIAURL +
                        idDetails?.document +
                        "?token=" +
                        localStorage.getItem("token")
                      }
                      className="img-fluid"
                    />
                    <p className="my-2 text-muted">
                      <a
                        className="text-muted"
                        href={
                          process.env.REACT_APP_MEDIAURL +
                          idDetails?.document +
                          "?token=" +
                          localStorage.getItem("token")
                        }
                        target="_blank"
                      >
                        Herunterladen
                      </a>
                    </p>
                  </MDBCol>
                  <MDBCol lg="6">
                    <img
                      src={
                        process.env.REACT_APP_MEDIAURL +
                        idDetails?.document_back +
                        "?token=" +
                        localStorage.getItem("token")
                      }
                      className="img-fluid"
                    />{" "}
                    <p className="my-2 text-muted">
                      <a
                        className="text-muted"
                        href={
                          process.env.REACT_APP_MEDIAURL +
                          idDetails?.document_back +
                          "?token=" +
                          localStorage.getItem("token")
                        }
                        target="_blank"
                      >
                        Herunterladen
                      </a>
                    </p>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBCardTitle>Daten überprüfen</MDBCardTitle>
                <div className="text-center">
                  <p className="mb-1">
                    <strong>Kontaktdaten</strong>
                    <MDBRow className="mt-2">
                      <MDBCol lg="6">
                        <MDBInput
                          type="text"
                          value={this.state.first_name}
                          getValue={(val) => this.setState({ first_name: val })}
                          outline
                          name="firstname"
                          label="Vorname"
                          containerClass="my-1"
                        />
                      </MDBCol>
                      <MDBCol lg="6">
                        <MDBInput
                          type="text"
                          value={this.state.last_name}
                          getValue={(val) => this.setState({ last_name: val })}
                          outline
                          name="surname"
                          label="Nachname"
                          containerClass="my-1"
                        />
                      </MDBCol>
                      <MDBCol lg="6">
                        <MDBInput
                          type="email"
                          value={this.state.email}
                          getValue={(val) => this.setState({ email: val })}
                          outline
                          name="email"
                          label="E-Mail"
                          containerClass="my-1"
                        />
                      </MDBCol>
                      <MDBCol lg="6">
                        <MDBInput
                          type="text"
                          value={this.state.birthdate}
                          getValue={(val) => this.setState({ birthdate: val })}
                          outline
                          name="birthdate"
                          label="Birthdate (dd.mm.yyyy)"
                          containerClass="my-1"
                        />
                      </MDBCol>
                      <MDBCol lg="6">
                        <MDBSelect
                          outline
                          className="my-1"
                          options={this.state.options}
                          getValue={(val) => this.setState({ sex: val })}
                          selected="Geschlecht auswählen"
                        />
                      </MDBCol>
                    </MDBRow>
                  </p>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <MDBBtn
                    color="danger"
                    size="md"
                    onClick={() => this.verifyDocument(false)}
                  >
                    <MDBIcon far icon="times-circle" />
                    Ablehnen
                  </MDBBtn>
                  <div>
                    <MDBBtn color="blue" size="md" onClick={this.props.close}>
                      <MDBIcon far icon="clock" />
                      Später
                    </MDBBtn>
                    <MDBBtn
                      color="success"
                      size="md"
                      onClick={() => this.verifyDocument(true)}
                      disabled={
                        !this.state.sex ||
                        !this.state.first_name ||
                        !this.state.last_name ||
                        !this.state.birthdate
                      }
                    >
                      <MDBIcon far icon="check-circle" />
                      Akzeptieren
                    </MDBBtn>
                  </div>
                </div>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  getUserIdentification,
  getUser,
  verifyId,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(IdCardCheck);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
