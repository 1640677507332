//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> Animations
import { animations } from "react-animation";
//> Redux
// Basic Redux provider
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSwitch,
  MDBProgress,
  MDBIcon,
  MDBAlert,
} from "mdbreact";

//> Redux Actions
import { sendQuestionnaire } from "../../../store/actions/questionnaireActions";

//> Images
// React Logo
import { ReactComponent as Logo } from "../../../assets/logo_white.svg";
import { ReactComponent as LogoDark } from "../../../assets/logo.svg";

//> Style
import "./index.scss";
//#endregion

//#region > Config
const stylePopIn1 = {
  animation: `${animations.popIn} 200ms`,
};
const stylePopIn2 = {
  animation: `${animations.popIn} 600ms`,
};
const stylePopIn3 = {
  animation: `${animations.popIn} 1400ms`,
};
//#endregion

//#region > Components
class RegisterUser extends React.Component {
  state = {};

  submitForm = async () => {
    const { first_name, last_name, email, password } = this.state;
    const { insuranceSlug, insuranceDetails, insuranceAgency } = this.props;

    this.setState({
      loading: true,
    });

    const res = await this.props.sendQuestionnaire(
      insuranceSlug,
      insuranceDetails,
      insuranceAgency?.id,
      {
        first_name,
        last_name,
        email,
        password,
      },
      { payment_interval: this.props.paymentInterval, price: this.props.price }
    );

    if (res.status) {
      // Success
      window.location.replace("/me");
    } else {
      this.setState({
        loading: false,
        password: "",
        error: res.data.password
          ? "password"
          : res.data.LoginDenied
          ? "email"
          : false,
      });
    }
  };

  render() {
    return (
      <MDBModal
        isOpen={true}
        toggle={this.props.cancelRegister}
        size="lg"
        id="registerModal"
        animation="left"
        className="flex-center"
      >
        {this.state.loading ? (
          <div className="text-center py-5 px-3">
            <MDBRow className="flex-center mt-4">
              <MDBCol lg="6">
                <MDBProgress material preloader />
              </MDBCol>
            </MDBRow>
            <p className="blue-text mb-2 lead">
              Wir erstellen dein individuelles Profil.
            </p>
            <p className="text-muted small">
              Bitte schließe diese Seite nicht.
            </p>
            <MDBRow className="flex-center mt-4">
              <MDBCol lg="6">
                <div className="mb-0 blue-text d-flex justify-content-between">
                  <div
                    className="animated d-sm-block d-none mx-1"
                    style={stylePopIn1}
                  >
                    <MDBIcon icon="database" />
                    <span className="small d-block text-muted">
                      Database connection
                    </span>
                  </div>
                  <div
                    className="animated d-sm-block d-none mx-1"
                    style={stylePopIn2}
                  >
                    <MDBIcon far icon="address-card" />
                    <span className="small d-block text-muted">
                      User selections
                    </span>
                  </div>
                  <div
                    className="animated d-sm-block d-none mx-1"
                    style={stylePopIn3}
                  >
                    <MDBIcon far icon="user-circle" />
                    <span className="small d-block text-muted">
                      Creating user
                    </span>
                  </div>
                </div>
                <div
                  className="animated d-block d-sm-none blue-text"
                  style={stylePopIn1}
                >
                  <MDBIcon far icon="user-circle" />
                  <span className="small d-block text-muted">
                    Creating user
                  </span>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        ) : (
          <>
            <MDBModalBody className="text-left">
              <MDBRow>
                <MDBCol
                  lg="6"
                  className="background-col-mobile d-sm-none d-block"
                >
                  <LogoDark className="logo" />
                </MDBCol>
                <MDBCol lg="6" className="p-3">
                  <form>
                    <p className="lead mb-4 text-center">
                      Nun brauchen wir nur noch deine Kontaktdaten
                    </p>
                    {this.state.error === "email" && (
                      <MDBAlert color="danger">
                        Ein Nutzer mit dieser E-Mail Adresse existiert bereits.
                      </MDBAlert>
                    )}
                    {this.state.error === "password" && (
                      <MDBAlert color="danger">
                        Das angegebene Passwort entspricht nicht den
                        Anforderungen. (Mindestens 8 Zeichen, Alphanumerisch)
                      </MDBAlert>
                    )}
                    <MDBRow className="flex-center">
                      <MDBCol lg="12">
                        <MDBInput
                          type="text"
                          label="Vorname"
                          name="firstname"
                          outline
                          value={this.state.first_name}
                          getValue={(val) => this.setState({ first_name: val })}
                          containerClass="mt-2 mb-1"
                          className="mb-0"
                        />
                      </MDBCol>
                      <MDBCol lg="12">
                        <MDBInput
                          type="text"
                          label="Nachname"
                          name="lastname"
                          outline
                          value={this.state.last_name}
                          getValue={(val) => this.setState({ last_name: val })}
                          containerClass="mt-2 mb-3"
                          className="mb-0"
                        />
                      </MDBCol>
                      <MDBCol lg="12">
                        <MDBInput
                          type="email"
                          label="E-Mail"
                          name="email"
                          outline
                          value={this.state.email}
                          getValue={(val) => this.setState({ email: val })}
                          containerClass="mt-2 mb-0"
                          className="mb-0"
                        />
                        <small className="text-muted d-block mb-2">
                          Wie können wir Dich erreichen?
                        </small>
                      </MDBCol>
                      <MDBCol lg="12">
                        <MDBInput
                          type="password"
                          label="Passwort"
                          name="password"
                          outline
                          value={this.state.password}
                          getValue={(val) => this.setState({ password: val })}
                          containerClass="mt-2 mb-0"
                          className="mb-0"
                        />
                        <small className="text-muted d-block mb-3">
                          Wähle ein Passwort (Min 8 Zeichen, Alphanumerisch)
                        </small>
                      </MDBCol>
                      <MDBCol lg="12">
                        <MDBInput
                          label={
                            <span className="small">
                              Ich habe die{" "}
                              <a
                                href="https://www.spardaplus.at/datenschutz"
                                target="_blank"
                              >
                                Datenschutzvereinbarung
                              </a>{" "}
                              gelesen und akzeptiere diese.
                            </span>
                          }
                          filled
                          checked={this.state.privacy}
                          onClick={(e) =>
                            this.setState({ privacy: e.target.checked })
                          }
                          type="checkbox"
                          id="privacy"
                          containerClass="my-3 pl-1 text-dark"
                        />
                        <MDBInput
                          label={
                            <span className="small">
                              Ich habe die{" "}
                              <a
                                href="https://www.spardaplus.at/agb"
                                target="_blank"
                              >
                                AGB
                              </a>{" "}
                              gelesen und akzeptiere diese.
                            </span>
                          }
                          filled
                          checked={this.state.agb}
                          onClick={(e) =>
                            this.setState({ agb: e.target.checked })
                          }
                          type="checkbox"
                          id="agb"
                          containerClass="my-3 pl-1 text-dark"
                        />
                      </MDBCol>
                    </MDBRow>
                    <div className="text-center">
                      <MDBBtn
                        color="blue"
                        className="m-0 w-100"
                        disabled={
                          !this.state.first_name ||
                          !this.state.last_name ||
                          !this.state.email ||
                          !this.state.password ||
                          !this.state.privacy ||
                          !this.state.agb
                        }
                        onClick={() => this.submitForm()}
                      >
                        Anfrage absenden
                      </MDBBtn>
                      <MDBBtn
                        color="grey"
                        className="m-0 mt-3 d-block w-100"
                        onClick={this.props.cancelRegister}
                      >
                        Zurück
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
                <MDBCol lg="6" className="background-col d-sm-block d-none">
                  <Logo className="logo" />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </>
        )}
      </MDBModal>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
  data: state.auth.data,
});

const mapDispatchToProps = {
  sendQuestionnaire,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);
//#endregion
/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
