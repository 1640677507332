//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// Phone Input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// Verification code
import ReactCodeInput from "react-verification-code-input";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBInput,
  MDBModalHeader,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
} from "mdbreact";
//> Lottie
import Lottie from "lottie-react-web";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import { verifyPhone, sendCode } from "../../../../store/actions/userActions";
//> Lottie
import finishedAnimationFile from "../../../../assets/lottie/finished.json";
//> CSS
import "../index.scss";
//#endregion

//#region > Config
const finishedAnimation = {
  loop: false,
  autoplay: true,
  animationData: finishedAnimationFile,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
//#endregion

//#region > Components
class PhoneVerify extends React.Component {
  state = {
    phone: this.props.phone,
    code: "",
  };

  sendCode = async (code) => {
    const res = await this.props.sendCode(code);

    this.setState({
      codeSuccess: res.status,
    });
  };

  requestCode = () => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        const res = await this.props.verifyPhone(
          this.state.phone,
          this.state.method
        );

        this.setState({
          codeSent: res.status,
          loading: false,
        });
      }
    );
  };

  render() {
    return (
      <MDBModal
        isOpen={true}
        toggle={() => this.props.toggle(this.state.codeSuccess)}
        size="md"
        animation="left"
        className="flex-center"
        id="phoneVerify"
      >
        <MDBModalHeader
          toggle={() => this.props.toggle(this.state.codeSuccess)}
        >
          <p className="mb-0 small">
            {this.props.isChange
              ? "Telefonnummer ändern"
              : "Telefonnummer bestätigen"}
          </p>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
          {!this.state.method ? (
            <MDBRow className="justify-content-center selection-container">
              <MDBCol md="12">
                <div className="text-left">
                  <p className="small text-muted mb-3">
                    Du erhälst entweder eine <strong>automatisierte SMS</strong>{" "}
                    oder einen <strong>automatisierten Anruf</strong> mit dem
                    6-stelligen Code auf die angeführte Nummer.
                  </p>
                </div>
              </MDBCol>
              <MDBCol lg="6">
                <MDBBtn
                  color="grey"
                  onClick={() => this.setState({ method: "sms" })}
                  size="lg"
                >
                  <MDBIcon
                    icon="sms"
                    size="lg"
                    className="d-block d-sm-inline-block mb-2 mb-sm-0 mr-0 mr-sm-1"
                  />
                  SMS erhalten
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="6">
                <MDBBtn
                  color="grey"
                  onClick={() => this.setState({ method: "call" })}
                  size="lg"
                >
                  <MDBIcon
                    icon="phone"
                    size="lg"
                    className="d-block d-sm-inline-block mb-2 mb-sm-0 mr-0 mr-sm-1"
                  />
                  Anruf erhalten
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          ) : (
            <>
              {!this.state.codeSuccess && (
                <div className="text-left">
                  <p className="font-weight-bold">
                    Bitte gib hier deine neue Telefonnummer ein.
                  </p>
                  {this.props.method === "call" ? (
                    <p className="text-muted small mb-3">
                      Du erhälst einen <strong>automatisierten Anruf</strong>{" "}
                      mit dem 6-stelligen Code auf die angeführte Nummer.
                    </p>
                  ) : (
                    <p className="text-muted small mb-3">
                      Du erhälst eine <strong>automatisierte SMS</strong> mit
                      dem 6-stelligen Code auf die angeführte Nummer.
                    </p>
                  )}
                </div>
              )}
              {this.state.loading ? (
                <div className="my-3">
                  <MDBSpinner />
                </div>
              ) : (
                <>
                  {this.state.codeSuccess ? (
                    <div className="blue-bg text-center">
                      <div className="animation-container">
                        <Lottie
                          options={finishedAnimation}
                          speed={1}
                          ariaRole="img"
                        />
                      </div>
                      <p className="h3 font-weight-bold mb-2">
                        Telefonnummer bestätigt
                      </p>
                      <p className="mb-3">
                        Deine Telefonnummer wurde nun verifiziert.
                      </p>
                      <MDBBtn
                        color="grey"
                        onClick={() => this.props.toggle(true)}
                      >
                        Schließen
                      </MDBBtn>
                    </div>
                  ) : (
                    <>
                      {this.state.codeSent ? (
                        <div>
                          <ReactCodeInput
                            autoFocus
                            fields={6}
                            onComplete={(val) => this.sendCode(val)}
                            onChange={() =>
                              this.setState({ codeSuccess: undefined })
                            }
                            className={
                              this.state.codeSuccess === false && "error"
                            }
                          />
                          {this.state.codeSuccess === false && (
                            <MDBAlert color="danger" className="mt-2">
                              <p className="mb-0 small">
                                Der eingegebene Code ist fehlerhaft. Bitte
                                überprüfe deine Eingabe.
                              </p>
                            </MDBAlert>
                          )}
                          <div className="d-flex justify-content-between">
                            <MDBBtn
                              color="grey"
                              onClick={() => this.setState({ codeSent: false })}
                            >
                              <MDBIcon icon="angle-left" />
                              Zurück
                            </MDBBtn>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <MDBRow className="justify-content-center">
                            <MDBCol md="8">
                              <PhoneInput
                                country={"at"}
                                value={this.state.phone}
                                onChange={(phone) => this.setState({ phone })}
                                disableDropdown
                              />
                              {this.state.codeSent === false && (
                                <MDBAlert color="danger" className="mt-2">
                                  <p className="mb-0 small">
                                    Diese Telefonnummer existiert bereits.
                                  </p>
                                </MDBAlert>
                              )}
                            </MDBCol>
                          </MDBRow>
                          <div className="d-flex justify-content-between mt-3">
                            <MDBBtn color="grey" onClick={this.props.toggle}>
                              <MDBIcon icon="times" />
                              Abbrechen
                            </MDBBtn>
                            <MDBBtn
                              color="blue"
                              onClick={this.requestCode}
                              disabled={!this.state.phone}
                            >
                              <MDBIcon icon="angle-right" />
                              Weiter
                            </MDBBtn>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </MDBModalBody>
      </MDBModal>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  verifyPhone,
  sendCode,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerify);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
