//> Reducers
// Authentication
import authReducer from "./authReducer";

//> Redux
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  /* Authentication */
  auth: authReducer,
});

export default rootReducer;

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
