//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBModalHeader,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import { changeUserDataByAdmin } from "../../../../store/actions/userActions";
//> CSS
import "../index.scss";
//#endregion

//#region > Components
class UserData extends React.Component {
  state = {};

  componentDidMount = () => {
    const { user } = this.props;

    this.setState({
      userId: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
    });
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  saveSettings = async () => {
    const { userId, first_name, last_name, email, phone } = this.state;

    const res = await this.props.changeUserDataByAdmin(
      userId,
      first_name,
      last_name,
      email,
      phone
    );

    if (res.status) {
      if (!res.data.current_password) {
        window.location.reload();
      } else {
        this.setState({ error: "password" });
      }
    } else {
      this.setState({ error: "unknown" });
    }
  };

  render() {
    const { user, key } = this.props;

    return (
      <div id="userinfo">
        <MDBBtn size="md" color="blue" outline onClick={this.toggleModal}>
          <MDBIcon icon="info-circle" className="m-0" size="lg" />
        </MDBBtn>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          size="md"
          animation="left"
          className="d-flex flex-center"
        >
          <MDBModalHeader toggle={this.toggleModal}>
            <p className="lead font-weight-bold mb-0">Datenübersicht</p>
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <form>
              <MDBRow className="justify-content-center text-left">
                <MDBCol lg="6">
                  <MDBInput
                    type="text"
                    label="Vorname"
                    name="firstname"
                    value={this.state.first_name}
                    getValue={(val) => this.setState({ first_name: val })}
                    containerClass="my-2"
                    outline
                  />
                </MDBCol>
                <MDBCol lg="6">
                  <MDBInput
                    type="text"
                    label="Nachname"
                    name="surname"
                    value={this.state.last_name}
                    getValue={(val) => this.setState({ last_name: val })}
                    containerClass="my-2"
                    outline
                  />
                </MDBCol>
                {user.id_document && user.id_document.verified ? (
                  <MDBCol lg="12">
                    <MDBAlert color="success">
                      <MDBIcon icon="award" className="mr-2" />
                      Die Identität wurde bestätigt.
                    </MDBAlert>
                  </MDBCol>
                ) : (
                  <>
                    {user.id_document?.denied ? (
                      <MDBCol lg="12">
                        <MDBAlert color="danger">
                          <MDBIcon icon="times-circle" className="mr-2" />
                          Identität wurde nicht bestätigt.
                        </MDBAlert>
                      </MDBCol>
                    ) : (
                      <MDBCol lg="12">
                        <MDBAlert color="warning">
                          <MDBIcon icon="award" className="mr-2" />
                          Bestätigung deiner Identität ausständig.
                        </MDBAlert>
                      </MDBCol>
                    )}
                  </>
                )}
                <MDBCol lg="12">
                  <MDBInput
                    type="email"
                    label="E-Mail"
                    name="email"
                    value={this.state.email}
                    getValue={(val) => this.setState({ email: val })}
                    containerClass="my-2"
                    outline
                  />
                </MDBCol>
                <MDBCol lg="12">
                  <MDBInput
                    type="text"
                    label="Telefon"
                    name="tel"
                    value={this.state.phone}
                    getValue={(val) => this.setState({ phone: val })}
                    containerClass="my-2"
                    outline
                  />
                </MDBCol>
              </MDBRow>
            </form>
            <div className="d-flex justify-content-between">
              <MDBBtn color="danger" onClick={this.toggleModal}>
                <MDBIcon icon="times" />
                Abbrechen
              </MDBBtn>
              <MDBBtn color="blue" onClick={this.saveSettings}>
                <MDBIcon icon="check" />
                Speichern
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  changeUserDataByAdmin,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(UserData);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
