//#region > Imports
import { reportException } from "../../helpers/errorReporting";
//#endregion

//#region > Action types
export const TOKEN_AUTH_S = "AUTH_SUCCESS";
export const TOKEN_AUTH_F = "AUTH_FAIL";
//#endregion

//#region > Creators
export const calculateParameters = (questionnaire, key) => {
  return (dispatch, getState) => {
    const data = new FormData();

    // Add insurance slug
    data.append("key", key);

    //> Insurance data
    // Household
    if (key === "household") {
      data.append("groesse", questionnaire.groesse);
      data.append("staendigbewohnt", questionnaire.staendigbewohnt);
      data.append("shortterm", questionnaire.shortterm);
      data.append("selbstbehaltsnachlass", questionnaire.selbstbehaltsnachlass);
      data.append("alter", questionnaire.alter);
      data.append("regionalrabatt", questionnaire.regionalrabatt);
      data.append("provider_id", questionnaire.provider_id);

      if (questionnaire.vs) {
        data.append("vs", questionnaire.vs);
      }
    }

    return fetch(process.env.REACT_APP_BASEURL + "calculate/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Object.fromEntries(data)),
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // Created
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not calculate parameters.",
              "POST calculate/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not calculate parameters.",
              "POST calculate/"
            );

            return { status: false, data: data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not calculate parameters.",
          "POST calculate/"
        );

        return { status: false, data: error };
      });
  };
};

export const sendQuestionnaire = (
  key,
  questionnaire,
  agency,
  user,
  selections
) => {
  return (dispatch, getState) => {
    const data = new FormData();

    // Add insurance slug
    data.append("key", key);

    //> Insurance data
    // Household
    questionnaire.forEach((element) => {
      data.append(element.field_name, element.field_content);
    });

    // Register or login user
    if (user) {
      user.first_name && data.append("first_name", user.first_name);
      user.last_name && data.append("last_name", user.last_name);
      data.append("email", user.email);
      data.append("password", user.password);
    }

    if (selections) {
      selections.payment_interval &&
        data.append("payment_interval", selections.payment_interval);

      selections.price && data.append("price", selections.price);
    }

    if (agency) {
      data.append("provider_id", agency);
    }

    return fetch(process.env.REACT_APP_BASEURL + "insurance/submit/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: user
          ? undefined
          : `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(Object.fromEntries(data)),
    })
      .then(async (response) => {
        const data = await response.json();

        // Set token if user is not logged in
        (!localStorage.getItem("token") ||
          localStorage.getItem("token") === "undefined") &&
          data.token &&
          localStorage.setItem("token", data.token);

        // Remove saved
        localStorage.removeItem("insuranceBackup");
        localStorage.removeItem("progressBackup");
        localStorage.removeItem("insurance");
        localStorage.removeItem("progress");

        switch (response.status) {
          case 200:
            return { status: true, data: data };
          case 201:
            return { status: true, data: data };
          case 400: // Bad Request
            reportException(
              data,
              "Could not send questionnaire.",
              "POST insurance/submit/"
            );

            return { status: false, data: data };
          default:
            reportException(
              data,
              "Could not send questionnaire.",
              "POST insurance/submit/"
            );

            return { status: false, data: data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not send questionnaire.",
          "POST insurance/submit/"
        );

        return { status: false, data: error };
      });
  };
};
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
