//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// Router
import { Link, Redirect } from "react-router-dom";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBProgress,
  MDBTooltip,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

// Actions
import { logoutUser } from "../../../store/actions/authActions";
//> Images
// React Logo
import { ReactComponent as Logo } from "../../../assets/logo_white.svg";
//> Components
import { DamageReportList } from "../../organisms/modals";
//> Helpers
import { removeLS } from "../../../helpers/localStorage.js";
//> Style
import "./index.scss";
//#endregion

//#region > Components
/**
 * @class Navbar Component
 * @description Global navigation bar
 */
class Navbar extends React.Component {
  state = {
    collapseID: "",
    redirect: false,
    data: null,
    localCache: null,
  };

  // Manage local version of header for improve loading performance
  componentDidMount = () => {
    const { data } = this.props;

    this.setState({
      data,
      localCache: localStorage.getItem("local-cache")
        ? JSON.parse(localStorage.getItem("local-cache"))
        : null,
    });
  };

  // Manage local version of header for improve loading performance
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
        localCache: localStorage.getItem("local-cache")
          ? JSON.parse(localStorage.getItem("local-cache"))
          : null,
      });
    }
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  closeCollapse = (collapseID) => () => {
    window.scrollTo(0, 0);

    this.state.collapseID === collapseID && this.setState({ collapseID: "" });
  };

  logout = () => {
    this.setState(
      {
        redirect: true,
      },
      () => {
        removeLS("verify-reminder");
        // End local session
        this.props.logoutUser();
      }
    );
  };

  render() {
    const { collapseID, redirect, data, localCache } = this.state;

    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.toggleCollapse("mainNavbarCollapse")}
      />
    );

    if (redirect) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        {this.props.loader && (
          <MDBProgress material preloader className="global-preloader" />
        )}
        <MDBNavbar color="white" light expand="md" fixed="top" scrolling>
          <MDBContainer>
            <MDBNavbarBrand
              href="/"
              className="py-0 font-weight-bold"
              onClick={
                this.props.inQuestionnaire
                  ? this.props.exitInsurance
                  : undefined
              }
            >
              <Logo />
            </MDBNavbarBrand>
            <div className="btn-mobile d-sm-none d-block">
              {this.props.inQuestionnaire ? (
                <MDBBtn
                  color="darkblue"
                  size="md"
                  onClick={
                    this.props.inQuestionnaire
                      ? this.props.exitInsurance
                      : undefined
                  }
                >
                  <MDBIcon far icon="times-circle" className="mr-2" />
                  Abbrechen
                </MDBBtn>
              ) : (
                <>
                  {!data ? (
                    <Link
                      exact
                      to="/login"
                      onClick={this.closeCollapse("mainNavbarCollapse")}
                    >
                      <MDBBtn
                        color="darkblue"
                        size="md"
                        className="m-0 font-weight-bold"
                      >
                        Login
                      </MDBBtn>
                    </Link>
                  ) : (
                    <MDBBtn color="darkblue" size="md" onClick={this.logout}>
                      <MDBIcon icon="power-off" className="mr-2" />
                      Ausloggen
                    </MDBBtn>
                  )}
                </>
              )}
            </div>
            <MDBCollapse
              id="mainNavbarCollapse"
              isOpen={this.state.collapseID}
              navbar
            >
              <MDBNavbarNav right className="align-items-center">
                {this.props.inQuestionnaire ? (
                  <>
                    <MDBNavItem className="px-1">
                      <MDBBtn
                        color="darkblue"
                        onClick={
                          this.props.inQuestionnaire
                            ? this.props.exitInsurance
                            : undefined
                        }
                      >
                        <MDBIcon far icon="times-circle" className="mr-2" />
                        Abbrechen
                      </MDBBtn>
                    </MDBNavItem>
                  </>
                ) : (
                  <>
                    {localCache && !data ? (
                      <>
                        <MDBNavItem className="px-1 text-white">
                          <strong className="text-white">
                            Hallo {localCache.first_name}!
                          </strong>
                        </MDBNavItem>
                        <MDBNavItem className="px-1">
                          <Link to="/me">
                            <MDBBtn color="darkblue" className="nav-btn m-0">
                              Übersicht
                            </MDBBtn>
                          </Link>
                        </MDBNavItem>
                        <MDBNavItem className="pl-2">
                          <MDBTooltip placement="bottom" material>
                            <MDBBtn
                              color="darkblue"
                              onClick={this.logout}
                              className="nav-btn"
                            >
                              <MDBIcon icon="power-off" className="m-0" />
                            </MDBBtn>
                            <div>Ausloggen</div>
                          </MDBTooltip>
                        </MDBNavItem>
                      </>
                    ) : (
                      <>
                        {!localCache && !data ? (
                          <>
                            <MDBNavItem>
                              <Link
                                exact
                                to="/login"
                                onClick={this.closeCollapse(
                                  "mainNavbarCollapse"
                                )}
                              >
                                <MDBBtn
                                  color="darkblue"
                                  className="nav-btn m-0 font-weight-bold"
                                >
                                  Kundenportal
                                </MDBBtn>
                              </Link>
                            </MDBNavItem>
                          </>
                        ) : (
                          <>
                            <MDBNavItem className="px-1 text-white">
                              <strong className="text-white">
                                Hallo{" "}
                                {data.first_name
                                  ? data.first_name
                                  : data[0]?.first_name}
                                !
                              </strong>
                            </MDBNavItem>
                            <MDBNavItem className="pl-2 pr-0">
                              <DamageReportList isNav />
                            </MDBNavItem>
                            <MDBNavItem className="pr-1">
                              <Link to="/me">
                                <MDBBtn
                                  color="darkblue"
                                  className="nav-btn m-0"
                                >
                                  Übersicht
                                </MDBBtn>
                              </Link>
                            </MDBNavItem>
                            <MDBNavItem className="pl-2">
                              <MDBTooltip placement="bottom" material>
                                <MDBBtn
                                  color="darkblue"
                                  onClick={this.logout}
                                  className="nav-btn"
                                >
                                  <MDBIcon icon="power-off" className="m-0" />
                                </MDBBtn>
                                <div>Ausloggen</div>
                              </MDBTooltip>
                            </MDBNavItem>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
        {collapseID && overlay}
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  data: state.auth.data,
});

const mapDispatchToProps = {
  logoutUser,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
