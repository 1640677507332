//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBIcon, MDBTooltip } from "mdbreact";
//#endregion

//#region > Components
class Atom extends React.Component {
  render() {
    const { verified, document, inline } = this.props;

    return (
      <div className={inline ? "d-inline-block ml-2" : "flex-center"}>
        <div className="d-flex flex-center">
          {verified && document?.verified ? (
            <MDBTooltip placement="bottom" material domElement>
              <div className="mx-1">
                <MDBIcon far icon="check-circle" className="text-success" />
              </div>
              <div>Verifiziert</div>
            </MDBTooltip>
          ) : (
            <>
              {verified ? (
                <MDBTooltip placement="bottom" material domElement>
                  <div className="mx-1">
                    <MDBIcon far icon="envelope" className="text-success" />
                  </div>
                  <div>E-Mail verifiziert</div>
                </MDBTooltip>
              ) : (
                <MDBTooltip placement="bottom" material domElement>
                  <div className="mx-1">
                    <MDBIcon far icon="envelope" />
                  </div>
                  <div>Verifizierung ausstehend</div>
                </MDBTooltip>
              )}
              {document?.verified ? (
                <MDBTooltip placement="bottom" material domElement>
                  <div className="mx-1">
                    <MDBIcon icon="user" className="text-success" />
                  </div>
                  <div>Identität bestätigt</div>
                </MDBTooltip>
              ) : (
                <>
                  {document?.denied ? (
                    <MDBTooltip placement="bottom" material domElement>
                      <div className="mx-1">
                        <MDBIcon icon="user" className="text-danger" />
                      </div>
                      <div>Abgelehnt</div>
                    </MDBTooltip>
                  ) : (
                    <>
                      {document ? (
                        <MDBTooltip placement="bottom" material domElement>
                          <div className="mx-1">
                            <MDBIcon far icon="user" className="text-warning" />
                          </div>
                          <div>Ausweis überprüfen</div>
                        </MDBTooltip>
                      ) : (
                        <MDBTooltip placement="bottom" material domElement>
                          <div className="mx-1">
                            <MDBIcon icon="user" far />
                          </div>
                          <div>Ausweis ausstehend</div>
                        </MDBTooltip>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
//#endregion

//#region > Exports
// Got access to the history object’s properties and the closest
// <Route>'s match.
export default Atom;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
