//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBIcon,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
} from "mdbreact";
//#endregion

//#region > Config
const RANDOM = Math.random().toString(36).substring(7);
//#endregion

//#region > Components
class NumberModule extends React.Component {
  state = {};

  componentDidMount = () => {
    const { name, value } = this.props;

    if (value) {
      this.setState({
        ["number-" + name]: value,
      });
    }
  };

  // Check if value is number
  setValue = (val, name) => {
    if (this.state.error) {
      this.setState({
        ["number-" + name]: !isNaN(val)
          ? val.trim()
          : this.state["number-" + name]
          ? this.state["number-" + name].trim()
          : "",
        error: false,
      });
    } else {
      this.setState({
        ["number-" + name]: !isNaN(val)
          ? val.trim()
          : this.state["number-" + name]
          ? this.state["number-" + name].trim()
          : "",
      });
    }
  };

  send = (name, value, min, max) => {
    const error = value && (value > max || value < min);

    if (!error) {
      this.props.setValue(name, value);
    } else {
      this.setState({ error });
    }
  };

  render() {
    const {
      head = "Head",
      sub = "Sub",
      name = RANDOM,
      min = 0,
      max = 100,
      lg = 4,
      hint = "",
      help,
      optional = false,
    } = this.props;

    return (
      <div className="module-number text-center">
        <p className="lead font-weight-bold mb-2">
          {head}
          {help && (
            <MDBPopover
              placement="top"
              popover
              clickable
              id="popper1"
              domElement
            >
              <span className="ml-2">
                <MDBIcon
                  far
                  icon="question-circle"
                  className="clickable blue-text"
                />
              </span>
              <div>
                <MDBPopoverHeader>{help.title}</MDBPopoverHeader>
                <MDBPopoverBody>{help.content}</MDBPopoverBody>
              </div>
            </MDBPopover>
          )}
        </p>
        <p className="text-muted">{sub}</p>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol lg={lg}>
            <MDBInput
              type="number"
              outline
              name={name}
              value={this.state["number-" + name]}
              onChange={(e) => this.setValue(e.target.value, name)}
              className={this.state.error ? "invalid" : undefined}
              hint={hint}
              containerClass="m-0"
            />
            {this.state.error && (
              <p className="text-danger small text-left">
                Der eingegebene Wert liegt außerhalb der zugelassenen Werte (
                {min} - {max}).
              </p>
            )}
            <div className="text-center mt-4">
              {optional ? (
                <div className="d-flex justify-content-between">
                  <MDBBtn
                    color="white"
                    size="lg"
                    className="m-0"
                    onClick={this.props.next}
                  >
                    Überspringen{" "}
                    <MDBIcon icon="angle-double-right" className="m-0" />
                  </MDBBtn>
                  <MDBBtn
                    color="blue"
                    size="lg"
                    className="m-0"
                    disabled={!this.state["number-" + name]}
                    onClick={() =>
                      this.send(name, this.state["number-" + name], min, max)
                    }
                  >
                    Weiter
                  </MDBBtn>
                </div>
              ) : (
                <MDBBtn
                  color="blue"
                  size="lg"
                  className="m-0"
                  disabled={!this.state["number-" + name]}
                  onClick={() =>
                    this.send(name, this.state["number-" + name], min, max)
                  }
                >
                  Weiter
                </MDBBtn>
              )}
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
//#endregion

//#region > Exports
export default NumberModule;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
