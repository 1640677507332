//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { withRouter, Link } from "react-router-dom";
// QR Code scanner
import QrReader from "react-qr-reader";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBEdgeHeader,
  MDBFreeBird,
  MDBAlert,
} from "mdbreact";

//> CSS
import "./index.scss";
//#endregion

//#region > Components
class VerifyMail extends React.Component {
  state = {
    result: null,
  };

  // Get the QR code results
  handleScan = (data) => {
    if (data) {
      this.setState(
        {
          result: data,
          error: false,
        },
        () => {
          if (data.includes("id/sign/")) {
            window.location.replace(data);
          }
        }
      );
    }
  };

  handleError = (err) => {
    this.setState({
      error: err,
    });
  };

  render() {
    return (
      <div id="qrPage">
        <MDBContainer id="qr" className="text-center">
          <MDBIcon icon="qrcode" size="3x" className="blue-text" />
          <h3 className="font-weight-bold mt-2">QR Code scannen</h3>
          <p className="text-muted mb-4">
            Scanne den QR Code auf dem Bildschirm um das Dokument zu signieren.
          </p>
          {this.state.result ? (
            <MDBSpinner green />
          ) : (
            <>
              {this.state.error ? (
                <MDBAlert color="danger">
                  Bitte gebe der Website die Berechtigung, auf deine Kamera
                  zuzugreiefen um einen QR Code zu scannen.
                </MDBAlert>
              ) : (
                <div className="d-flex justify-content-center">
                  <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    className="scanner"
                  />
                </div>
              )}
            </>
          )}
        </MDBContainer>
      </div>
    );
  }
}
//#endregion

//#region > Exports
export default withRouter(VerifyMail);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
