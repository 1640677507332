//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBModalHeader,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import { changeUserData } from "../../../../store/actions/userActions";
//> CSS
import "../index.scss";
//#endregion

//#region > Components
class UserData extends React.Component {
  state = {};

  componentDidMount = () => {
    const { data } = this.props;

    this.setState({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
    });
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  saveSettings = async () => {
    const { first_name, last_name, email, phone, password } = this.state;

    const res = await this.props.changeUserData(
      first_name,
      last_name,
      email,
      phone,
      password
    );

    if (res.status) {
      if (!res.data.current_password) {
        window.location.reload();
      } else {
        this.setState({ error: "password" });
      }
    } else {
      this.setState({ error: "unknown" });
    }
  };

  render() {
    const { data } = this.props;

    return (
      <div id="userdata">
        <MDBBtn
          onClick={this.toggleModal}
          color="grey"
          className="m-0"
          size="md"
        >
          <MDBIcon far icon="address-card" />
          Daten ändern
        </MDBBtn>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          size="md"
          animation="left"
          className="d-flex flex-center"
        >
          <MDBModalHeader toggle={this.toggleModal}>
            <p className="lead font-weight-bold mb-0">
              Persönliche Daten ändern
            </p>
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <form>
              <MDBRow className="justify-content-center text-left">
                <MDBCol lg="6">
                  <MDBInput
                    type="text"
                    label="Vorname"
                    name="firstname"
                    value={
                      data.id_document && data.id_document.verified
                        ? data.first_name
                        : this.state.first_name
                    }
                    getValue={(val) => this.setState({ first_name: val })}
                    containerClass="my-2"
                    outline
                    disabled={data.id_document && data.id_document.verified}
                  />
                </MDBCol>
                <MDBCol lg="6">
                  <MDBInput
                    type="text"
                    label="Nachname"
                    name="surname"
                    value={
                      data.id_document && data.id_document.verified
                        ? data.last_name
                        : this.state.last_name
                    }
                    getValue={(val) => this.setState({ last_name: val })}
                    containerClass="my-2"
                    outline
                    disabled={data.id_document && data.id_document.verified}
                  />
                </MDBCol>
                {data.id_document && data.id_document.verified ? (
                  <MDBCol lg="12" className="mb-4">
                    <MDBAlert color="success">
                      <MDBIcon icon="award" className="mr-2" />
                      Deine Identität wurde durch deinen Ausweis bestätigt.{" "}
                      <strong>
                        Wenn du deinen Namen ändern willst, melde dich bitte bei
                        deinem Berater.
                      </strong>
                    </MDBAlert>
                  </MDBCol>
                ) : (
                  <>
                    {data.id_document?.denied ? (
                      <MDBCol lg="12" className="mb-4">
                        <MDBAlert color="danger">
                          <MDBIcon icon="times-circle" className="mr-2" />
                          Identität wurde nicht bestätigt.
                        </MDBAlert>
                      </MDBCol>
                    ) : (
                      <MDBCol lg="12" className="mb-4">
                        <MDBAlert color="warning">
                          <MDBIcon icon="award" className="mr-2" />
                          Bestätigung deiner Identität ausständig.
                        </MDBAlert>
                      </MDBCol>
                    )}
                  </>
                )}
                <MDBCol lg="12">
                  <MDBInput
                    type="email"
                    label="E-Mail"
                    name="email"
                    value={this.state.email}
                    getValue={(val) => this.setState({ email: val })}
                    containerClass="my-2"
                    outline
                  />
                </MDBCol>
                {data.verified ? (
                  <MDBCol lg="12" className="mb-4">
                    <MDBAlert color="success" className="mb-0 text-left">
                      <MDBIcon icon="check-circle" size="lg" className="mr-2" />
                      Die E-Mail Adresse wurde bestätigt.
                    </MDBAlert>
                  </MDBCol>
                ) : (
                  <MDBCol lg="12" className="mb-4">
                    <MDBAlert color="warning" className="mb-0 text-left">
                      <MDBIcon icon="times-circle" className="mr-2" />
                      E-Mail Adresse wurde noch nicht bestätigt.
                    </MDBAlert>
                  </MDBCol>
                )}
                {this.state.phone && (
                  <>
                    <MDBCol lg="6">
                      <MDBInput
                        type="text"
                        label="Telefon"
                        name="tel"
                        value={this.state.phone}
                        getValue={(val) => this.setState({ phone: val })}
                        containerClass="my-2"
                        disabled
                        outline
                      />
                    </MDBCol>
                  </>
                )}
                <MDBCol
                  lg={this.state.phone ? "6" : "12"}
                  className="d-flex align-items-center justify-content-center"
                >
                  <MDBBtn
                    color="grey"
                    onClick={() => {
                      this.toggleModal();
                      this.props.changePhone();
                    }}
                  >
                    Telefon {this.state.phone ? "ändern" : "eintragen"}
                  </MDBBtn>
                </MDBCol>
                {data.phone_verified ? (
                  <MDBCol lg="12">
                    <MDBAlert color="success" className="mb-0 text-left">
                      <MDBIcon icon="check-circle" size="lg" className="mr-2" />
                      Die Telefonnummer wurde bestätigt.
                    </MDBAlert>
                  </MDBCol>
                ) : (
                  <>
                    {this.state.phone ? (
                      <MDBCol lg="12">
                        <MDBAlert color="warning" className="mb-0 text-left">
                          <MDBIcon icon="times-circle" className="mr-2" />
                          Telefonnummer Bestätigung ausstehend.
                        </MDBAlert>
                      </MDBCol>
                    ) : (
                      <MDBCol lg="12">
                        <MDBAlert color="warning" className="mb-0 text-left">
                          <MDBIcon icon="times-circle" className="mr-2" />
                          Telefonnummer ausstehend. Die kannst deine
                          Telefonnummer hier selbst eintragen.
                        </MDBAlert>
                      </MDBCol>
                    )}
                  </>
                )}
                <MDBCol lg="12" className="mt-1 pt-3">
                  <hr />
                  <p className="mt-3">
                    Bitte bestätige deine Identität, in dem du dein Passwort
                    eingibst:
                  </p>
                </MDBCol>
                <MDBCol lg="12">
                  <MDBInput
                    type="password"
                    name="password"
                    label="Passwort bestätigen"
                    value={this.state.password}
                    getValue={(val) => this.setState({ password: val })}
                    containerClass={
                      this.state.error === "password" ? "mt-2 mb-0" : "my-2"
                    }
                    className={
                      this.state.error === "password"
                        ? "invalid mb-1"
                        : undefined
                    }
                    outline
                  />
                  {this.state.error === "password" && (
                    <p className="small text-danger mb-3">
                      Bitte gib zur Bestätigung dein Passwort ein.
                    </p>
                  )}
                </MDBCol>
              </MDBRow>
            </form>
            <div className="mt-4">
              <MDBBtn
                color="blue"
                className="w-100 d-block"
                onClick={this.saveSettings}
                disabled={this.state.password?.trim() === ""}
              >
                <MDBIcon icon="check" />
                Speichern
              </MDBBtn>
              <MDBBtn
                color="grey"
                onClick={this.toggleModal}
                className="w-100 d-block mt-3"
              >
                <MDBIcon icon="times" />
                Abbrechen
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  changeUserData,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(UserData);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
