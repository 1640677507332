//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { withRouter } from "react-router-dom";
//> Redux
// Connect
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardUp,
  MDBAvatar,
  MDBListGroup,
  MDBTooltip,
  MDBCardHeader,
} from "mdbreact";

// Actions
import { getUserData } from "../../../../store/actions/authActions";
import { getInsurances } from "../../../../store/actions/userActions";
//> Components
import {
  DamageReport,
  DamageReportList,
  UserData,
  Identification,
  InsuranceOverview,
  PhoneVerify,
} from "../../../organisms/modals";
//#endregion

//#region > Components
class Customer extends React.Component {
  state = { chatLoaded: false };

  componentDidMount = async () => {
    this.setState({
      greeting: this.getGreeting(),
    });

    if (window.tidioChatApi) {
      window.tidioChatApi.on("ready", this.onTidioChatApiReady);
    } else {
      document.addEventListener("tidioChat-ready", this.onTidioChatApiReady);
    }

    const insurances = await this.props.getInsurances();

    if (insurances.status) {
      this.setState({
        insurances: insurances.data,
      });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("tidioChat-ready", this.onTidioChatApiReady);
  };

  getGreeting = () => {
    // Get date
    const today = new Date();
    // Get current hours
    const curHr = today.getHours();
    // Check if first login
    const firstLogin = localStorage.getItem("local-first") ? true : false;

    // Remove first login entry
    if (firstLogin) {
      localStorage.removeItem("local-first");
    }

    // Store selected greeting
    let selected = null;

    if (firstLogin) {
      selected = <span>Herzlich willkommen</span>;
    } else {
      if (curHr < 11) {
        selected = <span>Guten Morgen</span>;
      } else if (curHr < 18) {
        selected = <span>Willkommen zurück</span>;
      } else {
        selected = <span>Guten Abend</span>;
      }
    }

    return selected;
  };

  onTidioChatApiReady = () => {
    if (!this.state.chatLoaded) {
      // Code after chat loaded
      this.setState(
        {
          chatLoaded: true,
        },
        () => window.tidioChatApi.close()
      );
    }
  };

  showReminder = () => {
    this.setState({
      showReminder: true,
      forceShowIdentificationModal: false,
    });
  };

  render() {
    const { data } = this.props;
    const { greeting, chatLoaded, showReminder } = this.state;

    return (
      <MDBContainer id="customer" className="text-left py-5">
        <p className="font-weight-bold lead">
          {greeting}, {data.first_name}!
        </p>
        {!data.phone_verified && !this.state.phoneVerified && (
          <MDBCol lg="12" className="mb-4">
            <MDBAlert color="info" className="m-0">
              <p className="lead mb-2">Jetzt Telefonnummer bestätigen</p>
              <p className="mb-1">
                Bitte bestätige diese, um deine Echtheit zu bestätigen.
              </p>
              <MDBBtn
                color="info"
                className="ml-0"
                onClick={() => this.setState({ phoneVerify: true })}
              >
                <MDBIcon icon="check-circle" />
                Jetzt verifizieren
              </MDBBtn>
            </MDBAlert>
          </MDBCol>
        )}
        {(showReminder ||
          data.id_document?.denied ||
          !data.id_document?.verified ||
          localStorage.getItem("verify-reminder")) && (
          <>
            {data.id_document?.denied ? (
              <MDBCol lg="12" className="mb-4">
                <MDBAlert color="danger" className="m-0">
                  <p className="lead mb-2">Ausweis abgelehnt!</p>
                  <p className="mb-1">
                    Der von Dir eingereichte Ausweis wurde von unseren
                    MitarbeiterInnen abgelehnt.
                  </p>
                  <MDBBtn
                    color="danger"
                    className="ml-0"
                    onClick={() =>
                      this.setState({ forceShowIdentificationModal: true })
                    }
                  >
                    <MDBIcon far icon="address-card" />
                    Neuen Ausweis hochladen
                  </MDBBtn>
                </MDBAlert>
              </MDBCol>
            ) : (
              <>
                {data.id_document?.url ? (
                  <MDBCol lg="12" className="mb-4">
                    <MDBAlert color="warning" className="m-0">
                      <p className="lead mb-2">
                        <MDBIcon icon="clock" far className="mr-2" />
                        Verifizierung wird durchgeführt
                      </p>
                      <p className="mb-1">
                        Die Echtheit deiner Daten wird soeben überprüft.
                      </p>
                    </MDBAlert>
                  </MDBCol>
                ) : (
                  <MDBCol lg="12" className="mb-4">
                    <MDBAlert color="danger" className="m-0">
                      <p className="lead mb-2">Verifizierung ausstehend!</p>
                      <p className="mb-1">
                        Die Echtheit deiner Daten ist noch nicht bestätigt.
                        Einige Funktionen sind daher noch nicht verfügbar.
                      </p>
                      <MDBBtn
                        color="danger"
                        className="ml-0"
                        onClick={() =>
                          this.setState({ forceShowIdentificationModal: true })
                        }
                      >
                        <MDBIcon far icon="address-card" />
                        Ausweis hochladen
                      </MDBBtn>
                    </MDBAlert>
                  </MDBCol>
                )}
              </>
            )}
          </>
        )}
        <MDBRow>
          <MDBCol lg="8" className="mb-4">
            <MDBCard className="mb-4">
              <MDBCardHeader>Schnellaktionen</MDBCardHeader>
              <MDBCardBody className="d-sm-flex d-block align-items-center">
                {data.insurances &&
                  data.insurances.filter(
                    (insurance) => insurance.status?.active
                  ).length > 0 && <DamageReport />}
                <MDBBtn color="blue" href="/" size="md" className="m-1">
                  Versicherungen entdecken
                </MDBBtn>
                <DamageReportList />
              </MDBCardBody>
            </MDBCard>
            <MDBCard>
              <MDBCardHeader>Meine Versicherungen</MDBCardHeader>
              <MDBCardBody>
                {data.insurances && data.insurances.length > 0 ? (
                  <MDBListGroup className="insurance-list">
                    {this.state.insurances &&
                      data.insurances.map((insurance) => {
                        const selectedInsurance = this.state.insurances.filter(
                          (ins) => ins.id === insurance.id
                        )[0];

                        return (
                          <InsuranceOverview
                            insurance={selectedInsurance}
                            key={selectedInsurance.id}
                          />
                        );
                      })}
                  </MDBListGroup>
                ) : (
                  <MDBAlert color="info">
                    <p>
                      Du hast aktuell noch keine Versicherungen über uns
                      abgeschlossen.
                    </p>
                    <MDBBtn color="blue" href="/">
                      Versicherungen erkunden
                    </MDBBtn>
                  </MDBAlert>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="4" className="mb-4">
            <MDBCard className="mb-4">
              <MDBCardHeader>Meine Daten</MDBCardHeader>
              <MDBCardBody className="small">
                <MDBRow>
                  <MDBCol md="6" className="mb-2">
                    <p className="font-weight-bold mb-0">
                      {data.first_name} {data.last_name}
                    </p>
                  </MDBCol>
                  <MDBCol md="6" className="text-right mb-2">
                    <MDBTooltip placement="bottom" material domElement>
                      <p className="mb-0 d-inline-block">
                        <MDBIcon
                          far
                          icon="address-card"
                          className="text-muted mr-2"
                        />
                        {data.id.substring(0, 8)}
                      </p>
                      <div>Kundennummer</div>
                    </MDBTooltip>
                  </MDBCol>
                </MDBRow>
                <p className="mb-0">
                  <MDBIcon far icon="envelope" className="text-muted mr-2" />
                  {data.email}
                </p>
                {data.phone && (
                  <p className="mb-0">
                    <MDBIcon icon="phone-alt" className="text-muted mr-2" />
                    {data.phone}
                  </p>
                )}
                <div className="mt-3" />
                <UserData
                  changePhone={() => this.setState({ phoneChange: true })}
                />
              </MDBCardBody>
            </MDBCard>
            {!data.advisor ? (
              <MDBCard testimonial>
                <MDBCardUp className="blue" />
                <MDBCardBody>
                  <h4 className="card-title mb-0">Beratung</h4>
                  <p className="small text-muted">
                    Dir wird in Kürze ein persönlicher Berater zugeteilt.
                  </p>
                  <hr />
                  <div>
                    <div className="blue-text">
                      <p className="mb-0">
                        <MDBIcon icon="phone-alt" className="mr-2" />
                        <a href="tel:+43 4242 28020">+43 4242 28020</a>
                      </p>
                      <p>
                        <MDBIcon far icon="envelope" className="mr-2" />
                        <a href="mailto:office@spardaplus.at">
                          office@spardaplus.at
                        </a>
                      </p>
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            ) : (
              <MDBCard testimonial>
                <MDBCardUp className="blue" />
                <MDBAvatar className="mx-auto white">
                  <img
                    src={
                      process.env.REACT_APP_MEDIAURL +
                      data.advisor.picture +
                      "?token=" +
                      localStorage.getItem("token")
                    }
                    alt=""
                  />
                </MDBAvatar>
                <MDBCardBody>
                  <h4 className="card-title mb-0">
                    {data.advisor && data.advisor.first_name}
                  </h4>
                  <p className="small text-muted">Dein persönlicher Berater</p>
                  <hr />
                  <div>
                    <div className="text-muted small mb-1">
                      {data.advisor &&
                        data.advisor.first_name + " " + data.advisor.last_name}
                    </div>
                    <div className="blue-text">
                      {data.advisor && data.advisor.phone && (
                        <p className="mb-0">
                          <MDBIcon icon="phone-alt" className="mr-2" />
                          <a href={`tel:${data.advisor.phone}`}>
                            {data.advisor.phone}
                          </a>
                        </p>
                      )}
                      {data.advisor && data.advisor.email && (
                        <p>
                          <MDBIcon far icon="envelope" className="mr-2" />
                          <a href={`mailto:${data.advisor.email}`}>
                            {data.advisor.email}
                          </a>
                        </p>
                      )}
                    </div>
                    <MDBBtn
                      color="blue"
                      href={`mailto:${data.advisor.email}`}
                      className="mx-0"
                    >
                      <MDBIcon far icon="envelope" />
                      E-Mail schreiben
                    </MDBBtn>
                  </div>
                </MDBCardBody>
              </MDBCard>
            )}
          </MDBCol>
        </MDBRow>
        <Identification
          showReminder={this.showReminder}
          doc={data.id_document}
          forceShow={this.state.forceShowIdentificationModal}
        />
        {(this.state.phoneVerify || this.state.phoneChange) && (
          <PhoneVerify
            phone={data.phone}
            isChange={this.state.phoneChange}
            toggle={(verified) =>
              this.setState({
                phoneVerify: undefined,
                phoneChange: undefined,
                phoneVerified: verified ?? undefined,
              })
            }
          />
        )}
      </MDBContainer>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
  data: state.auth.data,
});

const mapDispatchToProps = {
  getUserData,
  getInsurances,
};
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Customer));
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
