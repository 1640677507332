//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> Swipe
import SwipeableViews from "react-swipeable-views";
//> Print
import printJS from "print-js";
//> Time
import moment from "moment";
//> PDF
import {
  Document,
  Outline,
  Page,
  pdfjs,
} from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/src/Page/AnnotationLayer.css";
//> Lottie
import Lottie from "lottie-react-web";
//> Redux
// Connect
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBProgress,
} from "mdbreact";

//> Lottie
import pdfAnimationFile from "../../../../assets/lottie/pdf.json";
//> Components
import { QRModal, SignModal } from "../../../organisms/modals";
//> Actions
import { requestSignDocument } from "../../../../store/actions/signatureActions";
//#endregion

//#region > Config
const pdfAnimation = {
  loop: true,
  autoplay: true,
  animationData: pdfAnimationFile,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");
//#endregion

//#region > Components
class PDFSignatureView extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
    addSignature: true,
    readyToSign: false,
  };

  componentDidMount = () => {
    // Check if SEPA information is required
    if (this.props.isPayment) {
      this.requestSignDocument();

      // Refresh token every 30 seconds
      this.refreshInterval = window.setInterval(
        this.requestSignDocument,
        30000
      );
    }

    // Autofill values
    const { data } = this.props;

    // Prefill values
    this.setState({
      first_name: data.first_name ?? "",
      last_name: data.last_name ?? "",
      birthdate: data.birthdate
        ? moment(data.birthdate, "YYYY-MM-DD").format("DD.MM.YYYY")
        : "",
      street: data.street ?? "",
      streetnumber: data.street_number ?? "",
      city: data.city ?? "",
      zipcode: data.zipcode ?? "",
    });
  };

  // Request empty document for payment data
  requestSignDocument = async () => {
    const res = await this.props.requestSignDocument(this.props.insuranceId);

    // Check if state has been set or token has changed to prevent state from updating every time
    if (
      (this.state.paymentDocument === undefined ||
        this.state.paymentDocument?.token !== res?.data?.token) &&
      res.data.document?.id
    ) {
      this.setState({
        paymentDocument: {
          id: res.data.document?.id,
          token: res.data.token,
        },
      });
    }
  };

  // Retrieve the number of pages
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  // Get signature from parent including payment data if applied
  getSignature = (signatureFile) => {
    if (this.props.isPayment) {
      // Send payment data
      const signatureData = {
        signature: signatureFile,
        documentId: this.state.paymentDocument?.id,
        token: this.state.paymentDocument?.token,
        payment: {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          street: this.state.street,
          street_number: this.state.streetnumber,
          zipcode: this.state.zip,
          city: this.state.city,
          iban: this.state.iban.trim().replace(/ +/g, ""),
          bic: this.state.bic,
          birthdate: moment(this.state.birthdate, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          ),
        },
      };

      console.log(signatureData);

      this.props.getPaymentSignature(signatureData);
    } else {
      this.props.getSignature(signatureFile);
    }
  };

  // Get signature position
  getPosition = (e, page, pageIndex) => {
    // Scroll to top to prevent wrong placement
    window.scrollTo(0, 0);

    const touches = e.touches[0];
    const elementPosition = page.getBoundingClientRect();

    // Get element details
    const elementLeft = elementPosition.left;
    const elementTop = elementPosition.top;

    // Get position of click
    const clickTop = touches.pageY;
    const clickLeft = touches.pageX;

    // Get internal offset in px
    const left = clickLeft - elementLeft;
    const top = clickTop - elementTop;

    // Get internal offset in %
    const leftPercent = (left / elementPosition.width) * 100;
    const topPercent = (top / elementPosition.height) * 100;

    this.setState({
      signaturePlacement: {
        pageIndex,
        left: {
          relative: left,
          percent: leftPercent,
        },
        top: {
          relative: top,
          percent: topPercent,
        },
      },
    });
  };

  render() {
    const { url, token, nextAvailable, isPayment } = this.props;

    return (
      <div
        className={!this.props.getSignaturePosition ? "scrollable" : undefined}
      >
        {this.state.readyToSign ? (
          <>
            {!this.props.getSignaturePosition && (
              <>
                <div className="my-4 text-center">
                  <p className="lead font-weight-bold">Unterzeichnen</p>
                  <MDBRow className="justify-content-center">
                    <MDBCol lg="8">
                      <p className="small text-center mb-4">
                        Mit <strong>Sparda Plus ID</strong> kannst Du ganz
                        einfach und unkompliziert alle Dokumente direkt am
                        Desktop oder Mobilgerät unterzeichnen.
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBBtn
                    color="blue"
                    onClick={() =>
                      this.setState({ showSignature: true }, () => {
                        this.props.callToken(this.state.paymentDocument?.token);
                      })
                    }
                  >
                    <MDBIcon icon="signature" />
                    Am PC mit Maus
                  </MDBBtn>
                  <MDBBtn
                    color="green"
                    onClick={() =>
                      this.setState({ showQR: true }, () => {
                        this.props.getStatus(
                          this.props.isPayment
                            ? this.state.paymentDocument?.token
                            : this.props.token
                        );
                      })
                    }
                  >
                    <MDBIcon icon="signature" />
                    Am Handy mit Finger
                  </MDBBtn>
                  <div className="w-100 mb-4">
                    <div className="splitter mt-3 mb-2">
                      <span className="or">
                        <span className="or-text">oder</span>
                      </span>
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="text-muted">
                      Du kannst nicht digital unterzeichnen?
                    </p>
                    <p className="small text-center mb-2">
                      Ausdrucken, unterzeichnen und per Post schicken.
                    </p>
                  </div>
                  <MDBBtn
                    color="grey"
                    onClick={() =>
                      printJS({
                        printable: url,
                        type: "pdf",
                      })
                    }
                  >
                    <MDBIcon icon="print" />
                    Drucken
                  </MDBBtn>
                </div>
              </>
            )}
            <hr />
            <div className="text-center mt-3 mb-2">
              <p className="lead font-weight-bold">
                Noch nicht bereit zum unterzeichnen?
              </p>
              <p className="text-muted">
                Du hast noch Fragen zu dem Dokument? dein Berater ist für dich
                da.
              </p>
            </div>
            <div className="text-center">
              <MDBBtn
                color="grey"
                outline
                onClick={() =>
                  this.setState({
                    readyToSign: false,
                  })
                }
              >
                <MDBIcon icon="angle-left" />
                Zurück zum Dokument
              </MDBBtn>
              <MDBBtn color="danger" outline onClick={this.props.toggleModal}>
                <MDBIcon icon="times" />
                Abbrechen
              </MDBBtn>
            </div>
          </>
        ) : (
          <>
            <div className="mb-2">
              {!isPayment ? (
                <div className="d-flex justify-content-between">
                  <div>
                    {Array.from(new Array(this.state.numPages), (el, index) => {
                      return (
                        <MDBBtn
                          color="blue"
                          floating
                          className="m-1"
                          onClick={() =>
                            this.setState({ pageNumber: index + 1 })
                          }
                          disabled={this.state.pageNumber === index + 1}
                        >
                          {index + 1}
                        </MDBBtn>
                      );
                    })}
                  </div>
                  {this.props.getSignaturePosition ? (
                    <>
                      {this.state.addSignature ? (
                        <MDBBtn
                          color="success"
                          onClick={() =>
                            this.setState({ addSignature: false }, () =>
                              this.props.getSignaturePosition(
                                this.state.signaturePlacement
                              )
                            )
                          }
                          disabled={!this.state.signaturePlacement}
                        >
                          <MDBIcon icon="check-circle" />
                          Platzierung bestätigen
                        </MDBBtn>
                      ) : (
                        <MDBBtn
                          color="blue"
                          onClick={() => this.setState({ addSignature: true })}
                        >
                          <MDBIcon icon="signature" />
                          Signaturfeld hinzufügen
                        </MDBBtn>
                      )}
                    </>
                  ) : (
                    <>
                      <MDBBtn
                        color="green"
                        className="m-1 d-sm-block d-none"
                        onClick={() => this.setState({ readyToSign: true })}
                      >
                        Unterzeichnen
                      </MDBBtn>
                      <MDBBtn
                        color="green"
                        className="m-1 d-block d-sm-none"
                        onClick={() =>
                          this.setState({ showSignature: true }, () => {
                            this.props.callToken(
                              this.state.paymentDocument?.token
                            );
                          })
                        }
                      >
                        Unterzeichnen
                      </MDBBtn>
                    </>
                  )}
                </div>
              ) : (
                <div className="text-center">
                  <div className="mb-4">
                    <form>
                      <MDBRow className="d-flex justify-content-center">
                        <MDBCol md="8" className="text-left mt-3">
                          <p className="font-weight-bold mb-0">Basis Infos</p>
                        </MDBCol>
                        <MDBCol md="12"></MDBCol>
                        <MDBCol md="4">
                          <MDBInput
                            size="md"
                            name="firstname"
                            outline
                            label="Vorname"
                            value={this.state.first_name}
                            getValue={(val) =>
                              this.setState({ first_name: val })
                            }
                            containerClass="mb-1 mt-2"
                          />
                        </MDBCol>
                        <MDBCol md="4">
                          <MDBInput
                            size="md"
                            name="lastname"
                            outline
                            label="Nachname"
                            value={this.state.last_name}
                            getValue={(val) =>
                              this.setState({ last_name: val })
                            }
                            containerClass="mb-1 mt-2"
                          />
                        </MDBCol>
                        <MDBCol md="12"></MDBCol>
                        <MDBCol md="4">
                          <MDBInput
                            size="md"
                            name="birthdate"
                            outline
                            label="Geburtsdatum (DD.MM.YYYY)"
                            value={this.state.birthdate}
                            getValue={(val) =>
                              this.setState({ birthdate: val })
                            }
                            containerClass="mb-1 mt-2"
                          />
                        </MDBCol>
                        <MDBCol md="4"></MDBCol>
                        <MDBCol md="12"></MDBCol>
                        <MDBCol md="8" className="text-left mt-3">
                          <p className="font-weight-bold mb-0">Deine Adresse</p>
                        </MDBCol>
                        <MDBCol md="12"></MDBCol>
                        <MDBCol md="5">
                          <MDBInput
                            size="md"
                            name="street"
                            outline
                            label="Straße"
                            value={this.state.street}
                            getValue={(val) => this.setState({ street: val })}
                            containerClass="mb-1 mt-2"
                          />
                        </MDBCol>
                        <MDBCol md="3">
                          <MDBInput
                            size="md"
                            name="streetnumber"
                            outline
                            label="Hausnummer"
                            value={this.state.streetnumber}
                            getValue={(val) =>
                              this.setState({ streetnumber: val })
                            }
                            containerClass="mb-1 mt-2"
                          />
                        </MDBCol>
                        <MDBCol md="12"></MDBCol>
                        <MDBCol md="5">
                          <MDBInput
                            size="md"
                            name="city"
                            outline
                            label="Stadt"
                            value={this.state.city}
                            getValue={(val) => this.setState({ city: val })}
                            containerClass="mb-1 mt-2"
                          />
                        </MDBCol>
                        <MDBCol md="3">
                          <MDBInput
                            size="md"
                            name="zip"
                            outline
                            label="Postleitzahl (PLZ)"
                            value={this.state.zip}
                            getValue={(val) => this.setState({ zip: val })}
                            containerClass="mb-1 mt-2"
                          />
                        </MDBCol>
                        <MDBCol md="12"></MDBCol>
                        <MDBCol md="8" className="text-left mt-3">
                          <p className="font-weight-bold mb-0">Zahlungsdaten</p>
                        </MDBCol>
                        <MDBCol md="12"></MDBCol>
                        <MDBCol md="5">
                          <MDBInput
                            size="md"
                            name="iban"
                            outline
                            label="IBAN"
                            value={this.state.iban}
                            maxLength="25"
                            getValue={(val) =>
                              this.setState({
                                iban: val,
                              })
                            }
                            containerClass="mb-1 mt-2"
                          />
                          {this.state.error?.position === "IBAN" && (
                            <div>
                              <p className="mb-0 text-danger small text-left">
                                {this.state.error?.message}
                              </p>
                            </div>
                          )}
                        </MDBCol>
                        <MDBCol md="3">
                          <MDBInput
                            size="md"
                            name="bic"
                            outline
                            label="BIC"
                            value={this.state.bic}
                            maxLength="11"
                            getValue={(val) =>
                              this.setState({
                                bic: val,
                              })
                            }
                            containerClass="mb-1 mt-2"
                          />
                        </MDBCol>
                      </MDBRow>
                    </form>
                  </div>
                  <MDBBtn
                    color="green"
                    className="m-auto d-sm-block d-none"
                    onClick={() => {
                      // Check if IBAN is valid
                      const IBAN = require("iban");

                      if (
                        IBAN.isValid(this.state.iban.trim().replace(/ +/g, ""))
                      ) {
                        this.setState({
                          error: undefined,
                          readyToSign: true,
                        });
                      } else {
                        this.setState({
                          error: {
                            position: "IBAN",
                            message:
                              "Der eingegebene IBAN ist ungültig. Bitte überprüfe deine Eingabe.",
                          },
                        });
                      }
                    }}
                    disabled={
                      !this.state.first_name ||
                      !this.state.last_name ||
                      !this.state.birthdate ||
                      !this.state.street ||
                      !this.state.streetnumber ||
                      !this.state.city ||
                      !this.state.zip ||
                      !this.state.iban ||
                      !this.state.bic
                    }
                  >
                    Unterzeichnen
                  </MDBBtn>
                  <MDBBtn
                    color="green"
                    className="m-auto d-block d-sm-none"
                    onClick={() =>
                      this.setState({ showSignature: true }, () => {
                        this.props.callToken(this.state.paymentDocument?.token);
                      })
                    }
                    disabled={
                      !this.state.first_name ||
                      !this.state.last_name ||
                      !this.state.birthdate ||
                      !this.state.street ||
                      !this.state.streetnumber ||
                      !this.state.city ||
                      !this.state.zip ||
                      !this.state.iban ||
                      !this.state.bic
                    }
                  >
                    Unterzeichnen
                  </MDBBtn>
                </div>
              )}
            </div>
            {!isPayment && (
              <>
                <p className="text-center text-muted mb-0">
                  Seite {this.state.pageNumber} von {this.state.numPages}
                </p>
                <Document
                  file={url}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                  onLoadError={(err) => console.log(err)}
                  renderMode="canvas"
                  renderTextLayer={false}
                  loading={
                    <div className="animation-container">
                      <Lottie options={pdfAnimation} speed={1} ariaRole="img" />
                    </div>
                  }
                >
                  <SwipeableViews
                    enableMouseEvents={true}
                    onChangeIndex={(index) =>
                      this.setState({ pageNumber: index + 1 })
                    }
                    index={this.state.pageNumber - 1}
                  >
                    {Array.from(new Array(this.state.numPages), (el, index) => (
                      <>
                        {this.props.getSignaturePosition ? (
                          <div
                            onClick={(e) =>
                              this.state.addSignature &&
                              this.getPosition(
                                e,
                                this["pdfRef-" + index],
                                index
                              )
                            }
                            ref={(ref) => {
                              this["pdfRef-" + index] = ref;
                            }}
                            className="position-relative"
                          >
                            {this.state.signaturePlacement &&
                              this.state.signaturePlacement.pageIndex ===
                                index && (
                                <div
                                  className="signaturePlacement"
                                  style={{
                                    top:
                                      this.state.signaturePlacement.top
                                        .relative - 68,
                                    left:
                                      this.state.signaturePlacement.left
                                        .relative - 78,
                                  }}
                                >
                                  <span>Unterschrift Kunde</span>
                                  <div className="area">
                                    <svg>
                                      <line x1="0" y1="100%" x2="100%" y2="0" />
                                      <line x1="0" y1="0" x2="100%" y2="100%" />
                                    </svg>
                                  </div>
                                </div>
                              )}
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          </div>
                        ) : (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                          />
                        )}
                      </>
                    ))}
                  </SwipeableViews>
                </Document>
              </>
            )}
          </>
        )}
        {this.state.showQR && (
          <QRModal
            token={
              this.props.isPayment ? this.state.paymentDocument?.token : token
            }
            action="sign"
            next={this.props.next}
            nextAvailable={nextAvailable}
            toggleModal={() =>
              this.setState({ showQR: undefined }, () => {
                if (this.props.isFinished) {
                  this.props.closeSession(true);
                } else {
                  this.props.stopProgress(true, this.state.paymentDocument?.id);
                }
              })
            }
            closeSession={() =>
              this.setState({ showQR: undefined }, () => {
                this.props.stopProgress(false);
                this.props.closeSession();
              })
            }
            isOnMobile={this.props.calledMobile}
            isFinished={this.props.isFinished}
          />
        )}
        {this.state.showSignature && (
          <SignModal
            getSignature={this.getSignature}
            next={this.props.next}
            nextAvailable={nextAvailable}
            toggleModal={() =>
              this.setState({ showSignature: undefined }, () => {
                if (this.props.isFinished) {
                  this.props.closeSession(true);
                } else {
                  this.props.stopProgress(true);
                }
              })
            }
            closeSession={() =>
              this.setState({ showSignature: undefined }, () =>
                this.props.closeSession(true)
              )
            }
            isFinished={this.props.isFinished}
          />
        )}
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  requestSignDocument,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(PDFSignatureView);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
