//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> Redux
// Basic Redux provider
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBAlert,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
} from "mdbreact";

//> Components
import { ResetPassword } from "../../organisms/modals";
// Actions
import { sendQuestionnaire } from "../../../store/actions/questionnaireActions";
import { tokenAuth } from "../../../store/actions/authActions";
//> Style
import "./index.scss";
//#endregion

//#region > Components
class LoginUser extends React.Component {
  state = {};

  submitHandler = (event) => {
    event.preventDefault();

    if (this.props.insuranceAgency) {
      this.sendQuestionnaire();
    } else {
      this.loginUser();
    }
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  sendQuestionnaire = async () => {
    //const { first_name, last_name, email, password, zip, address } = this.props.data;
    this.setState({
      loading: true,
    });

    const email = this.state.email;
    const password = this.state.password;

    // Send questionnaire and login user
    const res = await this.props.sendQuestionnaire(
      this.props.insuranceSlug,
      this.props.insuranceDetails,
      this.props.insuranceAgency?.id,
      {
        email,
        password,
      },
      { payment_interval: this.props.paymentInterval, price: this.props.price }
    );

    if (res.status) {
      // Success
      window.location.replace("/me");
    } else {
      this.setState({
        loading: false,
        password: "",
        error: res.data.password
          ? "password"
          : res.data.LoginDenied
          ? "email"
          : false,
      });
    }
  };

  loginUser = () => {
    const email = this.state.email;
    const password = this.state.password;

    if (email && password) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          const res = await this.props.tokenAuth("email", {
            email,
            password,
          });

          if (res) {
            this.setState(
              {
                error: !res.status ? res.data : false,
                loading: false,
              },
              () => {
                // Success, redirect to user page
                res.status && window.location.replace("/me");
              }
            );
          } else {
            this.setState({
              error: { errorCode: 0 },
              loading: false,
            });
          }
        }
      );
    } else {
      this.setState({
        error: true,
        loading: false,
      });
    }
  };

  render() {
    const { lg = [5, 5], fluid = false } = this.props;
    const { error } = this.state;

    return (
      <MDBRow
        className={
          fluid ? "justify-content-center" : "justify-content-center pt-5"
        }
      >
        <MDBCol lg={lg[0]}>
          <MDBCard className={fluid && "no-blue"}>
            <MDBCardHeader>Login</MDBCardHeader>
            <MDBCardBody>
              <form onSubmit={this.submitHandler}>
                {error && (
                  <>
                    {(error.LoginDenied ||
                      (error.first_name && error.last_name)) && (
                      <MDBAlert color="danger" className="small mt-2">
                        Es wurde kein Nutzer mit dieser E-Mail / Passwort
                        Kombination gefunden.
                      </MDBAlert>
                    )}
                    {error.errorCode === 0 && (
                      <MDBAlert color="warning" className="small">
                        <p className="mb-0">
                          Aufgrund von Wartungsarbeiten ist das Login derzeit
                          nicht möglich.
                        </p>
                        <p className="mb-0">Bitte versuche es später erneut.</p>
                      </MDBAlert>
                    )}
                  </>
                )}
                <input
                  value={this.state.email}
                  onChange={this.changeHandler}
                  type="email"
                  placeholder="E-Mail"
                  id="materialFormRegisterConfirmEx2"
                  name="email"
                  className="form-control my-3"
                  label="Your email"
                  required
                />
                <input
                  value={this.state.password}
                  onChange={this.changeHandler}
                  type="password"
                  id="materialFormRegisterConfirmEx4"
                  className="form-control mb-1"
                  placeholder="Passwort"
                  name="password"
                  label="Password"
                  required
                />
                <ResetPassword email={this.state.email} />
                <div className="text-right">
                  <MDBBtn
                    color="blue"
                    type="submit"
                    className={
                      this.props.btnCenter ? "mx-0 w-100 mt-2" : "mx-0"
                    }
                    disabled={this.state.loading}
                  >
                    <MDBIcon icon="angle-right" />
                    Einloggen
                  </MDBBtn>
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
});

const mapDispatchToProps = {
  tokenAuth,
  sendQuestionnaire,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(LoginUser);
//#endregion
/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
