//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// This serves as an entry point to the DOM and server renderers for React
import ReactDOM from "react-dom";

//> Font Awesome
// Font Awesome is an awesome icon library
import "@fortawesome/fontawesome-free/css/all.min.css";

//> Bootstrap
import "bootstrap-css-only/css/bootstrap.min.css";

//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import "mdbreact/dist/css/mdb.css";

//> Redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import thunk from "redux-thunk";

//> Sentry.io tracing
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

//> CSS
// Root SCSS file
import "./index.scss";

//> Components
// Root component
import App from "./App";

import unregister from "./registerServiceWorker";
//#endregion

const store = createStore(rootReducer, applyMiddleware(thunk));

Sentry.init({
  dsn: "https://d400cfefc24445fc92f19c765e63bef2@o1079560.ingest.sentry.io/6084503",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Render the root component to <div id="root"></div>
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// Unregister service worker to always pull the latest version
unregister();

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
