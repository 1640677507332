//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { withRouter, Link } from "react-router-dom";
//> Redux
// Connect
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
} from "mdbreact";

//> Components
import { Navbar } from "../../molecules";
// Actions
import {
  verifyMail,
  requestEmailVerification,
} from "../../../store/actions/authActions";
import { resetPassword } from "../../../store/actions/userActions";
//> CSS
import "./index.scss";
//#endregion

//#region > Components
class VerifyMail extends React.Component {
  state = {
    token: undefined,
  };

  componentDidMount = async () => {
    const { match, location } = this.props;

    const token = match?.params?.token;
    const url = location?.pathname;

    if (token) {
      if (url.includes("verify")) {
        this.setState(
          {
            action: "verify",
            token,
          },
          async () => {
            const res = await this.props.verifyMail(token);

            this.setState({
              success: res.status,
            });
          }
        );
      } else if (url.includes("reset")) {
        const email = match?.params?.email;

        this.setState({
          action: "reset",
          email: decodeURIComponent(email),
          token,
        });
      } else {
        this.setState({
          action: false,
          token: false,
        });
      }
    } else {
      this.setState({
        action: null,
        token: false,
      });
    }
  };

  resetPassword = async () => {
    const { password, token } = this.state;

    if (password?.trim()) {
      const res = await this.props.resetPassword(password, token);

      this.setState({
        success: res.status,
        password: "",
      });
    }
  };

  render() {
    return (
      <>
        <Navbar inQuestionnaire={false} loader={false} />
        <MDBContainer id="verify-mail" className="text-center py-5">
          {this.state.action === "verify" && (
            <>
              {this.state.success ? (
                <>
                  <MDBIcon
                    icon="check-circle"
                    size="3x"
                    className="text-success"
                  />
                  <h2 className="mt-3">
                    Ihre E-Mail wurde erfolgreich verifiziert.
                  </h2>
                  <Link to="/me">
                    <MDBBtn color="blue">Zum Dashboard</MDBBtn>
                  </Link>
                </>
              ) : (
                <>
                  {this.state.success === false ? (
                    <>
                      <MDBIcon
                        icon="times-circle"
                        size="3x"
                        className="text-warning"
                      />
                      <h2 className="mt-3">
                        Der Link zur Bestätigung deiner E-Mail ist abgelaufen.
                      </h2>
                      <p className="lead text-muted">
                        Bitte frage einen neuen Link an.
                      </p>
                      <Link to="/me">
                        <MDBBtn color="danger">Abbrechen</MDBBtn>
                      </Link>
                      <MDBBtn
                        color="blue"
                        disabled={this.state.sentLink}
                        onClick={() =>
                          this.setState({ sentLink: true }, () =>
                            this.props.requestEmailVerification(
                              this.state.token
                            )
                          )
                        }
                      >
                        Neuen Link erhalten
                      </MDBBtn>
                    </>
                  ) : (
                    <div>
                      <MDBSpinner />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {this.state.action === "reset" && (
            <>
              {this.state.success ? (
                <>
                  <MDBIcon
                    icon="check-circle"
                    size="3x"
                    className="text-success"
                  />
                  <h2 className="mt-3">
                    Dein Passwort wurde erfolgreich geändert.
                  </h2>
                  <Link to="/login">
                    <MDBBtn color="blue">Zum Login</MDBBtn>
                  </Link>
                </>
              ) : (
                <>
                  {this.state.success === false ? (
                    <>
                      <MDBIcon
                        icon="times-circle"
                        size="3x"
                        className="text-danger"
                      />
                      <h2 className="mt-3">
                        Der Link zur Änderung deines Passworts ist abgelaufen.
                      </h2>
                      <p className="lead text-muted">
                        Bitte klicke nochmals auf "Passwort vergessen".
                      </p>
                      <Link to="/login">
                        <MDBBtn color="blue">Ok</MDBBtn>
                      </Link>
                    </>
                  ) : (
                    <div>
                      <h2 className="mt-3">Neues Passwort wählen</h2>
                      <p className="lead text-muted">
                        Bitte wähle dein neues Passwort
                      </p>
                      <MDBRow className="flex-center">
                        <MDBCol lg="4">
                          <form autoComplete="new-password">
                            {this.state.email !== "undefined" &&
                              this.state.email && (
                                <MDBInput
                                  type="email"
                                  name="email"
                                  value={this.state.email}
                                  disabled
                                  outline
                                />
                              )}
                            <MDBInput
                              type="password"
                              name="password"
                              getValue={(val) =>
                                this.setState({ password: val })
                              }
                              value={this.state.password}
                              outline
                              autoComplete="new-password"
                            />
                          </form>
                        </MDBCol>
                      </MDBRow>
                      <MDBBtn
                        color="blue"
                        disabled={!this.state.password}
                        onClick={this.resetPassword}
                      >
                        Speichern
                      </MDBBtn>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {this.state.action === null && (
            <>
              <MDBIcon icon="times-circle" size="3x" className="text-danger" />
              <h2 className="mt-3 mb-3">Kein Token im Link.</h2>
              <p className="lead text-muted">
                Bitte versuche es später noch einmal oder kontaktiere den
                Support.
              </p>
              <Link to="/">
                <MDBBtn color="blue">Zur Startseite</MDBBtn>
              </Link>
            </>
          )}
          {this.state.action === false && (
            <>
              <MDBIcon icon="times-circle" size="3x" className="text-danger" />
              <h2 className="mt-3 mb-3">Der Link ist fehlerhaft.</h2>
              <p className="lead text-muted">
                Bitte versuche es später noch einmal oder kontaktiere den
                Support.
              </p>
              <Link to="/">
                <MDBBtn color="blue">Zur Startseite</MDBBtn>
              </Link>
            </>
          )}
        </MDBContainer>
      </>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
});

const mapDispatchToProps = {
  verifyMail,
  resetPassword,
  requestEmailVerification,
};
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyMail));
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
