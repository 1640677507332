//#region > Imports
import {
  TOKEN_AUTH_S,
  TOKEN_AUTH_F,
  DATA_AUTH_S,
  DATA_AUTH_F,
  LOGOUT_S,
} from "../actions/authActions";
//#endregion

//#region > Config
// Have initial state for when state is not ready to be passed
const initState = {
  logged: null,
  data: null,
  error: undefined,
};
//#endregion

//#region > Functions
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case TOKEN_AUTH_S:
      return { ...state, logged: action?.payload?.token, error: undefined };
    case TOKEN_AUTH_F:
      return { ...state, logged: false, error: action.payload };
    case DATA_AUTH_S:
      return { ...state, data: action?.payload?.data };
    case DATA_AUTH_F:
      return { ...state, data: false, error: action.payload };
    case LOGOUT_S:
      return { ...state, data: null, error: undefined, logged: null };
    default:
      return { ...state };
  }
};
//#endregion

//#region > Exports
export default authReducer;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
