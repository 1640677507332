//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { withRouter, Redirect } from "react-router-dom";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBSpinner } from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

// Actions
import { getUserData } from "../../../store/actions/authActions";
//> Components
import { Navbar } from "../../molecules";
import { Customer, Employee } from "../../organisms";
//> Style
import "./index.scss";
//#endregion

//#region > Components
class LoginPage extends React.Component {
  state = {};

  componentDidMount = () => {
    const { data } = this.props;
    const token = localStorage.getItem("token");

    // Check if a possible user is logged in
    if (token && !data) {
      this.props.getUserData();
    }
  };

  renderUI = (userType) => {
    switch (userType) {
      case 1:
        return <Customer />;
      case 7:
        return <Employee />;
      default:
        return <Employee />; // Temp, until /self endpoint is set
    }
  };

  render() {
    const { data } = this.props;
    const token = localStorage.getItem("token");

    if (!token || data === false) return <Redirect to="/login" />;

    return (
      <div id="profile">
        <Navbar inQuestionnaire={false} loader={this.state.loading} />
        {!data ? (
          <div className="d-flex flex-center mt-5">
            <MDBSpinner blue />
          </div>
        ) : (
          <>{this.renderUI(data.utype)}</>
        )}
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
  data: state.auth.data,
  error: state.auth.error,
});

const mapDispatchToProps = {
  getUserData,
};
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
