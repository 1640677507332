//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> File upload
import Dropzone from "react-dropzone";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBSpinner,
  MDBListGroupItem,
  MDBListGroup,
  MDBView,
  MDBMask,
  MDBInput,
  MDBRow,
  MDBCol,
} from "mdbreact";
//> Time
import moment from "moment";
//> Redux
// Connect
import { connect } from "react-redux";

//> Images
import profileIMG from "../../../../assets/content/profile.gif";
//> Helpers
import { escapeHtml } from "../../../../helpers/escape";
//> Actions
import {
  getDamageReportData,
  sendMessageToDamageReport,
  changeReportStatus,
} from "../../../../store/actions/userActions";
//> CSS
import "../index.scss";
//#endregion

//#region > Components
class DamageReportChat extends React.Component {
  state = {
    selectedFiles: [],
    msg: "",
    selectChat: true,
    localMsg: [],
  };

  componentDidMount = () => {
    const { damageReports } = this.props;

    damageReports &&
      damageReports.forEach((element) => {
        this.getData(element.id);
      });
  };

  getData = async (id) => {
    const res = await this.props.getDamageReportData(id);

    if (res.status) {
      this.setState({
        ["damageReport-" + id]: {
          infos: res.data,
          messages: res.data.messages.reverse(),
        },
      });
    }
  };

  getDrop = (files) => {
    const res = files.map((file) => {
      return {
        data: file,
        preview: URL.createObjectURL(file),
      };
    });

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...res],
      status: "",
    });
  };

  removeDrop = (name) => {
    const res = this.state.selectedFiles.filter(
      (file) => file.data.name !== name
    );

    this.setState({
      selectedFiles: res,
    });
  };

  sendFiles = async () => {
    const { selectedFiles, note, selectedOption } = this.state;

    if (note && selectedOption && selectedOption.length > 0) {
      let files = undefined;

      if (selectedFiles.length > 0) {
        files = selectedFiles.map((file) => file.data);
      }

      const res = await this.props.createDamageReport(
        selectedOption[0],
        note,
        files
      );

      this.setState({
        response: res,
      });
    }
  };

  sendMsg = async () => {
    const { msg, selectedChat, selectedFiles } = this.state;

    const images = selectedFiles.map((file) => file.data);

    if (msg && selectedChat.infos) {
      const res = await this.props.sendMessageToDamageReport(
        selectedChat.infos.id,
        msg,
        images
      );

      if (res.status) {
        this.setState({
          localMsg: [
            { msg: res.data?.message, images: this.state.selectedFiles },
            ...this.state.localMsg,
          ],
          selectedFiles: [],
          msg: "",
          answerMsg: false,
        });
      } else {
        this.setState({
          error: res.data,
        });
      }
    }
  };

  render() {
    const { user, damageReports } = this.props;

    let loaded = 0;

    damageReports &&
      damageReports.forEach((element) => {
        if (this.state["damageReport-" + element.id]) {
          loaded = loaded + 1;
        }
      });

    return (
      <div className="chat">
        <MDBModal
          isOpen={this.state.selectChat}
          toggle={this.props.closeChat}
          size="md"
          className="d-flex flex-center"
          animation="left"
          id="chat"
        >
          <MDBModalHeader toggle={this.props.closeChat}>
            <p className="lead font-weight-bold mb-0">
              Schadensmeldungen von {user.first_name} {user.last_name}
            </p>
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {damageReports && loaded !== damageReports.length ? (
              <MDBSpinner />
            ) : (
              <>
                {damageReports ? (
                  <MDBListGroup>
                    {damageReports.map((report) => {
                      const details = this.state["damageReport-" + report.id];

                      return (
                        <MDBListGroupItem
                          key={"damageReport-" + report.id}
                          className="text-left clickable"
                          onClick={() =>
                            this.setState({
                              selectChat: false,
                              selectedChat: details,
                            })
                          }
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              <p className="font-weight-bold">
                                {details.infos.policy_name}
                              </p>
                              <p>{details.infos.policy_id}</p>
                            </div>
                            <div className="text-right">
                              {details.infos.status !== "c" && (
                                <p className="text-warning small">
                                  <MDBIcon far icon="circle" className="mr-2" />
                                  Offen
                                </p>
                              )}
                              {details.infos.status === "w" && (
                                <p className="text-warning small">
                                  Kunde wartet auf Ihre Antwort
                                </p>
                              )}
                              {details.infos.status === "o" && (
                                <p className="text-muted small">
                                  Wartet auf Kundenantwort
                                </p>
                              )}
                              {details.infos.status === "c" && (
                                <p className="text-info small">
                                  <MDBIcon
                                    far
                                    icon="check-circle"
                                    className="mr-2"
                                  />
                                  Fertiggestellt
                                </p>
                              )}
                              <p className="text-muted small">
                                Erstellt:{" "}
                                {moment(details.infos.date).format(
                                  "DD.MM.YYYY HH:mm"
                                )}
                              </p>
                            </div>
                          </div>
                        </MDBListGroupItem>
                      );
                    })}
                  </MDBListGroup>
                ) : (
                  <div className="p-3">
                    <p>Keine Schadensmeldungen vorhanden.</p>
                  </div>
                )}
              </>
            )}
          </MDBModalBody>
        </MDBModal>
        {this.state.selectedChat && (
          <MDBModal
            isOpen={true}
            toggle={() =>
              this.setState(
                {
                  selectedChat: undefined,
                  selectChat: true,
                },
                () => this.props.closeChat()
              )
            }
            size="lg"
            className="d-flex flex-center"
            animation="left"
          >
            <MDBModalHeader
              toggle={() =>
                this.setState(
                  {
                    selectedChat: undefined,
                    selectChat: true,
                  },
                  () => this.props.closeChat()
                )
              }
            >
              <p className="lead mb-0">
                {this.state.selectedChat.infos.policy_name} von{" "}
                {this.state.selectedChat.infos.creator}
              </p>
            </MDBModalHeader>
            <MDBModalBody className="text-left p-3">
              {this.state.selectedChat.infos &&
              (this.state.selectedChat.infos.status === "o" ||
                this.state.selectedChat.infos.status === "w") ? (
                <>
                  {this.state.answerMsg ? (
                    <div className="mt-4">
                      <p className="mb-2 font-weight-bold">
                        Antwort an {this.state.selectedChat.infos.creator}{" "}
                        senden
                      </p>
                      <MDBInput
                        type="textarea"
                        rows="5"
                        outline
                        containerClass="mt-0 mb-0"
                        value={this.state.msg}
                        getValue={(val) => this.setState({ msg: val })}
                      />
                      {this.state.error && (
                        <span className="text-danger small">
                          Du musst noch{" "}
                          {(
                            Math.round(this.state.error.time_remaining * 100) /
                            100
                          ).toFixed(0)}{" "}
                          Sekunden warten bevor Du eine weitere Nachricht senden
                          kannst.
                        </span>
                      )}
                      <p className="text-muted mb-1 mt-3">
                        Dateien anfügen (optional)
                      </p>
                      <Dropzone
                        onDrop={(acceptedFiles) => this.getDrop(acceptedFiles)}
                        //onDropAccepted={() => this.setState({ status: "accept" })}
                        onDropRejected={() =>
                          this.setState({ status: "reject" })
                        }
                        onDragOver={() => this.setState({ status: "active" })}
                        onDragLeave={() => this.setState({ status: "" })}
                        accept="image/jpeg, image/png"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className={"wrapper " + this.state.status}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <p>
                                Ziehe Dateien hierher oder klicke auf das Feld,
                                um Dateien auszuwählen
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <aside className="thumbs-container mb-3">
                        {this.state.selectedFiles.length > 0 &&
                          this.state.selectedFiles.map((file) => {
                            return (
                              <div className="thumb" key={file.data.name}>
                                <div className="thumb-inner">
                                  <MDBView
                                    onClick={() =>
                                      this.removeDrop(file.data.name)
                                    }
                                  >
                                    <img
                                      src={file.preview}
                                      alt="File preview"
                                    />
                                    <MDBMask
                                      className="d-flex flex-center"
                                      overlay="black-slight"
                                    >
                                      <MDBIcon
                                        far
                                        icon="trash-alt"
                                        className="text-white"
                                      />
                                    </MDBMask>
                                  </MDBView>
                                </div>
                              </div>
                            );
                          })}
                      </aside>
                      <div className="text-right">
                        <MDBBtn
                          color="blue"
                          disabled={!this.state.msg}
                          onClick={() => this.sendMsg()}
                        >
                          <MDBIcon far icon="paper-plane" />
                          Senden
                        </MDBBtn>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <p className="lead">Aktionen</p>
                      <div className="d-flex justify-content-between">
                        <MDBBtn
                          color="blue"
                          onClick={() => this.setState({ answerMsg: true })}
                        >
                          <MDBIcon icon="reply" />
                          Antwort senden
                        </MDBBtn>
                        <MDBBtn
                          color="orange"
                          onClick={() => {
                            this.props.changeReportStatus(
                              this.state.selectedChat.infos.id,
                              "c"
                            );

                            this.props.closeChat();
                          }}
                        >
                          <MDBIcon far icon="check-circle" />
                          Festschreiben
                        </MDBBtn>
                      </div>
                      <hr />
                    </div>
                  )}
                </>
              ) : (
                <MDBAlert color="info">
                  <p>
                    Diese Schadensmeldung wurde als fertiggestellt markiert. Du
                    kannst daher nicht mehr auf diese antworten.
                  </p>
                  <MDBBtn
                    color="blue"
                    size="md"
                    onClick={() => {
                      this.props.changeReportStatus(
                        this.state.selectedChat.infos.id,
                        "w"
                      );
                    }}
                  >
                    <MDBIcon far icon="check-circle" />
                    Festschreibung aufheben
                  </MDBBtn>
                </MDBAlert>
              )}
              <p className="mb-2 font-weight-bold mb-3">Dein Chatverlauf</p>
              <div className="messages">
                {this.state.localMsg &&
                  this.state.localMsg.map((msg) => {
                    const images =
                      msg.images &&
                      msg.images.map((img) => {
                        return {
                          src: img.preview,
                        };
                      });

                    console.log("user", msg);

                    return (
                      <div className="d-flex msg">
                        <div className="msg-img">
                          <img
                            src={
                              process.env.REACT_APP_MEDIAURL +
                              this.props.user.advisor.picture +
                              "?token=" +
                              localStorage.getItem("token")
                            }
                            alt="File preview"
                          />
                        </div>
                        <div>
                          <p className="font-weight-bold mb-0">Du</p>
                          <p className="mb-2 small text-muted">
                            Vor einigen Sekunden
                          </p>
                          {msg.msg.includes("DAMAGEREPORT") ? (
                            <>
                              {msg.msg.includes(
                                "DAMAGEREPORTCLOSEDNOWMESSAGE"
                              ) && (
                                <p>Die Schadensmeldung wurde geschlossen.</p>
                              )}
                              {msg.msg.includes(
                                "DAMAGEREPORTOPENEDNOWMESSAGE"
                              ) && (
                                <p>
                                  Die Schadensmeldung wurde wieder geöffnet.
                                </p>
                              )}
                            </>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: escapeHtml(msg.msg).replace(
                                  /\n/g,
                                  "<br/>"
                                ),
                              }}
                            ></p>
                          )}
                          {images && images.length > 0 && (
                            <MDBRow>
                              {images.map((img) => (
                                <MDBCol lg="4">
                                  <img src={img.src} className="img-fluid" />
                                </MDBCol>
                              ))}
                            </MDBRow>
                          )}
                        </div>
                      </div>
                    );
                  })}
                {this.state.selectedChat.messages &&
                  this.state.selectedChat.messages.length > 0 &&
                  this.state.selectedChat.messages.map((msg, i) => {
                    const images =
                      msg.images &&
                      msg.images.map((img) => {
                        return {
                          src:
                            process.env.REACT_APP_MEDIAURL +
                            img.url +
                            "?token=" +
                            localStorage.getItem("token"),
                        };
                      });

                    console.log("selected", msg);

                    return (
                      <div className="d-flex msg" key={"message-" + i}>
                        <div className="msg-img">
                          <img
                            src={
                              msg.sender.picture
                                ? process.env.REACT_APP_MEDIAURL +
                                  msg.sender.picture +
                                  "?token=" +
                                  localStorage.getItem("token")
                                : profileIMG
                            }
                            alt={msg.sender.name}
                          />
                        </div>
                        <div>
                          <p className="font-weight-bold mb-0">
                            {msg.sender.name ===
                            `${this.props.user.advisor.first_name} ${this.props.user.advisor.last_name}`
                              ? "Du"
                              : msg.sender.name}
                          </p>
                          <p className="mb-2 small text-muted">
                            {moment(msg.date).fromNow()}
                          </p>
                          {msg.body.includes("DAMAGEREPORT") ? (
                            <>
                              {msg.body.includes(
                                "DAMAGEREPORTCLOSEDNOWMESSAGE"
                              ) && (
                                <p>Die Schadensmeldung wurde geschlossen.</p>
                              )}
                              {msg.body.includes(
                                "DAMAGEREPORTOPENEDNOWMESSAGE"
                              ) && (
                                <p>
                                  Die Schadensmeldung wurde wieder geöffnet.
                                </p>
                              )}
                            </>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: escapeHtml(msg.body).replace(
                                  /\n/g,
                                  "<br/>"
                                ),
                              }}
                            ></p>
                          )}
                          {images && images.length > 0 && (
                            <MDBRow>
                              {images.map((img) => (
                                <MDBCol lg="4">
                                  <img src={img.src} className="img-fluid" />
                                  <p>
                                    <a
                                      href={img.src}
                                      className="small"
                                      target="_blank"
                                    >
                                      Bild herunterladen
                                    </a>
                                  </p>
                                </MDBCol>
                              ))}
                            </MDBRow>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </MDBModalBody>
          </MDBModal>
        )}
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  getDamageReportData,
  sendMessageToDamageReport,
  changeReportStatus,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(DamageReportChat);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
