export function writeToLS(key, value) {
  if (key && value) {
    localStorage.setItem(key, value);

    return true;
  } else {
    return false;
  }
}

export function getLS(key) {
  const entry = localStorage.getItem(key);

  if (entry !== null) {
    return entry;
  } else {
    // No LS entry with that key
    return null;
  }
}

export function removeLS(key) {
  if (localStorage.getItem(key) !== null) {
    localStorage.removeItem(key);

    return true;
  } else {
    // No LS entry with that key
    return false;
  }
}
