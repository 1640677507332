//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { withRouter } from "react-router-dom";
//> Redux
// Connect
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBBadge,
  MDBDataTable,
  MDBTooltip,
} from "mdbreact";

// Actions
import { getUserData } from "../../../../store/actions/authActions";
import { getInsurances } from "../../../../store/actions/userActions";
//> Components
import {
  DamageReportChat,
  IdCardCheck,
  InsuranceList,
  UserInfo,
} from "../../../organisms/modals";
import { StatusSymbols } from "../../../atoms";
import AssignAdvisor from "../../modals/AssignAdvisor";
//> Images
import plusIMG from "../../../../assets/content/plus.png";
//#endregion

//#region > Components
class Employee extends React.Component {
  state = {
    chatLoaded: false,
    data: {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Kontakt",
          field: "contact",
          sort: "asc",
          width: 200,
        },
        {
          label: "Berater",
          field: "advisor",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Verwaltung",
          field: "functions",
          sort: "disabled",
          width: 50,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 50,
        },
        {
          label: "Warnungen",
          field: "state",
          sort: "disabled",
          width: 20,
        },
      ],
    },
  };

  componentDidMount = () => {
    this.setState({
      greeting: this.getGreeting(),
    });

    if (this.props.data) {
      this.generateList();
      this.getInsurances();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.generateList();
    }
  };

  // Get list of insurances
  getInsurances = async () => {
    const res = await this.props.getInsurances();

    if (res.status) {
      this.setState({
        insuranceList: res.data,
      });
    } else {
      this.setState({
        insuranceList: false,
      });
    }
  };

  matchInsurances = (user) => {
    const { insuranceList } = this.state;
    const userInsurances = user.insurances;

    if (userInsurances && insuranceList) {
      let res = [];

      insuranceList.forEach((element) => {
        userInsurances.forEach((userIns) => {
          if (element.id === userIns.id) {
            res = [...res, element];
          }
        });
      });

      return { user, info: res };
    }
  };

  // Create table list
  generateList = () => {
    this.setState({
      data: {
        columns: this.state.data.columns,
        rows: this.props.data[2].map((user) => {
          return {
            name: user.first_name + " " + user.last_name,
            contact: user.email,
            advisor: (
              <div className="d-flex w-100 flex-center">
                {user.advisor ? (
                  <MDBTooltip placement="bottom" material domElement>
                    <img
                      src={
                        process.env.REACT_APP_MEDIAURL +
                        user.advisor?.picture +
                        "?token=" +
                        localStorage.getItem("token")
                      }
                      alt={
                        user.advisor
                          ? user.advisor?.first_name
                          : "Advisor picture"
                      }
                      onClick={() => this.assignAdvisor(user, user.advisor)}
                    />
                    <div>
                      {user.advisor?.first_name} {user.advisor?.last_name}
                    </div>
                  </MDBTooltip>
                ) : (
                  <MDBTooltip placement="bottom" material domElement>
                    <img
                      src={plusIMG}
                      alt={
                        user.advisor?.first_name + " " + user.advisor?.last_name
                      }
                      onClick={() => this.assignAdvisor(user, user.advisor)}
                      className="not-rounded"
                    />
                    <div>Berater zuweisen</div>
                  </MDBTooltip>
                )}
              </div>
            ),
            functions: (
              <div className="d-flex w-100 align-items-center text-left">
                <UserInfo user={user} key={user.id} />
                {user.damagereports && (
                  <MDBBtn
                    size="md"
                    color="elegant"
                    className="inbox"
                    disabled={!user.advisor}
                    onClick={() => this.setState({ chat: user })}
                  >
                    <MDBIcon icon="fire" className="m-0 px-1" size="lg" />
                    {user.damagereports &&
                      user.damagereports.filter((rep) => rep.status === "w")
                        .length > 0 && (
                        <MDBBadge pill color="orange">
                          {
                            user.damagereports.filter(
                              (rep) => rep.status === "w"
                            ).length
                          }
                        </MDBBadge>
                      )}
                  </MDBBtn>
                )}
                {user.insurances && (
                  <MDBBtn
                    size="md"
                    color="elegant"
                    disabled={!user.advisor}
                    onClick={() =>
                      this.setState({
                        insuranceListUser: this.matchInsurances(user),
                      })
                    }
                  >
                    <MDBIcon
                      far
                      icon="address-card"
                      className="m-0 px-1"
                      size="lg"
                    />
                  </MDBBtn>
                )}
                {user.id_document &&
                  !user.id_document?.verified &&
                  !user.id_document?.denied && (
                    <MDBBtn
                      size="md"
                      color="warning"
                      onClick={() => this.setState({ idCardCheckUser: user })}
                    >
                      <MDBIcon
                        far
                        icon="address-card"
                        className="m-0"
                        size="lg"
                      />
                    </MDBBtn>
                  )}
              </div>
            ),
            status: (
              <StatusSymbols
                verified={user.verified}
                document={user.id_document}
              />
            ),
            state: (
              <>
                {!user.id_document?.verified &&
                  !user.id_document?.denied &&
                  user.id_document && (
                    <div className="text-danger text-center">
                      <MDBIcon icon="exclamation-triangle" className="mr-1" />
                      <span className="d-inline-block small">
                        Ausweis überprüfen
                      </span>
                    </div>
                  )}
              </>
            ),
          };
        }),
      },
    });
  };

  assignAdvisor = (user, currentAdvisor) => {
    this.setState({
      assignAdvisor: {
        user,
        currentAdvisor,
      },
    });
  };

  closeAssignAdvisor = () => {
    this.setState({
      assignAdvisor: undefined,
    });
  };

  getGreeting = () => {
    // Get date
    const today = new Date();
    // Get current hours
    const curHr = today.getHours();
    // Check if first login
    const firstLogin = localStorage.getItem("local-first") ? true : false;

    // Remove first login entry
    if (firstLogin) {
      localStorage.removeItem("local-first");
    }

    // Store selected greeting
    let selected = null;

    if (firstLogin) {
      selected = <span>Herzlich willkommen</span>;
    } else {
      if (curHr < 11) {
        selected = <span>Guten Morgen</span>;
      } else if (curHr < 18) {
        selected = <span>Willkommen zurück</span>;
      } else {
        selected = <span>Guten Abend</span>;
      }
    }

    return selected;
  };

  refresh = () => {
    /*if (this.props.data) {
      this.setState(
        {
          assignAdvisor: undefined,
        },
        () => {
          this.generateList();
          this.getInsurances();
        }
      );
    }*/

    window.location.reload();
  };

  render() {
    const { data } = this.props;
    const { greeting } = this.state;
    const currentUser = data[0];

    return (
      <MDBContainer id="employee" className="text-left py-5">
        <p className="font-weight-bold lead">
          {greeting}, {currentUser.first_name}!
        </p>
        <MDBRow>
          <MDBCol lg="12" className="mb-4">
            <MDBCard>
              <MDBCardBody>
                <p className="font-weight-bold">Allgemeine Funktionen</p>
                <div className="d-flex justify-content-between">
                  <MDBBtn color="blue" onClick={this.refresh}>
                    <MDBIcon icon="sync-alt" />
                    Liste aktualisieren
                  </MDBBtn>
                  <div>
                    <MDBBtn
                      color="grey"
                      href="mailto:support@kanbon.at?subject=Fehlermeldung&body=Bitte%20beschreibe%20den%20Fehler"
                    >
                      <MDBIcon icon="exclamation-triangle" />
                      Fehler melden
                    </MDBBtn>
                    <MDBBtn color="grey" href="tel:+4368120502754">
                      <MDBIcon icon="headset" />
                      Technischer Support
                    </MDBBtn>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="12" className="mb-4">
            <MDBCard>
              <MDBCardBody>
                <MDBDataTable
                  striped
                  bordered
                  small
                  data={this.state.data}
                  infoLabel={["Zeige", "-", "von", "Kunden"]}
                  searchLabel="Suchen"
                  entriesLabel="Kunden anzeigen"
                  paginationLabel={["Vorherig", "Nächste"]}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        {this.state.assignAdvisor && (
          <AssignAdvisor
            advisorData={this.state.assignAdvisor}
            toggle={this.closeAssignAdvisor}
            refreshList={this.refresh}
          />
        )}
        {this.state.chat && (
          <DamageReportChat
            user={this.state.chat}
            damageReports={this.state.chat.damagereports}
            closeChat={() =>
              this.setState({ chat: undefined }, () => this.props.getUserData())
            }
          />
        )}
        {this.state.idCardCheckUser && (
          <IdCardCheck
            user={this.state.idCardCheckUser}
            close={() =>
              this.setState({ idCardCheckUser: undefined }, () =>
                this.props.getUserData()
              )
            }
          />
        )}
        {this.state.insuranceListUser && (
          <InsuranceList
            user={this.state.insuranceListUser.user}
            insuranceList={this.state.insuranceListUser.info}
            closeInsuranceView={() =>
              this.setState({ insuranceListUser: undefined }, () => {
                this.generateList();
                this.getInsurances();
              })
            }
          />
        )}
      </MDBContainer>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  logged: state.auth.logged,
  data: state.auth.data,
});

const mapDispatchToProps = {
  getUserData,
  getInsurances,
};
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Employee));
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
