//#region > Imports
import * as Sentry from "@sentry/browser";
//#endregion

//#region > Functions
function setTags(tags = []) {
  // Set tags
  tags.forEach((element) => {
    Sentry.setTag(element.key, element.value);
  });
}
//#endregion

//#region > Exports
export function reportException(err, msg = "", endpoint) {
  // Send exception to Sentry (best = err object)
  Sentry.captureException(err, {
    tags: {
      message: msg,
      route: window.location.pathname,
      endpoint: endpoint,
    },
  });
}
//#endregion
