//#region > Imports
import * as Sentry from "@sentry/browser";

import { reportException } from "../../helpers/errorReporting";
//#endregion

//#region > Action types
export const TOKEN_AUTH_S = "AUTH_SUCCESS";
export const TOKEN_AUTH_F = "AUTH_FAIL";
export const DATA_AUTH_S = "DATA_SUCCESS";
export const DATA_AUTH_F = "DATA_FAIL";
export const LOGOUT_S = "LOGOUT_SUCCESS";
//#endregion

//#region > Functions
const setToken = (token) => {
  localStorage.setItem("token", token);
};

const removeToken = () => {
  localStorage.removeItem("token");
};

const addChat = async (uuid, email, name, phone) => {
  const script = document.createElement("script");

  script.src = process.env.REACT_APP_TIDIO_URL;
  script.async = true;

  document.body.appendChild(script);

  if (uuid) {
    document.tidioIdentify = {
      distinct_id: uuid, // Unique user ID in your system
      email: email ? email : undefined, // User email
      name: name ? name : undefined, // Visitor name
      phone: phone ? phone : undefined, // Visitor phone
      verified: true,
    };
  }

  document.tidioChatLang = "de";
};

const removeChat = () => {
  if (window.tidioChatApi) {
    window.tidioChatApi.close();
    window.tidioChatApi.display(true);
  }

  const element = document.getElementById("tidio-chat");

  if (element && element.parentNode) {
    element.parentNode.removeChild(element);
  }
};
//#endregion

//#region > Creators
export const verifyMail = (token) => {
  return (dispatch, getState) => {
    return fetch(
      process.env.REACT_APP_BASEURL + "users/verify-mail/" + token + "/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data };
          default:
            reportException(
              data,
              "E-Mail verification failed.",
              "POST users/verify-mail/<token>/"
            );

            // Default case (other HTTP status code)
            return { status: false, data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "E-Mail verification failed.",
          "POST users/verify-mail/<token>/"
        );

        return { status: false, data: null };
      });
  };
};

export const requestEmailVerification = () => {
  return (dispatch, getState) => {
    return fetch(process.env.REACT_APP_BASEURL + "users/verify-mail/request/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            return { status: true, data };
          default:
            reportException(
              data,
              "Could not request E-Mail verification.",
              "POST users/verify-mail/request/"
            );

            // Default case (other HTTP status code)
            return { status: false, data };
        }
      })
      .catch((error) => {
        reportException(
          error,
          "Could not request E-Mail verification.",
          "POST users/verify-mail/request/"
        );

        return { status: false, data: null };
      });
  };
};

export const tokenAuth = (type, credentials) => {
  return (dispatch, getState) => {
    if (type === "email") {
      const email = credentials.email;
      const psw = credentials.password;

      return fetch(process.env.REACT_APP_BASEURL + "users/create/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: psw,
        }),
      })
        .then(async (response) => {
          const data = await response.json();

          // Set first time logged in
          if (data.user_created) {
            localStorage.setItem("local-first", true);
          }

          switch (response.status) {
            case 200: // OK
              // Set token in local storage
              setToken(data.token);

              // Add user to enriched events
              Sentry.setUser({ email: email });
              Sentry.setTag("authenticated", "yes");

              return { status: true, data };
            default:
              reportException(data, "Could not login", "POST users/create/");

              return { status: false, data };
          }
        })
        .catch((error) => {
          reportException(error, "Could not login", "POST users/create/");

          dispatch({
            type: TOKEN_AUTH_F,
            payload: {
              errorCode: 0,
              message: "ERR_CONNECTION_REFUSED",
              error,
            },
          });
        });
    }
  };
};

export const getUserData = () => {
  return (dispatch, getState) => {
    fetch(process.env.REACT_APP_BASEURL + "users/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();

        switch (response.status) {
          case 200: // OK
            // Set user data as local cache
            localStorage.setItem("local-cache", JSON.stringify(data));

            try {
              if (data && data.utype === 1) {
                addChat(
                  data.id,
                  data.email,
                  `${data.first_name} ${data.last_name}`,
                  data.phone
                );
              }
            } catch (error) {
              console.error(error);
            }

            dispatch({
              type: DATA_AUTH_S,
              payload: {
                data,
              },
            });
            break;
          case 400: // Bad Request
            // Remove token from Local Storage because it was invalid
            removeToken();

            reportException(data, "Could not get user data", "GET users/");

            dispatch({
              type: DATA_AUTH_F,
              payload: {
                errorCode: response.status,
                message: response.statusText,
                error: data,
              },
            });
            break;
          case 401: // Unauthorized
            // Remove token from Local Storage because it was invalid
            removeToken();

            reportException(data, "Could not get user data", "GET users/");

            dispatch({
              type: DATA_AUTH_F,
              payload: {
                errorCode: response.status,
                message: response.statusText,
                error: data,
              },
            });
            break;
          case 403: // Forbidden
            // Remove token from Local Storage because it was invalid
            removeToken();

            reportException(data, "Could not get user data", "GET users/");

            dispatch({
              type: DATA_AUTH_F,
              payload: {
                errorCode: response.status,
                message: response.statusText,
                error: data,
              },
            });
            break;
          default:
            return null;
        }
      })
      .catch((error) => {
        // Remove token from Local Storage because it was invalid
        //removeToken();

        // ATTENTION! POTENTIAL BREAK WHEN SERVER IS DONE (LOOP)

        reportException(error, "Could not get user data", "GET users/");

        dispatch({
          type: DATA_AUTH_F,
          payload: {
            errorCode: 0,
            message: "ERR_CONNECTION_REFUSED",
            error,
          },
        });
      });
  };
};

export const logoutUser = () => {
  return (dispatch, getState) => {
    localStorage.removeItem("token");
    localStorage.removeItem("local-cache");
    removeChat();

    // Remove user from enriched events
    Sentry.configureScope((scope) => scope.setUser(null));
    Sentry.setTag("authenticated", "no");

    dispatch({
      type: LOGOUT_S,
      payload: null,
    });
  };
};
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
