//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTooltip,
} from "mdbreact";

//> CSS
import "../index.scss";

//> Redux
// Connect
import { connect } from "react-redux";
//> Actions
import { assignAdvisor } from "../../../../store/actions/userActions";
//#endregion

//#region > Components
class AssignAdvisor extends React.Component {
  state = {
    modal: false,
    selectedAdvisor: undefined,
  };

  componentDidMount = () => {};

  assignAdvisor = () => {
    const { selectedAdvisor } = this.state;
    const { advisorData } = this.props;

    if (selectedAdvisor && advisorData.user) {
      this.setState({ loading: true }, async () => {
        const res = await this.props.assignAdvisor(
          selectedAdvisor.id,
          advisorData.user.id
        );

        if (res.status) {
          this.setState({ loading: false }, () => this.props.refreshList());
        } else {
          console.error("Can not set new advisor.");
        }
      });
    }
  };

  render() {
    const { loading } = this.state;
    const { data } = this.props;
    const advisors = data[1];

    return (
      <div id="assign-advisor">
        <MDBModal
          isOpen={true}
          toggle={this.props.toggle}
          size="sm"
          className="d-flex flex-center"
          animation="left"
        >
          <MDBModalHeader toggle={this.props.toggle}>
            <p className="lead font-weight-bold">Berater auswählen</p>
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {advisors.map((advisor) => {
              const isActive =
                advisor.email !== this.props.advisorData?.currentAdvisor?.email;

              return (
                <MDBTooltip placement="top" material domElement>
                  <img
                    src={
                      process.env.REACT_APP_MEDIAURL +
                      advisor?.picture +
                      "?token=" +
                      localStorage.getItem("token")
                    }
                    alt={advisor?.first_name ? advisor?.first_name : "Berater"}
                    onClick={() =>
                      this.state.selectedAdvisor?.id === advisor.id
                        ? this.setState({ selectedAdvisor: undefined })
                        : this.setState({ selectedAdvisor: advisor })
                    }
                    className={
                      this.state.selectedAdvisor?.id === advisor.id
                        ? "active"
                        : !isActive
                        ? "disabled"
                        : undefined
                    }
                  />
                  <div>
                    {advisor?.first_name} {advisor?.last_name}
                  </div>
                </MDBTooltip>
              );
            })}
            {this.state.selectedAdvisor && (
              <div className="mt-3">
                <MDBBtn
                  color="blue"
                  onClick={() => this.assignAdvisor()}
                  disabled={loading}
                >
                  {loading && <MDBIcon icon="spinner" className="fa-spin" />}
                  {this.state.selectedAdvisor.first_name}{" "}
                  {this.state.selectedAdvisor.last_name} zuweisen
                </MDBBtn>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => ({
  data: state.auth.data,
});

const mapDispatchToProps = {
  assignAdvisor,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(AssignAdvisor);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
