//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> Animations
import { animations } from "react-animation";
//> File upload
import Dropzone from "react-dropzone";
//> Time
import moment from "moment";
//> Redux
// Connect
import { connect } from "react-redux";
//> Lottie
import Lottie from "lottie-react-web";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBInput,
  MDBView,
  MDBMask,
  MDBTooltip,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBModalHeader,
  MDBListGroup,
  MDBListGroupItem,
} from "mdbreact";

//> Images
import profileIMG from "../../../../assets/content/profile.gif";
//> Helpers
import { escapeHtml } from "../../../../helpers/escape";
//> Actions
import {
  createDamageReport,
  getDamageReportData,
  sendMessageToDamageReport,
} from "../../../../store/actions/userActions";
//> Lottie
import pdfAnimationFile from "../../../../assets/lottie/heart.json";
//> CSS
import "../index.scss";
import DamageReport from "../DamageReport";
//#endregion

//#region > Config
const heartAnimation = {
  loop: false,
  autoplay: true,
  animationData: pdfAnimationFile,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
//#endregion

//#region > Components
class DamageReportList extends React.Component {
  state = {
    selectedFiles: [],
    selectedOption: undefined,
    modal: false,
    modal2: false,
    note: "",
    options: [
      {
        text: "Haushaltsversicherung",
        value: "haushaltsversicherung",
      },
    ],
    localMsg: [],
  };

  componentDidMount = () => {
    const insurances = this.props.data?.insurances;

    if (insurances) {
      const activeInsurances = insurances.filter(
        (insurance) => insurance.status.active === true
      );

      this.setState({
        options: activeInsurances.map((ins) => {
          return {
            text: ins.insurance + " - " + ins.policy_id,
            value: ins.policy_id,
          };
        }),
      });
    }
  };

  toggleModal = () => {
    if (!this.state.modal) {
      this.setState({
        modal: !this.state.modal,
      });
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };

  toggleModal2 = () => {
    if (!this.state.modal2) {
      this.setState({
        modal2: !this.state.modal2,
      });
    } else {
      this.setState({
        modal2: !this.state.modal2,
        damageReportInfos: undefined,
      });
    }
  };

  getDrop = (files) => {
    const res = files.map((file) => {
      return {
        data: file,
        preview: URL.createObjectURL(file),
      };
    });

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...res],
      status: "",
    });
  };

  removeDrop = (name) => {
    const res = this.state.selectedFiles.filter(
      (file) => file.data.name !== name
    );

    this.setState({
      selectedFiles: res,
    });
  };

  sendFiles = async () => {
    const { selectedFiles, note, selectedOption } = this.state;

    if (note && selectedOption && selectedOption.length > 0) {
      let files = undefined;

      if (selectedFiles.length > 0) {
        files = selectedFiles.map((file) => file.data);
      }

      const res = await this.props.createDamageReport(
        selectedOption[0],
        note,
        files
      );

      this.setState({
        response: res,
      });
    }
  };

  getData = async (id) => {
    const res = await this.props.getDamageReportData(id);

    if (res.status) {
      this.setState({
        damageReportInfos: res.data,
        damageReportMessages: res.data.messages.reverse(),
        modal2: true,
      });
    }
  };

  sendMsg = async () => {
    const { msg, damageReportInfos, selectedFiles } = this.state;

    const images = selectedFiles.map((file) => file.data);

    if (msg && damageReportInfos) {
      const res = await this.props.sendMessageToDamageReport(
        damageReportInfos.id,
        msg,
        images
      );

      if (res.status) {
        this.setState({
          localMsg: [
            { msg: res.data?.message, images: this.state.selectedFiles },
            ...this.state.localMsg,
          ],
          selectedFiles: [],
          msg: "",
        });
      } else {
        this.setState({
          error: res.data,
        });
      }
    }
  };

  render() {
    const { data, isNav } = this.props;
    const { damageReportInfos, damageReportMessages } = this.state;

    const styleFadeIn = {
      animation: animations.fadeIn,
    };

    return (
      <div id="damagereportlist">
        {this.props.data &&
          this.props.data.insurances &&
          this.props.data.insurances.length > 0 && (
            <MDBTooltip placement="bottom" material>
              <MDBBtn
                color={isNav ? "darkblue" : "blue"}
                size="md"
                onClick={this.toggleModal}
                className={isNav ? "inbox nav-btn" : "m-1"}
              >
                {isNav ? (
                  <div>
                    <MDBIcon icon="fire" className="m-0 px-1" />
                    {this.props.data.damagereports &&
                      this.props.data.damagereports.filter(
                        (rep) => rep.status === "o"
                      ).length > 0 && (
                        <MDBBadge
                          pill
                          color="orange"
                          className="ml-2 z-depth-0"
                          style={styleFadeIn}
                        >
                          {
                            this.props.data.damagereports.filter(
                              (rep) => rep.status === "o"
                            ).length
                          }
                        </MDBBadge>
                      )}
                  </div>
                ) : (
                  <div>
                    <MDBIcon icon="fire" className="m-0 px-1" />
                    Schadensmeldungen
                    {this.props.data.damagereports &&
                      this.props.data.damagereports.filter(
                        (rep) => rep.status === "o"
                      ).length > 0 && (
                        <MDBBadge
                          pill
                          color="orange"
                          className="ml-2 z-depth-0"
                          style={styleFadeIn}
                        >
                          {
                            this.props.data.damagereports.filter(
                              (rep) => rep.status === "o"
                            ).length
                          }
                        </MDBBadge>
                      )}
                  </div>
                )}
              </MDBBtn>
              {isNav ? (
                <div>Schadensmeldungen</div>
              ) : (
                <div className="d-none"></div>
              )}
            </MDBTooltip>
          )}
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          size="md"
          animation="left"
          className="full-height"
          fullHeight
          position="right"
        >
          <MDBModalHeader toggle={this.toggleModal}>
            Schadensmeldungen
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {data && data.damagereports && data.damagereports.length > 0 ? (
              <MDBListGroup>
                {data.damagereports.map((report) => {
                  return (
                    <MDBListGroupItem
                      className="d-flex justify-content-between"
                      onClick={() => this.getData(report.id)}
                      key={"report-" + report.id}
                    >
                      <div>
                        <p className="mb-0">{report.policy.name}</p>
                        <span className="text-muted small">
                          ({report.policy.policy_id})
                        </span>
                      </div>
                      <div className="small">
                        {(() => {
                          switch (report.status) {
                            case "c":
                              return (
                                <span className="text-muted">Geschlossen</span>
                              );
                            case "o":
                              return <span className="text-danger">Offen</span>;
                            case "w":
                              return <span className="text-danger">Offen</span>;
                            default:
                              break;
                          }
                        })()}
                      </div>
                    </MDBListGroupItem>
                  );
                })}
              </MDBListGroup>
            ) : (
              <div className="p-4">
                <div className="animation-container pt-2 pb-4">
                  <Lottie options={heartAnimation} speed={2.5} ariaRole="img" />
                </div>
                <p className="text-muted mb-0">
                  Es liegen noch keine Schadensmeldungen vor.
                </p>
                <p className="text-muted">
                  Wir hoffen, dass es auch so bleibt.
                </p>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
        {damageReportInfos && this.state.modal2 && (
          <MDBModal
            isOpen={this.state.modal2}
            toggle={this.toggleModal2}
            size="lg"
            animation="left"
            id="modal-chat"
          >
            <MDBModalHeader toggle={this.toggleModal2}>
              {damageReportInfos.policy_name}
            </MDBModalHeader>
            <MDBModalBody className="text-left p-3">
              {damageReportInfos &&
              (damageReportInfos.status === "o" ||
                damageReportInfos.status === "w") ? (
                <>
                  {this.state.answerMsg ? (
                    <div className="mt-4">
                      <p className="mb-2 font-weight-bold">
                        Antwort an {data.advisor.first_name}{" "}
                        {data.advisor.last_name} senden
                      </p>
                      <MDBInput
                        type="textarea"
                        rows="5"
                        outline
                        containerClass="mt-0 mb-0"
                        value={this.state.msg}
                        getValue={(val) => this.setState({ msg: val })}
                      />
                      {this.state.error && (
                        <span className="text-danger small">
                          Du musst noch{" "}
                          {(
                            Math.round(this.state.error.time_remaining * 100) /
                            100
                          ).toFixed(0)}{" "}
                          Sekunden warten bevor Du eine weitere Nachricht senden
                          kannst.
                        </span>
                      )}
                      <p className="text-muted mb-1 mt-3">
                        Dateien anfügen (optional)
                      </p>
                      <Dropzone
                        onDrop={(acceptedFiles) => this.getDrop(acceptedFiles)}
                        //onDropAccepted={() => this.setState({ status: "accept" })}
                        onDropRejected={() =>
                          this.setState({ status: "reject" })
                        }
                        onDragOver={() => this.setState({ status: "active" })}
                        onDragLeave={() => this.setState({ status: "" })}
                        accept="image/jpeg, image/png"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className={"wrapper " + this.state.status}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <p>
                                Ziehe Dateien hierher oder klicke auf das Feld,
                                um Dateien auszuwählen
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <aside className="thumbs-container mb-3">
                        {this.state.selectedFiles.length > 0 &&
                          this.state.selectedFiles.map((file) => {
                            return (
                              <div className="thumb" key={file.data.name}>
                                <div className="thumb-inner">
                                  <MDBView
                                    onClick={() =>
                                      this.removeDrop(file.data.name)
                                    }
                                  >
                                    <img
                                      src={file.preview}
                                      alt="File preview"
                                    />
                                    <MDBMask
                                      className="d-flex flex-center"
                                      overlay="black-slight"
                                    >
                                      <MDBIcon
                                        far
                                        icon="trash-alt"
                                        className="text-white"
                                      />
                                    </MDBMask>
                                  </MDBView>
                                </div>
                              </div>
                            );
                          })}
                      </aside>
                      <div className="text-right">
                        <MDBBtn
                          color="blue"
                          disabled={!this.state.msg}
                          onClick={this.sendMsg}
                        >
                          <MDBIcon far icon="paper-plane" />
                          Senden
                        </MDBBtn>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <MDBBtn
                        color="blue"
                        onClick={() => this.setState({ answerMsg: true })}
                      >
                        <MDBIcon icon="reply" />
                        Antwort senden
                      </MDBBtn>
                    </div>
                  )}
                </>
              ) : (
                <MDBAlert color="info">
                  Diese Schadensmeldung wurde als fertiggestellt markiert. Du
                  kannst daher nicht mehr auf diese antworten.
                </MDBAlert>
              )}
              <p className="mb-2 font-weight-bold mb-3">Dein Chatverlauf</p>
              <div className="messages">
                {this.state.localMsg &&
                  this.state.localMsg.map((msg, m) => {
                    const images =
                      msg.images &&
                      msg.images.map((img) => {
                        return {
                          src: img.preview,
                        };
                      });

                    return (
                      <div className="d-flex msg">
                        <div className="msg-img">
                          <img src={profileIMG} alt={"Profile image " + m} />
                        </div>
                        <div>
                          <p className="font-weight-bold mb-0">Du</p>
                          <p className="mb-2 small text-muted">
                            Vor einigen Sekunden
                          </p>
                          {msg.msg.includes("DAMAGEREPORT") ? (
                            <>
                              {msg.msg.includes(
                                "DAMAGEREPORTCLOSEDNOWMESSAGE"
                              ) && (
                                <p>Die Schadensmeldung wurde geschlossen.</p>
                              )}
                              {msg.msg.includes(
                                "DAMAGEREPORTOPENEDNOWMESSAGE"
                              ) && (
                                <p>
                                  Die Schadensmeldung wurde wieder geöffnet.
                                </p>
                              )}
                            </>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: escapeHtml(msg.msg).replace(
                                  /\n/g,
                                  "<br/>"
                                ),
                              }}
                            ></p>
                          )}
                          {images && images.length > 0 && (
                            <MDBRow>
                              {images.map((img) => (
                                <MDBCol lg="4">
                                  <img src={img.src} className="img-fluid" />
                                  <p>
                                    <a
                                      href={img.src}
                                      className="small"
                                      target="_blank"
                                    >
                                      Bild herunterladen
                                    </a>
                                  </p>
                                </MDBCol>
                              ))}
                            </MDBRow>
                          )}
                        </div>
                      </div>
                    );
                  })}
                {damageReportMessages &&
                  damageReportMessages.length > 0 &&
                  damageReportMessages.map((msg, i) => {
                    const images =
                      msg.images &&
                      msg.images.map((img) => {
                        return {
                          src:
                            process.env.REACT_APP_MEDIAURL +
                            img.url +
                            "?token=" +
                            localStorage.getItem("token"),
                        };
                      });

                    return (
                      <div className="d-flex msg" key={"message-" + i}>
                        <div className="msg-img">
                          <img
                            src={
                              msg.sender.picture
                                ? process.env.REACT_APP_MEDIAURL +
                                  msg.sender.picture
                                : profileIMG
                            }
                            alt={msg.sender.name}
                          />
                        </div>
                        <div>
                          <p className="font-weight-bold mb-0">
                            {msg.sender.creator ? "Du" : msg.sender.name}
                          </p>
                          <p className="mb-2 small text-muted">
                            {moment(msg.date).fromNow()}
                          </p>
                          {msg.body.includes("DAMAGEREPORT") ? (
                            <>
                              {msg.body.includes(
                                "DAMAGEREPORTCLOSEDNOWMESSAGE"
                              ) && (
                                <p>Die Schadensmeldung wurde geschlossen.</p>
                              )}
                              {msg.body.includes(
                                "DAMAGEREPORTOPENEDNOWMESSAGE"
                              ) && (
                                <p>
                                  Die Schadensmeldung wurde wieder geöffnet.
                                </p>
                              )}
                            </>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: escapeHtml(msg.body).replace(
                                  /\n/g,
                                  "<br/>"
                                ),
                              }}
                            ></p>
                          )}
                          {images && images.length > 0 && (
                            <MDBRow>
                              {images.map((img) => (
                                <MDBCol lg="4">
                                  <img src={img.src} className="img-fluid" />
                                  <p>
                                    <a
                                      href={img.src}
                                      className="small"
                                      target="_blank"
                                    >
                                      Bild herunterladen
                                    </a>
                                  </p>
                                </MDBCol>
                              ))}
                            </MDBRow>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </MDBModalBody>
          </MDBModal>
        )}
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  createDamageReport,
  getDamageReportData,
  sendMessageToDamageReport,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(DamageReportList);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
