//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBInput,
  MDBRow,
  MDBCol,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import {
  requestSignature,
  getSignatureProgress,
  signDocument,
  callToken,
  requestSignDocument,
  addPaymentData,
} from "../../../../store/actions/signatureActions";
//> CSS
import "../index.scss";
//> Components
import PDFSignatureView from "../../../molecules/modules/PDFSignatureView";
//#endregion

//#region > Components
class SignatureModal extends React.Component {
  state = { modal: true, numPages: null, pageNumber: 1 };

  componentDidMount = async () => {
    // Refresh token if not payment data
    if (this.props.signDoc !== "payment") {
      // Get the first time
      this.getSignToken();

      // Repeat every 30 seconds
      this.refreshInterval = window.setInterval(this.getSignToken, 30000);
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.refreshInterval);
    clearInterval(this.getUpdateInterval);
  };

  stopProgress = (forceNew, paymentId) => {
    clearInterval(this.getUpdateInterval);

    this.getUpdateInterval = undefined;

    if (forceNew) {
      this.forceNewToken(paymentId);
    }
  };

  getSignToken = async () => {
    const { signDoc } = this.props;

    if (signDoc) {
      const { id } = signDoc;

      const signToken = await this.props.requestSignature(id);

      if (signToken?.data?.token) {
        this.setState({
          signToken: signToken?.data?.token,
        });
      }
    }
  };

  forceNewToken = async (paymentId) => {
    const { signDoc } = this.props;

    if (paymentId) {
      // Force new token
      const signToken = await this.props.requestSignature(paymentId, true);

      if (signToken?.data?.token) {
        this.setState({
          signToken: undefined,
          calledMobile: false,
        });
      }
    } else {
      if (signDoc) {
        const { id } = signDoc;

        // Force new token
        const signToken = await this.props.requestSignature(id, true);

        if (signToken?.data?.token) {
          this.setState({
            signToken: signToken?.data?.token,
            calledMobile: false,
          });
        }
      }
    }
  };

  // Activate progress for desktop use
  callToken = (paymentToken) => {
    const { signToken } = this.state;

    if (paymentToken) {
      this.refreshInterval && clearInterval(this.refreshInterval);

      this.props.callToken(paymentToken);

      // Get current status of sign process
      this.getUpdateInterval = window.setInterval(
        () => this.getStatus(paymentToken),
        2000
      );
    } else {
      if (signToken) {
        this.refreshInterval && clearInterval(this.refreshInterval);

        this.props.callToken(signToken);

        // Get current status of sign process
        this.getUpdateInterval = window.setInterval(
          () => this.getStatus(this.state.signToken),
          2000
        );
      }
    }
  };

  // Get status
  getStatus = async () => {
    const signToken = this.state.signToken;

    const res = await this.props.getSignatureProgress(signToken);

    // If automatic get progress not active yet
    if (!this.getUpdateInterval) {
      this.getUpdateInterval = window.setInterval(() => this.getStatus(), 2000);
    }

    if (res.status) {
      // Everything running nicely
      if (res.data.called && !this.state.calledMobile) {
        this.setState({
          calledMobile: true,
        });
      }

      if (res.data.called && res.data.signed && !this.state.isFinished) {
        this.setState(
          {
            isFinished: true,
          },
          () => this.stopProgress(false)
        );
      }
    } else {
      // Token not valid anymore or token not used yet

      console.error("TOKEN INVALID");
    }
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  getSignature = (signature) => {
    if (signature) {
      this.setState(
        {
          signatureImage: signature,
          loading: true,
        },
        async () => {
          const res = await this.props.signDocument(
            signature,
            this.state.signToken
          );

          // Call progress one more time for token to be yeeted
          this.getStatus();

          this.stopProgress(false);
        }
      );
    }
  };

  getPaymentSignature = (signatureData) => {
    if (signatureData) {
      this.setState(
        {
          signatureImage: signatureData.signature,
          loading: true,
        },
        async () => {
          const res = await this.props.signDocument(
            signatureData.signature,
            signatureData.token
          );

          if (res) {
            this.setState(
              {
                error: undefined,
                loading: false,
              },
              () => {
                this.props.addPaymentData(
                  signatureData.payment,
                  signatureData.documentId
                );

                // Call progress one more time for token to be yeeted
                this.getStatus();

                this.stopProgress(false);
              }
            );
          } else {
            this.setState({
              loading: false,
              error: {
                position: "SIGN",
                message:
                  "Die Zeit zum unterschreiben ist abgelaufen. Bitte starte den Prozess erneut.",
              },
            });
          }
        }
      );
    }
  };

  render() {
    const { getSignaturePosition, pdf, signDoc } = this.props;

    const isPayment = this.props.signDoc === "payment";

    return (
      <div id="signatureModal">
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.props.toggleModal}
          size="lg"
          animation="left"
          className="flex-center"
        >
          <MDBModalHeader toggle={this.props.toggleModal}>
            <p className="lead font-weight-bold">Unterzeichnen</p>
          </MDBModalHeader>
          <MDBModalBody>
            {isPayment ? (
              <span className="text-muted mb-3 d-block text-center">
                Durch das SEPA Lastschriftmandat können wir bequem und
                automatisch die Versicherungsprämie von dem angegebenen Konto
                abbuchen.
              </span>
            ) : (
              <span className="text-muted mb-3 d-block text-center">
                Das Dokument ist auch nach dem Unterzeichnen jederzeit für dich
                in der Versicherungsübersicht abrufbar.
              </span>
            )}
            {this.state.error?.position === "SIGN" ? (
              <div>
                <MDBAlert color="danger">
                  <p>{this.state.error.message}</p>
                  <MDBBtn
                    color="white"
                    size="md"
                    onClick={this.props.toggleModal}
                  >
                    Schließen
                  </MDBBtn>
                </MDBAlert>
              </div>
            ) : (
              <PDFSignatureView
                url={
                  pdf ??
                  process.env.REACT_APP_MEDIAURL +
                    signDoc?.template +
                    "?token=" +
                    localStorage.getItem("token")
                }
                token={this.state.signToken ?? null}
                getSignature={this.getSignature}
                getStatus={this.getStatus}
                stopProgress={this.stopProgress}
                getSignaturePosition={getSignaturePosition}
                isFinished={this.state.isFinished ?? false}
                toggleModal={this.props.toggleModal}
                closeSession={this.props.toggleModal}
                callToken={this.callToken}
                next={this.props.next}
                calledMobile={this.state.calledMobile ?? false}
                nextAvailable={this.props.nextAvailable}
                isPayment={isPayment}
                insuranceId={this.props.insuranceId}
                getPaymentSignature={this.getPaymentSignature}
              />
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapDispatchToProps = {
  requestSignature,
  getSignatureProgress,
  signDocument,
  callToken,
  requestSignDocument,
  addPaymentData,
};
//#endregion

//#region > Exports
export default connect(null, mapDispatchToProps)(SignatureModal);
//#endregion

/*
  SPDX-License-Identifier: (EUPL-1.2)
  Copyright © 2021 InspireMedia GmbH
 */
