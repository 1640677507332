//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> File upload
import Dropzone from "react-dropzone";
//> Time
import moment from "moment";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBListGroupItem,
  MDBListGroup,
  MDBModalHeader,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBMask,
  MDBView,
  MDBInput,
  MDBAlert,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> Actions
import {
  getInsurances,
  uploadTemplate,
  uploadDoc,
} from "../../../../store/actions/userActions";
//> Components
import { StatusSymbols } from "../../../atoms";
//> CSS
import "../index.scss";
import { SignatureModal } from "..";
//#endregion

//#region > Components
class InsuranceList extends React.Component {
  state = { selectInsurance: true, selectedFiles: [], needsSignature: true };

  getDrop = (files) => {
    const res = files.map((file) => {
      return {
        data: file,
        preview: URL.createObjectURL(file),
      };
    });

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...res],
      status: "",
    });
  };

  removeDrop = (name) => {
    const res = this.state.selectedFiles.filter(
      (file) => file.data.name !== name
    );

    this.setState({
      selectedFiles: res,
    });
  };

  uploadTemplate = async () => {
    const { selectedFiles, selectedInsurance } = this.state;
    const files = selectedFiles.map((file) => file.data);

    const res = await this.props.uploadTemplate(selectedInsurance.id, files);

    if (res.status) {
      this.props.closeInsuranceView();
    }
  };

  uploadSingleTemplate = async () => {
    const { selectedFiles, selectedInsurance } = this.state;

    selectedFiles.map(async (file, f) => {
      console.log("FILE", file);

      // Check if file needs signature
      const needsSignature = this.state["needsSignature-" + f] ? true : false;
      // Get signature position
      const signaturePosition = this.state["signaturePosition-" + f];

      console.log(needsSignature, signaturePosition);

      const fileObject = {
        title: `Angebotsdatei ${selectedInsurance.insurance}`,
        description:
          "Wir haben das Angebot individuell an deine Anforderungen angepasst.",
        template: file?.data,
        pos_x: needsSignature
          ? signaturePosition?.left.percent / 100 ?? null
          : null,
        pos_y: needsSignature
          ? signaturePosition?.top.percent / 100 ?? null
          : null,
        page_index: needsSignature
          ? signaturePosition?.pageIndex ?? null
          : null,
        id: null,
        remove: null,
      };

      console.log(fileObject);

      const res = await this.props.uploadTemplate(
        selectedInsurance.id,
        fileObject
      );

      if (res.status) {
        this.props.closeInsuranceView();
      }
    });
  };

  uploadDoc = async (insurance) => {
    const { selectedFiles } = this.state;
    const files = selectedFiles.map((file) => file.data);

    const res = await this.props.uploadDoc(
      insurance.id,
      files[0],
      this.state.policy_id
    );

    if (res.status) {
      this.props.closeInsuranceView();
    }
  };

  render() {
    const { user, insuranceList } = this.props;

    let needsSignature = false,
      needsSignatureCount = [],
      gotSignatures = false,
      gotSignaturesCount = [];

    needsSignature === false &&
      this.state.selectedFiles.forEach((_, f) => {
        if (this.state["needsSignature-" + f]) {
          needsSignature = true;
          needsSignatureCount = [...needsSignatureCount, f];
        }

        if (this.state["signaturePosition-" + f]) {
          gotSignatures = true;
          gotSignaturesCount = [...gotSignaturesCount, f];
        }
      });

    console.log(
      "NEEDS",
      needsSignature,
      gotSignatures,
      needsSignatureCount,
      gotSignaturesCount
    );

    console.log(
      needsSignature && !gotSignatures && this.state.selectedFiles.length > 0
    );

    return (
      <div className="insurancelist-modal">
        <MDBModal
          isOpen={this.state.selectInsurance}
          toggle={this.props.closeInsuranceView}
          size="md"
          className="d-flex flex-center"
          animation="left"
          id="chat"
        >
          <MDBModalHeader toggle={this.props.closeInsuranceView}>
            <p className="lead font-weight-bold mb-0">
              Versicherungen von {user.first_name} {user.last_name}
            </p>
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <MDBListGroup>
              {insuranceList.map((insurance) => {
                return (
                  <MDBListGroupItem
                    key={"insuranceItem-" + insurance.id}
                    className="text-left clickable"
                    onClick={() => {
                      this.setState({
                        selectInsurance: false,
                        selectedInsurance:
                          insurance.status.status === 0 ? insurance : undefined,
                        customerAnswer:
                          insurance.status.status === 2 ||
                          insurance.status.status === 1 ||
                          insurance.status.status === 3
                            ? insurance
                            : undefined,
                      });
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="font-weight-bold mb-1">
                          {insurance?.insurance}
                        </p>
                        <p className="text-muted small mb-0">
                          Polizze:{" "}
                          {insurance.policy_id
                            ? insurance.policy_id
                            : "Ausstehend"}
                        </p>
                        <p className="text-muted small">
                          Eingang:{" "}
                          {moment(insurance.date).format("DD.MM.YYYY HH:mm")}
                        </p>
                      </div>
                      <div className="text-right">
                        {insurance?.status.active ? (
                          <>
                            <p className="text-success small">
                              <MDBIcon
                                far
                                icon="check-circle"
                                className="mr-2"
                              />
                              Aktiv
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="blue-text small font-weight-bold">
                              <MDBIcon far icon="circle" className="mr-2" />
                              Offen
                            </p>
                            {insurance?.status.status === 0 && true && (
                              <p className="text-danger small">
                                Kunde wartet auf Angebot
                              </p>
                            )}
                            {insurance?.status.status === 1 && true && (
                              <p className="text-muted small">
                                Auf Kundenunterschrift warten
                              </p>
                            )}
                            {insurance?.status.status === 2 && (
                              <p className="text-danger small">
                                Kunde wartet auf Polizze
                              </p>
                            )}
                            {insurance?.status.status === 6 && (
                              <p className="text-muted small">
                                <MDBIcon
                                  far
                                  icon="times-circle"
                                  className="mr-2"
                                />
                                Abgelehnt
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </MDBListGroupItem>
                );
              })}
            </MDBListGroup>
          </MDBModalBody>
        </MDBModal>
        {this.state.selectedInsurance && (
          <MDBModal
            isOpen={this.state.selectedInsurance && !this.state.addDocuments}
            toggle={() =>
              this.setState(
                {
                  selectedInsurance: undefined,
                  selectInsurance: true,
                },
                () => this.props.closeInsuranceView()
              )
            }
            size="lg"
            className="d-flex flex-center"
            animation="left"
            id="chat"
          >
            <MDBModalHeader
              toggle={() =>
                this.setState(
                  {
                    selectedInsurance: undefined,
                    selectInsurance: true,
                  },
                  () => this.props.closeInsuranceView()
                )
              }
            >
              <p className="lead font-weight-bold mb-0">
                {this.state.selectedInsurance.insurance}
              </p>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              <div className="text-left">
                <div>
                  <p className="font-weight-bold mb-0 d-inline-block">
                    Antragsteller: {this.state.selectedInsurance.submitter}
                  </p>
                  <StatusSymbols
                    verified={user.verified}
                    document={user.id_document}
                    inline
                  />
                </div>
                <p className="text-muted mb-2">
                  Antragszeitpunkt:{" "}
                  {moment(this.state.selectedInsurance.date).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </p>
                {this.state.selectedInsurance.data[0]?.provider_id ===
                  "household_vav_exklusiv" && (
                  <img
                    src="https://www.ivva.at/wp-content/uploads/2011/02/logo-vav-small-300x149.jpg"
                    alt="Agency Logo 1"
                    style={{ maxHeight: "50px" }}
                    className="my-2"
                  />
                )}
                {this.state.selectedInsurance.data[0]?.provider_id ===
                  "household_wien_extra" && (
                  <img
                    src="https://www.wienerstaedtische.at/fileadmin/user_upload/Bilderpool/Unternehmen/Presse/Mediathek/Logo/Logo_Wiener_Staedtische_4c.jpg"
                    alt="Agency Logo 2"
                    style={{ maxHeight: "50px" }}
                    className="my-2"
                  />
                )}
                {this.state.showParams ? (
                  <p
                    className="blue-text py-2 clickable"
                    onClick={() => this.setState({ showParams: false })}
                  >
                    <MDBIcon far icon="eye-slash" className="mr-2" />
                    Parameter verstecken
                  </p>
                ) : (
                  <p
                    className="blue-text py-2 clickable"
                    onClick={() => this.setState({ showParams: true })}
                  >
                    <MDBIcon far icon="eye" className="mr-2" />
                    Parameter anzeigen
                  </p>
                )}
                {this.state.showParams && (
                  <MDBTable striped>
                    <MDBTableHead>
                      <tr>
                        <th>Frage</th>
                        <th>Antwort</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td>Zahlungsintervall</td>
                        <td>
                          {
                            this.state.selectedInsurance.data[0]
                              .payment_interval
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Preisvorschlag</td>
                        <td>
                          {Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(this.state.selectedInsurance.data[0].price)}
                        </td>
                      </tr>
                      {this.state.selectedInsurance &&
                        this.state.selectedInsurance.options.map(
                          (element, e) => {
                            return (
                              <tr>
                                <td>{element.field_title}</td>
                                <td>
                                  {this.state.selectedInsurance.data[1].map(
                                    (elem) => {
                                      if (
                                        element.field_name === elem.field_name
                                      ) {
                                        return element?.field_type === "number"
                                          ? elem.field_content
                                          : element.options
                                          ? element.options[
                                              elem.field_content
                                            ] ?? "/"
                                          : "no options";
                                      }
                                    }
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </MDBTableBody>
                  </MDBTable>
                )}
                <p className="lead font-weight-bold mt-3">Angebot anfügen</p>
                <p className="text-muted mb-3">
                  Füge das Angebot bzw. den Antrag für den Kunden an.
                </p>
                <Dropzone
                  onDrop={(acceptedFiles) => this.getDrop(acceptedFiles)}
                  //onDropAccepted={() => this.setState({ status: "accept" })}
                  onDropRejected={() => this.setState({ status: "reject" })}
                  onDragOver={() => this.setState({ status: "active" })}
                  onDragLeave={() => this.setState({ status: "" })}
                  accept="application/pdf"
                  className="clickable"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className={"wrapper " + this.state.status}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <p>
                          Ziehe Dateien hierher oder klicke auf das Feld, um
                          Dateien auszuwählen
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {this.state.selectedFiles.length > 0 && (
                  <>
                    {this.state.selectedFiles.map((file, i) => {
                      if (file.data?.type === "application/pdf") {
                        return (
                          <>
                            <div className="border rounded p-3 my-2">
                              <p className="text-muted small mb-1">
                                Ausgewählte Datei
                              </p>
                              <aside className="pdf-container">
                                <div key={file.data.name}>
                                  <MDBIcon
                                    icon="trash-alt"
                                    far
                                    className="mr-2 text-danger clickable p-1"
                                    onClick={() =>
                                      this.removeDrop(file.data.name)
                                    }
                                  />
                                  <a href={file.preview} target="_blank">
                                    {file.data.name}
                                    {this.state["signaturePosition-" + i] && (
                                      <MDBIcon
                                        icon="signature"
                                        className="ml-2 text-success"
                                      />
                                    )}
                                  </a>
                                </div>
                              </aside>
                              <MDBInput
                                type="checkbox"
                                onChange={(e) =>
                                  this.setState({
                                    ["needsSignature-" + i]: e.target.checked,
                                  })
                                }
                                id={"needsSignature+" + i}
                                checked={this.state["needsSignature-" + i]}
                                label="Das Dokument benötigt eine Kunden-Unterschrift"
                                containerClass="mt-4 pl-1"
                              />
                              {this.state["signaturePosition-" + i] && (
                                <MDBAlert
                                  color="success"
                                  className="mt-4 mb-0 d-flex justify-content-between"
                                >
                                  <div>
                                    <MDBIcon
                                      icon="signature"
                                      className="mr-2"
                                    />
                                    Die Unterschrift-Position wurde gesetzt.
                                  </div>
                                  <div>
                                    <span
                                      className="text-muted clickable"
                                      onClick={() =>
                                        this.setState({
                                          ["signaturePosition-" + i]: undefined,
                                        })
                                      }
                                    >
                                      Entfernen
                                    </span>
                                  </div>
                                </MDBAlert>
                              )}
                            </div>
                            {this.state.getSignaturePosition &&
                              !gotSignaturesCount.includes(i) &&
                              needsSignature &&
                              needsSignatureCount.length > 0 &&
                              needsSignatureCount.includes(i) && (
                                <SignatureModal
                                  getSignaturePosition={(pos) =>
                                    this.setState({
                                      ["signaturePosition-" + i]: pos,
                                    })
                                  }
                                  pdf={this.state.selectedFiles[i].preview}
                                  toggleModal={() =>
                                    this.setState({
                                      getSignaturePosition:
                                        needsSignatureCount.filter(
                                          (item) => item !== i
                                        ).length > 0
                                          ? true
                                          : false,
                                    })
                                  }
                                />
                              )}
                          </>
                        );
                      }
                    })}
                  </>
                )}
                <MDBRow className="mt-4">
                  <MDBCol lg="6" className="text-left">
                    <MDBBtn
                      color="grey"
                      onClick={() =>
                        this.setState({
                          selectedInsurance: undefined,
                          selectInsurance: true,
                        })
                      }
                    >
                      <MDBIcon icon="angle-left" />
                      Zurück
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol lg="6" className="text-right">
                    {needsSignature &&
                    !gotSignatures &&
                    this.state.selectedFiles.length > 0 ? (
                      <MDBBtn
                        color="orange"
                        disabled={this.state.selectedFiles.length === 0}
                        onClick={() =>
                          this.setState({ getSignaturePosition: true }, () =>
                            window.scrollTo(0, 0)
                          )
                        }
                      >
                        <MDBIcon icon="signature" />
                        Unterschrift
                        {this.state.selectedFiles.length > 1 && "en"} platzieren
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        color="blue"
                        disabled={
                          this.state.selectedFiles.length === 0 ||
                          gotSignaturesCount.length !==
                            needsSignatureCount.length
                        }
                        onClick={this.uploadSingleTemplate}
                      >
                        <MDBIcon icon="file-contract" />
                        Angebot senden
                      </MDBBtn>
                    )}
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBModalBody>
          </MDBModal>
        )}
        {this.state.customerAnswer && (
          <MDBModal
            isOpen={this.state.customerAnswer}
            toggle={() =>
              this.setState({ customerAnswer: undefined }, () =>
                this.props.closeInsuranceView()
              )
            }
            size="lg"
            className="d-flex flex-center"
            animation="left"
            id="chat"
          >
            <MDBModalHeader
              toggle={() =>
                this.setState({ customerAnswer: undefined }, () =>
                  this.props.closeInsuranceView()
                )
              }
            >
              <p className="lead font-weight-bold mb-0">
                {this.state.customerAnswer.insurance}
              </p>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              {this.state.customerAnswer.status.status === 1 ? (
                <div className="my-5">
                  <p className="lead mb-1">
                    Du hast das Angebot bereits unterbreitet.
                  </p>
                  <p className="text-muted">
                    Es wird auf die Kundenantwort gewartet.
                  </p>
                </div>
              ) : (
                <>
                  {this.state.customerAnswer.status.active ? (
                    <div className="text-left">
                      <p className="lead font-weight-bold">Kunden Upload</p>
                      <p className="mb-1 text-muted">
                        Der Kunde hat folgende Dokuemnte hochgeladen.
                      </p>
                      <MDBListGroup className="mb-4">
                        {this.state.customerAnswer?.submission_documents?.map(
                          (doc) => {
                            if (doc.document) {
                              return (
                                <a
                                  href={
                                    process.env.REACT_APP_MEDIAURL +
                                    doc.document +
                                    "?token=" +
                                    localStorage.getItem("token")
                                  }
                                  target="_blank"
                                >
                                  <MDBListGroupItem>
                                    <p className="lead text-dark">
                                      {doc.title}
                                    </p>
                                    <p className="text-dark">
                                      {doc.description}
                                    </p>
                                  </MDBListGroupItem>
                                </a>
                              );
                            }
                          }
                        )}
                      </MDBListGroup>
                      <hr />
                      <p className="mb-1">Polizze</p>
                      <MDBListGroup className="mb-3">
                        <a
                          href={
                            process.env.REACT_APP_MEDIAURL +
                            this.state.customerAnswer.document +
                            "?token=" +
                            localStorage.getItem("token")
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <MDBListGroupItem className="text-dark">
                            <MDBIcon far icon="file-pdf" className="mr-2" />
                            <span className="small">
                              {
                                this.state.customerAnswer.document.split("/")[
                                  this.state.customerAnswer.document.split("/")
                                    .length - 1
                                ]
                              }
                            </span>
                          </MDBListGroupItem>
                        </a>
                      </MDBListGroup>
                    </div>
                  ) : (
                    <div className="text-left">
                      <p className="lead font-weight-bold">Kunden Upload</p>
                      <p className="mb-1 text-muted">
                        Der Kunde hat folgende Dokuemnte hochgeladen.
                      </p>
                      <MDBListGroup className="mb-4">
                        {this.state.customerAnswer?.submission_documents?.map(
                          (doc) => {
                            if (doc.document) {
                              return (
                                <a
                                  href={
                                    process.env.REACT_APP_MEDIAURL +
                                    doc.document +
                                    "?token=" +
                                    localStorage.getItem("token")
                                  }
                                  target="_blank"
                                >
                                  <MDBListGroupItem>
                                    <p className="lead text-dark">
                                      {doc.title}
                                    </p>
                                    <p className="text-dark">
                                      {doc.description}
                                    </p>
                                  </MDBListGroupItem>
                                </a>
                              );
                            }
                          }
                        )}
                      </MDBListGroup>
                      <hr />
                      <p className="lead font-weight-bold">Finale Polizze</p>
                      <MDBRow className="mb-3 flex-center">
                        <MDBCol lg="6">
                          <p className="text-muted m-0">
                            Bitte füge die Nummer der Polizze an.
                          </p>
                        </MDBCol>
                        <MDBCol lg="6">
                          <MDBInput
                            type="text"
                            outline
                            label="Polizzennummer"
                            getValue={(val) =>
                              this.setState({ policy_id: val })
                            }
                            value={this.state.policy_id}
                            containerClass="my-0"
                            className="m-0"
                          />
                        </MDBCol>
                      </MDBRow>
                      <p className="mb-3 text-muted">
                        Lade hier die finale Polizze und sonstige Files für den
                        Kunden hoch.
                      </p>
                      <Dropzone
                        onDrop={(acceptedFiles) => this.getDrop(acceptedFiles)}
                        //onDropAccepted={() => this.setState({ status: "accept" })}
                        onDropRejected={() =>
                          this.setState({ status: "reject" })
                        }
                        onDragOver={() => this.setState({ status: "active" })}
                        onDragLeave={() => this.setState({ status: "" })}
                        accept="image/jpeg, image/png, application/pdf"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className={"wrapper " + this.state.status}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <p>
                                Ziehe Dateien hierher oder klicke auf das Feld,
                                um Dateien auszuwählen
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <aside className="thumbs-container mb-3">
                        {this.state.selectedFiles.length > 0 &&
                          this.state.selectedFiles.map((file) => {
                            if (file.data?.type !== "application/pdf") {
                              return (
                                <div className="thumb" key={file.data.name}>
                                  <div className="thumb-inner">
                                    <MDBView
                                      onClick={() =>
                                        this.removeDrop(file.data.name)
                                      }
                                    >
                                      {file.preview && (
                                        <img
                                          src={file.preview}
                                          alt="File preview"
                                        />
                                      )}
                                      <MDBMask
                                        className="d-flex flex-center"
                                        overlay="black-slight"
                                      >
                                        <MDBIcon
                                          far
                                          icon="trash-alt"
                                          className="text-white"
                                        />
                                      </MDBMask>
                                    </MDBView>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </aside>
                      <aside className="pdf-container">
                        {this.state.selectedFiles.length > 0 &&
                          this.state.selectedFiles.map((file) => {
                            if (file.data?.type === "application/pdf") {
                              return (
                                <div key={file.data.name}>
                                  <MDBIcon
                                    icon="trash-alt"
                                    far
                                    className="mr-2 text-danger clickable p-1"
                                    onClick={() =>
                                      this.removeDrop(file.data.name)
                                    }
                                  />
                                  <a href={file.preview} target="_blank">
                                    {file.data.name}
                                  </a>
                                </div>
                              );
                            }
                          })}
                      </aside>
                      <div className="text-right">
                        <MDBBtn
                          color="blue"
                          disabled={
                            this.state.selectedFiles.length === 0 ||
                            this.state.selectedFiles.length > 1 ||
                            !this.state.policy_id
                          }
                          onClick={() =>
                            this.uploadDoc(this.state.customerAnswer)
                          }
                        >
                          <MDBIcon icon="file-contract" />
                          Polizze senden
                        </MDBBtn>
                      </div>
                    </div>
                  )}
                </>
              )}
            </MDBModalBody>
          </MDBModal>
        )}
      </div>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return { data: state.auth.data };
};

const mapDispatchToProps = {
  getInsurances,
  uploadTemplate,
  uploadDoc,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(InsuranceList);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
