//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> Animations
import { animations } from "react-animation";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBView,
  MDBMask,
} from "mdbreact";
//#endregion

//#region > Config
const RANDOM = Math.random().toString(36).substring(7);
//#endregion

//#region > Components
class SingleChoiceCardModule extends React.Component {
  state = {};

  componentDidMount = () => {
    const { name, value, items } = this.props;

    // Prefill state
    if (value !== undefined) {
      this.setState({
        ["selection-" + name]: value,
      });
    } else {
      if (items) {
        let res = [];

        items.forEach((element, e) => {
          res = [...res, { animation: `${animations.popIn} ${e * 80}ms` }];
        });

        this.setState({
          animations: res,
        });
      }
    }
  };

  // Check if value is number
  setValue = (val, name) => {
    this.setState({
      ["number-" + name]: !isNaN(val)
        ? val.trim()
        : this.state["number-" + name]
        ? this.state["number-" + name].trim()
        : "",
    });
  };

  render() {
    const {
      head = "Head",
      sub = "Sub",
      name = RANDOM,
      lg = 4,
      items = [],
      help,
      link = "",
      instant = false,
      disabled = false,
      className = "",
    } = this.props;

    return (
      <div className="module-singlechoicecard text-center">
        <p className="lead font-weight-bold mb-2">
          {head}
          {help && (
            <MDBPopover
              placement="top"
              popover
              clickable
              id="popper1"
              domElement
            >
              <span className="ml-2">
                <MDBIcon
                  far
                  icon="question-circle"
                  className="clickable blue-text"
                />
              </span>
              <div>
                <MDBPopoverHeader>{help.title}</MDBPopoverHeader>
                <MDBPopoverBody>{help.content}</MDBPopoverBody>
              </div>
            </MDBPopover>
          )}
        </p>
        <p className={link ? "mb-1 text-muted" : "mb-3 text-muted"}>{sub}</p>
        {link && (
          <span className="d-block mb-3">
            <MDBIcon far icon="question-circle" className="text-muted mr-2" />
            <a href={link} className="small" target="_bank">
              {link}
            </a>
          </span>
        )}
        <MDBRow className="d-flex justify-content-center">
          {items.map((item, i) => {
            return (
              <MDBCol
                lg={lg.toString()}
                onClick={
                  instant
                    ? () => this.props.setValue(name, item.value)
                    : () => this.setState({ ["selection-" + name]: item.value })
                }
                style={
                  this.state.animations ? this.state.animations[i] : undefined
                }
                key={item.value}
                className={
                  disabled
                    ? this.state.animations
                      ? "animated"
                      : "disabled"
                    : this.state.animations
                    ? "animated"
                    : undefined
                }
              >
                <MDBCard
                  className={
                    this.state["selection-" + name] === item.value
                      ? "active"
                      : this.state["selection-" + name] !== undefined
                      ? "inactive"
                      : undefined
                  }
                >
                  <MDBCardBody>
                    <MDBView>
                      {item.icon && !item.isPicture && <item.icon />}
                      <MDBMask className="clickable" />
                    </MDBView>
                    {item.icon && item.isPicture && <img src={item.icon} />}
                    <span className="font-weight-bold d-block">
                      {item.label}
                    </span>
                    <span className="text-muted small d-block">
                      {item.help}
                    </span>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            );
          })}
        </MDBRow>
        {!instant && (
          <div className="text-center mt-4">
            <MDBBtn
              color="blue"
              size="lg"
              className="m-0"
              disabled={this.state["selection-" + name] === undefined}
              onClick={() =>
                this.props.setValue(name, this.state["selection-" + name])
              }
            >
              Weiter
            </MDBBtn>
          </div>
        )}
      </div>
    );
  }
}
//#endregion

//#region > Exports
export default SingleChoiceCardModule;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
